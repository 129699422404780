import {useEffect, useState} from "react";

// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Grid from "@mui/material/Grid";
import FormField from "../../user-profile/components/FormField";
import ArgonButton from "../../../components/ArgonButton";
import {renameFolder} from "../../../api/BackendApi/TestRepository";
import {enqueueSnackbar} from "notistack";

function FolderDetails({ folderId, folderName, treeRefresher }) {

  const [nameFolder, setNameFolder] = useState(null);

    useEffect(() => {
        setNameFolder(folderName)
    }, [folderName]);

    const handleSaveName = async () => {
        try {
            await renameFolder(folderId, nameFolder);
            enqueueSnackbar("Folder renamed!", { variant: "success" });
        } finally {
            treeRefresher();
        }
    }


  return (
    <Card sx={{ height: "100%", display: "flex" }}>
      <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <ArgonTypography variant="h5" fontWeight="medium" textTransform="capitalize">
          Details
        </ArgonTypography>
      </ArgonBox>
        <ArgonBox component="form" pt={3} px={3}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField label="ID:" placeholder="id" value={folderId} disabled/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {folderId === "root" ?
                        <FormField label="Folder Name:"  placeholder="folder name" value="Root" disabled/>
                        :
                        <FormField label="Folder Name:"  placeholder="folder name" value={nameFolder} onChange={(e) => setNameFolder(e.target.value)}/>
                    }
                </Grid>
            </Grid>
        </ArgonBox>
        {folderId !== "root" ?
            <ArgonBox pt={3} sx={{ display: "flex", justifyContent: "center" }}>
                <ArgonButton variant="contained" color="success" onClick={() => handleSaveName()} disabled={nameFolder === folderName || nameFolder === ""}>
                    Save
                </ArgonButton>
            </ArgonBox>
            : null
        }
    </Card>
  );
}

FolderDetails.propTypes = {
  folderId: PropTypes.string,
  folderName: PropTypes.string,
  handleSaveName: PropTypes.func
}

export default FolderDetails;
