import { HUBAPI } from "api/BackendApi/HUB";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";


function LogsView({ execId }) {

  const tolerance = 2;

  const mounted = useRef(false);
  const [logs, setLogs] = useState(null);
  const [autoScroll, setAutoScroll] = useState(true);
  const textareaRef = useRef(null);

  const updateLogs = async () => {
    let response = await HUBAPI.getExecLogs(execId);
    setLogs(response.data.logs.join(""));
  }

  const updateLoop = async () => {
    try {
      if (!mounted.current) return;
      await updateLogs();
    } finally {
      setTimeout(updateLoop, 1000);
    }
  }

  useEffect(() => {
    if (autoScroll && textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [logs, autoScroll]);

  useEffect(() => {
    mounted.current = true;
    const textarea = textareaRef.current;

    updateLoop();

    const handleScroll = () => {
      console.log(textarea.scrollHeight - textarea.scrollTop);
      console.log(textarea.clientHeight);
      const isAtBottom = textarea.scrollHeight - textarea.scrollTop <= textarea.clientHeight + tolerance;
      if (isAtBottom) {
        setAutoScroll(true); // Re-enable auto-scroll if the user scrolls to the bottom
      } else {
        setAutoScroll(false); // Disable auto-scroll on manual scroll
      }
    };

    if (textarea) {
      textarea.addEventListener('scroll', handleScroll);
    }

    return () => {
      mounted.current = false;
      if (textarea) {
        textarea.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return <textarea ref={textareaRef} readOnly={true} value={logs ?? "Loading logs..."} style={{
    "width": "100%",
    "height": "85vh",
    "boxSizing": "border-box",
    "backgroundColor": "#000",
    "color": "#fff",
    "padding": "10px",
    "resize": "none"
  }} />;
}

export default LogsView;
