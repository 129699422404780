import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import ArgonBox from "components/ArgonBox";
import Card from "@mui/material/Card";
import { ChartsAPI } from "api/BackendApi/ChartsAPI";
import ChartConfigsKanban from './ChartConfigsKanban.js';
import ArgonInput from "../../../components/ArgonInput";
import ArgonTypography from "../../../components/ArgonTypography";
import { Module } from "../../../api/BackendApi/Module";
import { enqueueSnackbar } from "notistack";
import ArgonButton from "../../../components/ArgonButton";
import Icon from "@mui/material/Icon";

function RowDashboard({ graphTypes, module, enableDelete, handleCloseAfterAction, onRename, onConfigChange }) {

  const [newNameDashboard, setNewNameDashboard] = useState(null);
  const [nameDashboard, setNameDashboard] = useState(module.name.replace("Dashboard", "").trim());
  const [isTypingName, setIsTypingName] = useState(false);

  const onCardOrderChange = async (configs) => {
    let orderConfigMap = {};
    for (let [index, config] of Object.entries(configs)) {
      orderConfigMap[config._id] = index;
    }
    await ChartsAPI.setChartsConfigsOrder(orderConfigMap);
    enqueueSnackbar("Charts order changed!", { variant: "success" })
  }

  const loading = () => {
    return (<ArgonBox mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress size={64} />
    </ArgonBox>);
  };

  const updateDashboardName = async () => {
    if (newNameDashboard == null) return;
    let nameNewDashboard = ["Dashboard", newNameDashboard.trim()].join(" ").trim();
    if (newNameDashboard == nameDashboard) return;
    let resp = await Module.editModule(module._id, nameNewDashboard, module.enabled)
    if (resp.data) {
      setNewNameDashboard(null);
      setNameDashboard(newNameDashboard.trim());
      enqueueSnackbar("Dashboard name updated", { variant: "success" });
      onRename?.();
    }
  }

  const actionDeleteDashboard = async (event) => {
    try {
      await Module.deleteDashboard(module._id);
      enqueueSnackbar("Dashboard deleted!", { variant: "success" })
    } catch (e) {
      console.log(error)
      enqueueSnackbar("Error while deleting dashboard!", { variant: "error" });
    } finally {
      handleCloseAfterAction();
    }
  };

  const getTable = () => {
    if (graphTypes == null) {
      return loading();
    }

    return (
      <Card>
        <Grid container>
          <Grid item xs={11}>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
              {isTypingName ?
                <Grid container>
                  <Grid item xs={1}>
                    <ArgonTypography variant="h5" fontWeight="medium" textTransform="capitalize" sx={{ mt: 0.6 }} >
                      Dashboard
                    </ArgonTypography>
                  </Grid>
                  <Grid item xs={11}>
                    <ArgonInput
                      autoFocus
                      value={newNameDashboard}
                      placeholder="Insert Dashboard Name"
                      onChange={({ currentTarget }) => setNewNameDashboard(currentTarget.value)}
                      onBlur={({ currentTarget }) => {
                        setIsTypingName(false);
                        updateDashboardName()
                      }}
                    />
                  </Grid>
                </Grid>
                :
                <ArgonTypography
                  variant="h5"
                  fontWeight="medium"
                  textTransform="capitalize"
                  onClick={() => {
                    setIsTypingName(true);
                    setNewNameDashboard(nameDashboard)
                  }}>
                  Dashboard {nameDashboard}
                </ArgonTypography>
              }
            </ArgonBox>
          </Grid>
          {enableDelete &&
            <Grid item xs={1}>
              <ArgonButton variant="gradient" color="error" sx={{ mt: 2 }} onClick={actionDeleteDashboard}>
                <Icon>delete</Icon>&nbsp;
                Delete
              </ArgonButton>
            </Grid>
          }

        </Grid>
        <CardContent>
          <ChartConfigsKanban key={module._id} configs={graphTypes} outputValueUpdate={onCardOrderChange} onConfigChange={onConfigChange}></ChartConfigsKanban>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ marginBottom: "3em" }}>
      <Grid container>
        {getTable()}
      </Grid>
    </Card>
  );
}


export default RowDashboard;
