import Card from "@mui/material/Card";
import { TestRunsAPI } from "api/BackendApi/TestRuns";
import { useEffect } from "react";
import { useState } from "react";

function ReportView({ runId }) {

  const [urlPath, setUrlPath] = useState(null);

  useEffect(() => {
    const worker = async () => {
      let response = await TestRunsAPI.getRunReportLink(runId);
      setUrlPath(response.data);
    };

    worker();
  }, []);

  if (urlPath == null) {
    return <div/>;
  }

  return <iframe sandbox width="100%" height="98%" src={"/be/test-runs" + urlPath} sx={{
    position: "fixed",
    background: "#fff",
    border: "none",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    minHeight: '400px'
  }} />;
}

export default ReportView;
