import apiClient from "api/BackendApi";

const TestRunsAPI = {
  getTestRunsForInstance: (instanceId) => apiClient.get("/be/test-runs/runs/" + instanceId),
  getRunReportLink: (runId) => apiClient.get("/be/test-runs/run-report/" + runId),
  getRunReport: (testId) => apiClient.get("/be/test-runs/run-report-file/" + testId, {
    responseType: 'arraybuffer'
  }),
  deleteRun: (runId) => apiClient.delete("/be/test-runs/runs/" + runId),
  getReportsFiles: (runIds) => apiClient.post("/be/test-runs/run-report-files", {"run_ids": runIds}, { responseType: 'arraybuffer' }), 
}

export {
  TestRunsAPI
};
