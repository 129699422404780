import apiClient from "../index";

const generateGraph1 = (data) => apiClient.post("/be/map_explorer/generate_graph", {"data": data});

const generateGraph2 = (data) => apiClient.post("/be/map_explorer/generate_graph", {"data": data});

const generateGraph3 = (data) => apiClient.post("/be/map_explorer/generate_graph/mockGraph", {"data": data});

const generateGraph4 = (data) => apiClient.post("/be/map_explorer/generate_graph/mockGraph2", {"data": data});

export {
  generateGraph1,
  generateGraph2,
  generateGraph3,
  generateGraph4
};
