import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Grid from "@mui/material/Grid";
import { enqueueSnackbar } from "notistack";
import { TestCycles } from "../../../../../api/BackendApi/TestCycles";
import ArgonButton from "../../../../../components/ArgonButton";
import FormField from "../../../../user-profile/components/FormField";

function RenameCycle({ closeDialog, entityId, folderName }) {

  const [nameFolder, setNameFolder] = useState(null);

  useEffect(() => {
    setNameFolder(folderName)
  }, [folderName]);

  const handleSaveName = async () => {
    try {
      await TestCycles.renameCycleFolder(entityId, nameFolder);
      enqueueSnackbar("Entity renamed!", { variant: "success" });
    } finally {
      closeDialog();
    }
  }


  return (
    <>
      <ArgonBox component="form" px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField label="ID:" placeholder="id" value={entityId} disabled />
          </Grid>
          <Grid item xs={12} sm={12}>
            {entityId === "root" ?
              <FormField label="Entity Name:" placeholder="entity name" value="Root" disabled />
              :
              <FormField label="Entity Name:"
                placeholder="entity name"
                value={nameFolder}
                onChange={(e) => setNameFolder(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && nameFolder !== "") {
                    handleSaveName();
                    e.preventDefault();
                  } else if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }} />
            }
          </Grid>
        </Grid>
      </ArgonBox>
      {entityId !== "root" ?
        <ArgonBox pt={3} sx={{ display: "flex", justifyContent: "center" }}>
          <ArgonButton variant="contained" color="success" onClick={() => handleSaveName()} disabled={nameFolder === folderName}>
            Save
          </ArgonButton>
        </ArgonBox>
        : null
      }
    </>
  );
}

RenameCycle.propTypes = {
  folderId: PropTypes.string,
  folderName: PropTypes.string,
  handleSaveName: PropTypes.func
}

export default RenameCycle;
