import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import { ChartsAPI } from "api/BackendApi/ChartsAPI";
import ArgonTypography from "components/ArgonTypography";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";

function HorizontalBar({ data, width, height }) {

  const [loadedSeries, setLoadedSeries] = useState(null);

  const updateSerie = (series) => {
    const stackInfo = {};
    for (let i = 0; i < series[0].data.length; ++i) {
      for (let j = 0; j < series.length; ++j) {
        const stackName = series[j].stack;
        if (!stackName) {
          continue;
        }
        if (!stackInfo[stackName]) {
          stackInfo[stackName] = {
            stackStart: [],
            stackEnd: []
          };
        }
        const info = stackInfo[stackName];
        const data = series[j].data[i];
        if (data && data !== '-') {
          if (info.stackStart[i] == null) {
            info.stackStart[i] = j;
          }
          info.stackEnd[i] = j;
        }
      }
    }
    for (let i = 0; i < series.length; ++i) {
      const data = series[i].data;
      const info = stackInfo[series[i].stack];
      for (let j = 0; j < series[i].data.length; ++j) {
        // const isStart = info.stackStart[j] === i;
        const isEnd = info.stackEnd[j] === i;
        const isStart = info.stackStart[j] === i;
        const rightBorder = isEnd ? 20 : 0;
        const leftBorder = isStart ? 20 : 0;
        data[j] = {
          value: data[j],
          itemStyle: {
            borderRadius: [leftBorder, rightBorder, rightBorder, leftBorder]
          }
        };
      }
    }
    return series;
  };

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      }
    },
    color: [
      '#a1e8c7',
      '#e18383',
      '#f8e8a1'
    ],
    legend: {
      show: false
    },
    grid: {
      left: '1%',
      right: '2%',
      bottom: '2%',
      top: '2%',
      containLabel: false
    },
    xAxis: {
      splitLine: { show: false },
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: { show: false },
      type: 'value'
    },
    yAxis: {
      splitLine: { show: false },
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: { show: false },
      type: 'category',
      data: ['Mon']
    },
    series: loadedSeries ?? []
  };

  const onReady = (i) => {
    console.log("Setting instance");
    window.addEventListener("resize", () => {
      console.log("Resize");
      console.log(i);
      i.resize();
    });
  };

  useEffect(() => {
    const serie = [];
    var passed = data["Passed"] ?? 0;
    var failed = data["Failed"] ?? 0;
    var notCompleted = data["Not Completed"] ?? 0;
    
    let s1 = {
      name: "Passed",
      type: 'bar',
      stack: 'total',
      label: {
        show: true
      },
      emphasis: {
        focus: 'series'
      },
      data: [passed]
    }
    serie.push(s1);

    let s2 = {
      name: "Failed",
      type: 'bar',
      stack: 'total',
      label: {
        show: true
      },
      emphasis: {
        focus: 'series'
      },
      data: [failed]
    }
    serie.push(s2);

    let s3 = {
      name: "Not Compelted",
      type: 'bar',
      stack: 'total',
      label: {
        show: true
      },
      emphasis: {
        focus: 'series'
      },
      data: [notCompleted]
    }
    serie.push(s3);

    const updatedSerie = updateSerie(serie);
    setLoadedSeries(updatedSerie);
  }, []);

  if (data == null) {
    return <div />;
  }

  return <ReactEcharts option={option} onChartReady={onReady} style={{
    height: height ?? '100%',
    width: width ?? '100%',
  }} />;
}

export default HorizontalBar;