import {
	Grid,
	Card
} from "@mui/material";
import ArgonDropzone from "../../../components/ArgonDropzone";
import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";
import logoBoxingPNG from "assets/images/icons/boxing.png";
import ArgonButton from "../../../components/ArgonButton";
import {useEffect} from "react";

export function GenerativeTestFileUploader({ setCardUploadSelected, setUploadSend, dropzoneFiles }) {

	useEffect(() => {
		for(const file of dropzoneFiles.current){
			document.querySelector("div#dropmehere").firstElementChild.dropzone.addFile(file);
		}
	}, []);

	let dropzoneOptions = {
		addRemoveLinks: true,
		uploadMultiple: true,
		autoProcessQueue: false,

		init: function () {

			this.on("removedfile", (file) => {
				console.log('File removed:', file);
				if(file.status === "canceled"){
					this.addFile(file);
				} else {
					dropzoneFiles.current = dropzoneFiles.current.filter((f) => f.name !== file.name);
				}

				if(dropzoneFiles.current.length === 0){
					setUploadSend(false);
				}
			});

			this.on("addedfile", (file) => {
				console.log('File added:', file);
				const defaultProgressBar = file.previewElement.querySelector(".dz-progress");

				if (defaultProgressBar) {
					defaultProgressBar.style.display = "none";
				}

				if (dropzoneFiles.current.findIndex((f) => f.name === file.name) === -1) {
					dropzoneFiles.current = [...dropzoneFiles.current, file];
				}
				setUploadSend(true);
			});
		},
	};

	return (<ArgonBox mt={15} mb={10}>
				<Grid container justifyContent="center">
					<Grid item xs={12} md={8} mb={5}>
						<Card>
							<Grid container justifyContent="center" alignItems="center">
								<Grid item >
									<ArgonBox>
										<img src={logoBoxingPNG} style={{width: "3vw", height: "3vw"}} alt="logoUpload"/>
									</ArgonBox>
								</Grid>
								<Grid item >
									<ArgonBox>
										<ArgonTypography component="label" variant="h5" fontWeight="bold">
											Upload
										</ArgonTypography>
									</ArgonBox>
								</Grid>
							</Grid>
						</Card>
					</Grid>
					<Grid item xs={12} md={8}>
						<Card sx={{ minHeight: "calc(100vh - 65vh)" }}>
							<ArgonBox mt={3} ml={5} mr={5}>
								<div className="dropmehere" id="dropmehere">
									<ArgonDropzone options={dropzoneOptions} />
								</div>
							</ArgonBox>
							<Grid container>
								<Grid item xs={12}>
									<ArgonBox mt={3} ml={5} mr={5}>
										<ArgonButton fullWidth variant="contained" color="dark" onClick={() => setCardUploadSelected(false)}> Back </ArgonButton>
									</ArgonBox>
								</Grid>
							</Grid>
						</Card>
					</Grid>
				</Grid>
			</ArgonBox>);
} 


