import { useState } from "react";

// Argon Dashboard 2 PRO MUI
import ControllerCard from "../../../components/Cards/ControllerCard";

// @mui material components
import Icon from "@mui/material/Icon";
import { Module } from "api/BackendApi/Module";
import {enqueueSnackbar} from "notistack";

function ModuleCard({module, navbarRefresher}) {
    const [active, setActive] = useState(module.enabled);

    const enableModule = async (module_id, module_name) => {
      try {
        await Module.enableModule(module_id, module_name, !active);
        setActive(!active);
        enqueueSnackbar(!active ? 'Module enabled!' : 'Module disabled!',{variant:'success'});
        navbarRefresher("ModuleCard");
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Error during the enabling of the module',{variant:'error'})
      }
    };

    if(module.name.includes("Dashboard")) {
        return (
            <ControllerCard
                icon={<Icon fontSize="large" color={"info"}>bar_chart</Icon>}
                title="Dashboards"
                description={(active) ? "Active" : "Disabled"}
                state={active}
                color={"dark"}
                onChange={(event) => {
                    enableModule(module._id, "Dashboard")
                }}
            />
        );
    }else{
        return (
            <ControllerCard
                icon={<Icon fontSize="large" color={"info"}>{module.icon}</Icon>}
                title={module.name}
                description={(active) ? "Active" : "Disabled"}
                state={active}
                color={"dark"}
                onChange={(event) => {
                    enableModule(module._id, module.name)
                }}
            />
        );
    }
}

export default ModuleCard;