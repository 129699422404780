// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

function EmptyDetails() {
  return (
    <Card sx={{ height: "100%", display: "flex" }}>
      <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Details
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox p={2}>
        <ArgonTypography>Select a cycle from the tree</ArgonTypography>
      </ArgonBox>
    </Card>
  );
}


export default EmptyDetails;
