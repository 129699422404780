import React, {useState} from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import ArgonTypography from "../../../components/ArgonTypography";
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonButton from "../../../components/ArgonButton";
import ArgonBox from "../../../components/ArgonBox";
import {deleteSitemap} from "../../../api/BackendApi/SitemapWebsite";
import Table from "../../../components/Table";
import {CircularProgress} from "@mui/material";


//import custom components


function DeleteSitemap({buttonDisabled, tableSitemap, actionDeleteSitemap}) {

    const [openModalDeleteSitemap, setOpenModalDeleteSitemap] = useState(false);
    const handleCloseModal = () => setOpenModalDeleteSitemap(false);
    const handleOpenModal = () => {
        setOpenModalDeleteSitemap(true);
    }

    const loadingTable = () => {
        let dataTable = [];
        for (let i in tableSitemap.getSelectedRowModel().rows) {
            dataTable.push({
                sitemap: tableSitemap.getSelectedRowModel().rows[i].id,
            });
        }

        return (<Table
                columns={[
                    { name: "sitemap", align: "center" },
                ]}
                rows={dataTable}
                />)
    };

    const handleActionDeleteSitemap = async () => {
        actionDeleteSitemap();
        setOpenModalDeleteSitemap(false);
    };

    let body = loadingTable();

  return (
      <div>
          <ArgonButton variant="gradient" color="info" disabled={buttonDisabled} onClick={handleOpenModal}>
              <Icon>delete</Icon>&nbsp;
              Delete
          </ArgonButton>
          <Modal open={openModalDeleteSitemap}
                 onClose={(event, reason) => {
                     if (reason !== 'backdropClick') {
                         handleCloseModal(event, reason);
                     }
                 }}
                 closeAfterTransition
                 slots={{ backdrop: Backdrop }}
                 slotProps={{
                     backdrop: {
                         timeout: 500,
                     },
                 }}
                 aria-labelledby="delete-urls"
                 aria-describedby="delete-urls" >
              <ArgonBox mt={20} mb={20} p={1} xs={3} ml={"auto"} mr={"auto"}>
                  <Grid container justifyContent="center">
                      <Grid item xs={10} sm={10} lg={5} borderRadius={4}>
                          <Card>
                              <ArgonBox p={5}>
                                  <ArgonTypography variant="h3" textAlign={"center"}>
                                      Delete Sitemap
                                  </ArgonTypography>
                                  <ArgonTypography textAlign={"center"} sx={{ pt: 1 }}>
                                      Are you sure?
                                  </ArgonTypography>
                                  <ArgonBox mt={1}>
                                      <Grid container justifyContent="center">
                                          <Grid item sx={{ maxHeight: '20em', overflow: "auto"}}>
                                              {body}
                                          </Grid>
                                      </Grid>
                                  </ArgonBox>
                                  <ArgonBox mt={4}>
                                      <Grid container spacing={1} justifyContent="center">
                                          <Grid item >
                                              <ArgonButton color="dark" variant="gradient" onClick={handleCloseModal}>Cancel</ArgonButton>
                                          </Grid>
                                          <Grid item >
                                              <ArgonButton color="error" variant="gradient" onClick={handleActionDeleteSitemap}>Yes, Delete!</ArgonButton>
                                          </Grid>
                                      </Grid>
                                  </ArgonBox>
                              </ArgonBox>
                          </Card>
                      </Grid>
                  </Grid>
              </ArgonBox>
          </Modal>
      </div>
  );
}

export default DeleteSitemap;
