import PropTypes from "prop-types";
import { useState } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Settings page components
import FormField from "./FormField";

function BasicInfo({user}) {
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <ArgonBox p={3}>
        <ArgonTypography variant="h5">User Information</ArgonTypography>
      </ArgonBox>
      <ArgonBox component="form" pb={3} px={3}>
        <Grid container spacing={6} pl={6} pr={6}>
          <Grid item xs={12} sm={6}>
            <FormField label="first name" placeholder="Name" value={user.name} disabled/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="last name" placeholder="Surname" value={user.surname} disabled/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="username"
              placeholder="username"
              inputProps={{ type: "email" }}
              value={user.username}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="email"
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
              value={user.email}
              disabled
            />
          </Grid>
        </Grid>
      </ArgonBox>
    </Card>
  );
}

BasicInfo.propTypes = {
  user: PropTypes.object
}

export default BasicInfo;
