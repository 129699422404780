

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import colors from "assets/theme/base/colors";

const { primary } = colors;

// Argon Dashboard 2 PRO MUI context
import { useArgonController } from "context";
import { CardActionArea, CardContent } from "@mui/material";

function ControllerCard({ color, state, icon, title, description, onChange }) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  var cardStyle = state ? { outline: 1, outlineColor: "primary.main" } : {};
  var cardContentStyle = state ? {backgroundColor: primary.main + "09"} : {};
  return (
    //, borderColor: primary.main, borderWidth: state ? 2 : 0

    <Card sx={{ ...cardStyle, height: "100%", transition: "all" }}>
      <CardActionArea onClick={onChange} >
        <CardContent sx={cardContentStyle}>
          <ArgonBox
          
            pt={1}
            height="100%"

            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              lineHeight={1}
            >
              <ArgonTypography variant="body2">
                {state ? "On" : "Off"}
              </ArgonTypography>
              <ArgonBox mr={1}>
                <Switch checked={state} />
              </ArgonBox>
            </ArgonBox>
            {icon}
            <ArgonBox mt={2} lineHeight={1}>
              <ArgonTypography
                variant="body2"
                textTransform="capitalize"
                fontWeight="medium"
              >
                {title}
              </ArgonTypography>
              {description ? (
                <ArgonTypography variant="caption">
                  {description}
                </ArgonTypography>
              ) : null}
            </ArgonBox>
          </ArgonBox>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

// Setting default values for the props of ControllerCard
ControllerCard.defaultProps = {
  color: "info",
  state: false,
  description: "",
};

// Typechecking props for the ControllerCard
ControllerCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  state: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ControllerCard;
