import { useEffect, useState } from "react"

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import ArgonAlert from "components/ArgonAlert";
import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress } from '@mui/material';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { addTest } from "api/BackendApi/TestRepository";
import ArgonSnackbar from "components/ArgonSnackbar";
import {enqueueSnackbar} from "notistack";

function AddNewTestView({closeDialog, folderId}) {

  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [description, setDescription] = useState("");
  const [precondition, setPrecondition] = useState("");
  const [binaries, setBinaries] = useState("");
  const [command, setCommand] = useState("");
  const [testArguments, setArguments] = useState("");

  const saveNewTest = () => {
    setSaving(true);
    addTest(folderId, {
      "name": name,
      "notes": notes,
      "description": description,
      "precondition": precondition,
      "binaries": binaries,
      "command": command,
      "arguments": testArguments
    })
      .then(() => {
          enqueueSnackbar("Test added successfully", { variant: "success" });
      })
      .catch(() => {
          enqueueSnackbar("Error", { variant: "error" });
      })
      .finally(() => {
        setSaving(false);
        closeDialog();
      });
  };



  return (
    <ArgonBox component="form" role="form">
        <ArgonTypography fontSize={14}>Test Name</ArgonTypography>
        <ArgonBox mb={2}>
          <ArgonInput
            placeholder="Test Name"
            value={name}
            size="medium"
            onChange={(e) => { setName(e.target.value) }}
            onKeyPress={(e) => {
                if (e.key === "Enter" && name !== ""){
                    saveNewTest()
                    e.preventDefault();
                } else if (e.key === "Enter") {
                    e.preventDefault();
                }
            }}
          />
        </ArgonBox>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ArgonTypography fontSize={14}>Test Description</ArgonTypography>
            <ArgonBox mb={2}>
              <ArgonInput
                placeholder="Test Description"
                value={description}
                size="medium"
                onChange={(e) => { setDescription(e.target.value) }}
                onKeyPress={(e) => {
                    if (e.key === "Enter" && name !== ""){
                        saveNewTest()
                        e.preventDefault();
                    } else if (e.key === "Enter") {
                        e.preventDefault();
                    }
                }}
              />
            </ArgonBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <ArgonTypography fontSize={14}>Test Precondition</ArgonTypography>
            <ArgonBox mb={2}>
              <ArgonInput
                placeholder="Test Precondition"
                value={precondition}
                size="medium"
                onChange={(e) => { setPrecondition(e.target.value) }}
                onKeyPress={(e) => {
                    if (e.key === "Enter" && name !== ""){
                        saveNewTest()
                        e.preventDefault();
                    } else if (e.key === "Enter") {
                        e.preventDefault();
                    }
                }}
              />
            </ArgonBox>
          </Grid>
        </Grid>
        <ArgonTypography fontSize={14}>Notes</ArgonTypography>
        <ArgonBox mb={2}>
          <ArgonInput
            placeholder="Notes"
            value={notes}
            size="medium"
            onChange={(e) => { setNotes(e.target.value) }}
            onKeyPress={(e) => {
                if (e.key === "Enter" && name !== ""){
                    saveNewTest()
                    e.preventDefault();
                } else if (e.key === "Enter") {
                    e.preventDefault();
                }
            }}
          />
        </ArgonBox>
        <ArgonTypography fontSize={14}>Binaries</ArgonTypography>
        <ArgonBox mb={2}>
          <ArgonInput
            placeholder="Binaries"
            value={binaries}
            size="medium"
            onChange={(e) => { setBinaries(e.target.value) }}
            onKeyPress={(e) => {
                if (e.key === "Enter" && name !== ""){
                    saveNewTest()
                    e.preventDefault();
                } else if (e.key === "Enter") {
                    e.preventDefault();
                }
            }}
          />
        </ArgonBox>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ArgonTypography fontSize={14}>Command</ArgonTypography>
            <ArgonBox mb={2}>
              <ArgonInput
                placeholder="Command"
                value={command}
                size="medium"
                onChange={(e) => { setCommand(e.target.value) }}
                onKeyPress={(e) => {
                    if (e.key === "Enter" && name !== ""){
                        saveNewTest()
                        e.preventDefault();
                    } else if (e.key === "Enter") {
                        e.preventDefault();
                    }
                }}
              />
            </ArgonBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <ArgonTypography fontSize={14}>Arguments</ArgonTypography>
            <ArgonBox mb={2}>
              <ArgonInput
                placeholder="Arguments"
                value={testArguments}
                size="medium"
                onChange={(e) => { setArguments(e.target.value) }}
                onKeyPress={(e) => {
                    if (e.key === "Enter" && name !== ""){
                        saveNewTest()
                        e.preventDefault();
                    } else if (e.key === "Enter") {
                        e.preventDefault();
                    }
                }}
              />
            </ArgonBox>
          </Grid>
        </Grid>
        <ArgonBox display="flex" justifyContent="center" mt={2}>
          <ArgonBox mr={1.5}>
              {name === "" ? (
                <ArgonButton color="primary" disabled>
                    <SaveIcon />&nbsp; Save
                </ArgonButton>
              ) : (
                  <ArgonButton color="primary" onClick={saveNewTest}>
                      {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                      &nbsp; {saving ? "Saving..." : "Save"}
                  </ArgonButton>
              )}
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
  );
}


AddNewTestView.propTypes = {
  closeDialog: PropTypes.func,
  folderId: PropTypes.string
};

export default AddNewTestView;
