import apiClient from "api/BackendApi";

const TestCycles = {
  testCycles: () => apiClient.get("/be/test-cycles"),

  addCycle: (folderId, cycle) => apiClient.post("/be/test-cycles/cycle/" + folderId, cycle),
  
  getCycleInfo: (cycleId) => apiClient.get("/be/test-cycles/cycle/" + cycleId),
  
  updateCycleInfo: (cycleId, data) => apiClient.patch("/be/test-cycles/cycle/" + cycleId, data),
  
  addCycleFolder: (parentId, folderName) => apiClient.post("/be/test-cycles/folder/" + parentId, { "name": folderName }),
  
  renameCycleFolder: (folderId, folderName) => apiClient.post("/be/test-cycles/folder/rename/" + folderId, { "name": folderName }),
  
  deleteEntity: (entityId) => apiClient.delete("/be/test-cycles/entity/" + entityId),

  cloneCycle: (cycleId) => apiClient.get("/be/test-cycles/clone-cycle/" + cycleId),

  recap: (folderId) => apiClient.get("/be/test-cycles/recap/" + folderId),
}


export { TestCycles };
