import axios from "axios";

const apiClient = axios.create();

apiClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwt-token") || sessionStorage.getItem("jwt-token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default apiClient;
