// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Images
import logo from "assets/images/small-logos/logo-figma.svg";

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Figma() {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  return (
      <Accordion disabled>
          <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content-jira"
              id="panel-jira"
          >
              <ArgonBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
              >
                  <ArgonBox display="flex" alignItems="center">
                      <ArgonAvatar src={logo} alt="Figma logo" variant="rounded" />
                      <ArgonBox ml={2}>
                          <ArgonTypography variant="h5" fontWeight="medium">
                              Figma
                              <ArgonTypography variant="overline" fontWeight="light">
                                  &nbsp;(To be released)
                              </ArgonTypography>
                          </ArgonTypography>

                      </ArgonBox>
                  </ArgonBox>
              </ArgonBox>
          </AccordionSummary>
          <AccordionDetails>
              <ArgonBox ml={2} pl={6} pt={2} lineHeight={1}>
                  <ArgonBox
                      bgColor={darkMode ? "transparent" : "grey-100"}
                      borderRadius="lg"
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                      my={3}
                      py={1}
                      pl={{ xs: 1, sm: 2 }}
                      pr={1}
                  >
                      <ArgonTypography variant="button" fontWeight="medium" color="text">
                          Url to your Jira
                      </ArgonTypography>
                      <ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
                          <ArgonInput size="medium" placeholder="Add your Url to your Jira"  value={"TODO"} />
                      </ArgonBox>
                  </ArgonBox>
                  <ArgonBox
                      bgColor={darkMode ? "transparent" : "grey-100"}
                      borderRadius="lg"
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                      my={3}
                      py={1}
                      pl={{ xs: 1, sm: 2 }}
                      pr={1}
                  >
                      <ArgonTypography variant="button" fontWeight="medium" color="text">
                          Token to your Jira
                      </ArgonTypography>
                      <ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
                          <ArgonInput size="medium" placeholder="Add your Token"  value={"TODO"}/>
                      </ArgonBox>
                  </ArgonBox>
                  <ArgonBox
                      bgColor={darkMode ? "transparent" : "grey-100"}
                      borderRadius="lg"
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                      my={3}
                      py={1}
                      pl={{ xs: 1, sm: 2 }}
                      pr={1}
                  >
                      <ArgonTypography variant="button" fontWeight="medium" color="text">
                          Verify or Save your Jira credentials
                      </ArgonTypography>
                      <ArgonBox
                          display="flex"
                          alignItems={{ xs: "flex-start", sm: "center" }}
                          flexDirection={{ xs: "column", sm: "row" }}
                      >
                          <ArgonButton variant="gradient" color="primary" sx={{ mr: 2 }} size="small">
                              Check
                          </ArgonButton>
                          <ArgonButton variant="gradient" color="success" size="small">
                              Save
                          </ArgonButton>
                      </ArgonBox>
                  </ArgonBox>
              </ArgonBox>
          </AccordionDetails>
      </Accordion>
  );
}

export default Figma;
