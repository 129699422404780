import React, {useEffect, useRef, useState} from "react";
import { Navigate } from "react-router-dom";
import {createMRTColumnHelper, MaterialReactTable, useMaterialReactTable, type MRT_Row} from "material-react-table";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

// @mui material components
import {Grid, Card, Icon} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

// Argon Dashboard 2 PRO MUI components
import DefaultCounterCard from "components/Cards/CounterCards/DefaultCounterCard";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "../../components/ArgonButton";
import ArgonBadge from "../../components/ArgonBadge";

//import custom components
import Header from "components/Header";
import Footer from "components/Footer";
import AddNewSitemap from "./components/AddNewSitemap";
import DeleteSitemap from "./components/DeleteSitemap";
import ApplicationExplorer from "./components/ApplicationExplorer";
import useAuth from "utils/useAuth";

//import api backend
import {checkStatus, deleteSitemapBySitemapName, sitemapWebsite} from "../../api/BackendApi/SitemapWebsite";
import Badge from "@mui/material/Badge";
import UrlWebsite from "../url-website/UrlWebsite";
import {QueryClient, QueryClientProvider, useMutation, useQuery} from "@tanstack/react-query";
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

function SitemapTable()  {
    const isLoggedIn = useAuth().ensureLoggedIn();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [buttonDisabledAppExplorer, setButtonDisabledAppExplorer] = useState(true);
    const [showSitemapToApplicationExpl, setShowSitemapToApplicationExpl] = useState(true);
    const [showSitemapToUrls, setShowSitemapToUrls] = useState(true);

    const [rowSelection, setRowSelection] = useState({});
    const [hostInSitemap, setHostInSitemap] = useState([]);

    const [knownSitemap, setKnownSitemap] = useState(0);
    const [successSitemap, setSuccessSitemap] = useState(0);
    const [warningSitemap, setWarningSitemap] = useState(0);
    const [errorSitemap, setErrorSitemap] = useState(0);

    const rowVirtualizerInstanceRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [valueProgressBar, setValueProgressBar] = useState(0);

    const actionShowApplicationExplorer = async () => {
        setShowSitemapToApplicationExpl(!showSitemapToApplicationExpl);
    };

    const actionShowUrls = async () => {
        setShowSitemapToUrls(!showSitemapToUrls);
    };

    //READ
    const {
        data: dataSitemap = [],
        isError,
        isRefetching,
        refetch,
    } = useGetSitemap();

    //READ function
    function useGetSitemap() {
        return useQuery({
            queryKey: [
                'sitemap',
                'table-data'
            ],
            queryFn: async () => {
                try {
                    setIsLoading(true);
                    const sitemapResponse = await sitemapWebsite();
                    if (sitemapResponse.status === 200) {
                        const dataResponse = sitemapResponse.data.sitemap;
                        let countSuccess = 0;
                        let countWarning = 0;
                        let countError = 0;
                        const dataSitemapTable = await Promise.all(
                            dataResponse.map(async (item) => {
                                const responseItem = await checkStatus({sitemap: item.sitemap});
                                let status = <ArgonBadge color={"error"}>Error</ArgonBadge>;
                                let url_discovered = <Badge color="primary" max={99999} badgeContent={item.html.length} showZero></Badge>
                                if (responseItem.data.response_code === 200) {
                                    countSuccess++;
                                    status = <ArgonBadge badgeContent="200" max={99999}  variant="gradient" size="md"
                                                         color={"success"} container/>;
                                } else if (responseItem.data.response_code === 500) {
                                    countWarning++;
                                    status = <ArgonBadge badgeContent="500" max={99999} variant="gradient" size="md"
                                                         color={"warning"} container/>;
                                } else if (responseItem.data.response_code === 404) {
                                    countError++;
                                    status = <ArgonBadge badgeContent="404" max={99999} variant="gradient" size="md"
                                                         color={"error"} container/>;
                                }

                                if(hostInSitemap.length === 0) {
                                    hostInSitemap.push({value: item.hostname, label: item.hostname})
                                    setButtonDisabledAppExplorer(false)
                                }else{
                                    let check = false;
                                    hostInSitemap.forEach(
                                        (host) => {
                                            if (host.value === item.hostname) {
                                                check = true;
                                            }
                                        }
                                    )
                                    if(!check)
                                        hostInSitemap.push({ value: item.hostname, label: item.hostname })
                                }

                                return {
                                    sitemap: item.sitemap,
                                    hostname: item.hostname,
                                    status: status,
                                    creation_date: item.creation_date,
                                    url_discovered: url_discovered
                                };
                            })
                        );

                        setKnownSitemap(dataResponse.length);
                        setSuccessSitemap(countSuccess);
                        setWarningSitemap(countWarning);
                        setErrorSitemap(countError);
                        setIsLoading(false);
                        return Promise.resolve(dataSitemapTable);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            },
            refetchOnWindowFocus: false,
        });
    }

    //CREATE
    const { mutateAsync: newSitemap,
        isPending: isCreatingSitemap
    } = useNewSitemap();

    //CREATE action
    const openCreateConfirmModal = async () => {
        await newSitemap().then(r => {
            enqueueSnackbar('Reloaded data!',{variant:'info'})
        });
    };

    //CREATE function
    function useNewSitemap() {
        return useMutation({
            mutationFn: async () => {
                setKnownSitemap(0);
                setSuccessSitemap(0);
                setWarningSitemap(0);
                setErrorSitemap(0);
                await refetch();
                return Promise.resolve();
            },
        });
    }

    //DELETE
    const { mutateAsync: deleteSitemap,
        isPending: isDeletingSitemap
    } = useDeleteSitemap();

    //DELETE action
    const openDeleteConfirmModal = () => {
        deleteSitemap(table.getSelectedRowModel().rows).then(r => {
            enqueueSnackbar('Reloaded data!',{variant:'info'})
        });
    };

    //DELETE function
    function useDeleteSitemap() {
        return useMutation({
            mutationFn: async (sitemaps) => {
                try{
                    try{
                        let check = false;
                        for(const sitemap of sitemaps){
                            const response = await deleteSitemapBySitemapName(sitemap.id);
                            if(response.status === 200){
                                if(response.data.delete){
                                    check = true;
                                }
                            }else{
                                check = false;
                                break;
                            }
                        }
                        if(check){
                            setRowSelection({});
                            setKnownSitemap(0);
                            setSuccessSitemap(0);
                            setWarningSitemap(0);
                            setErrorSitemap(0);
                            enqueueSnackbar('Sitemap deleted!',{variant:'success'})
                            await refetch();
                            return Promise.resolve();
                        } else
                            enqueueSnackbar('Error! Sitemap not deleted!',{variant:'error'})
                            return Promise.reject();
                    }catch (e) {
                        enqueueSnackbar('Error!',{variant:'error'})
                        console.log(e);
                        return Promise.reject();
                    }
                }catch (e) {
                    enqueueSnackbar('Error!',{variant:'error'})
                    console.log(e);
                    return Promise.reject();
                }
            },
        });
    }

    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    const columnHelper = createMRTColumnHelper();

    const columns = [
        columnHelper.accessor('sitemap', {
            header: 'Sitemap',
            size: 150,
            muiTableHeadCellProps: {
                align: 'left',
            },
        }),
        columnHelper.accessor('hostname', {
            header: 'Host',
            size: 50,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },

        }),
        columnHelper.accessor('status', {
            header: 'Status',
            size: 50,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('creation_date', {
            header: 'Creation Date',
            size: 60,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('url_discovered', {
            header: 'URL Discovered',
            size: 70,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
    ];

    useEffect(() => {
        if(table.getSelectedRowModel().rows.length > 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [rowSelection]);

    const table = useMaterialReactTable({
        columns,
        data: dataSitemap,
        createDisplayMode: 'custom',
        onCreatingRowSave: openCreateConfirmModal,
        enableRowSelection: true,
        selectAllMode: 'all',
        enableBottomToolbar: true,
        enablePagination: true,
        positionToolbarAlertBanner: 'bottom',
        enableRowVirtualization: true,
        muiTableContainerProps: { sx: { maxHeight: '600px' } },
        onSortingChange: setSorting,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        state: {
            isSaving: isDeletingSitemap || isCreatingSitemap,
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            rowSelection},
        rowVirtualizerInstanceRef,
        rowVirtualizerOptions: { overscan: 25 },
        initialState: { density:   'compact', pagination: { pageSize: 25 }, columnVisibility: { creation_date: false } },
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100],
        },
        getRowId: (originalRow) => originalRow.sitemap,
        onRowSelectionChange: setRowSelection,
        muiTableBodyRowProps: ({ row }) => ({
            onClick: () =>
                setRowSelection((prevRowSelection) => {
                    const rowSelection = { ...prevRowSelection };
                    if (rowSelection[row.id]) {
                        delete rowSelection[row.id];
                    } else {
                        rowSelection[row.id] = true;
                    }
                    return rowSelection;
                }),
            selected: rowSelection[row.id],
            sx: {
                cursor: 'pointer',
            },
        }),
        muiTableHeadProps: {
            sx: {
                padding: '0 !important',
            },
        },
        muiFilterTextFieldProps: {
            sx: { m: '0.5rem 0', width: '100%' },
            variant: 'outlined',
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    disabled={dataSitemap.length === 0}
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportCSVData}
                    startIcon={<FileDownloadIcon />}
                >
                    Export All Data by CSV
                </Button>
                <Button
                    disabled={dataSitemap.length === 0}
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                        handleExportPDFRows(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                >
                    Export All Data by PDF
                </Button>
                <Button
                    disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    onClick={() => handleExportCSVRows(table.getSelectedRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                >
                    Export Selected Rows by CSV
                </Button>
                <Button
                    disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    onClick={() => handleExportPDFRows(table.getSelectedRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                >
                    Export Selected Rows by PDF
                </Button>
            </Box>
        ),
    });

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
    });

    const handleExportCSVRows = (rows) => {
        const rowData = [];
        //const rowData = rows.map((row) => row.original);
        for(let i in rows) {
            let rowArray = [];
            rowArray['sitemap'] = (rows[i].original.sitemap);
            rowArray['host'] = (rows[i].original.hostname)
            rowArray['url discovered'] = (rows[i].original.url_discovered.props.badgeContent);

            rowData.push(rowArray)
        }
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };

    const handleExportCSVData = () => {
        const rowData = [];
        //const rowData = rows.map((row) => row.original);
        for(let i in dataSitemap) {
            let rowArray = [];
            rowArray['sitemap'] = (dataSitemap[i].sitemap);
            rowArray['host'] = (dataSitemap[i].hostname)
            rowArray['url discovered'] = (dataSitemap[i].url_discovered.props.badgeContent);

            rowData.push(rowArray)
        }
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };

    const handleExportPDFRows = (rows: MRT_Row<>[]) => {
        const doc = new jsPDF();
        const tableData = [];
        //const tableData = rows.map((row) => Object.values(row.original));
        for(let i in rows) {
            let rowArray = [];
            rowArray.push(rows[i].original.sitemap,rows[i].original.hostname,rows[i].original.url_discovered.props.badgeContent)
            tableData.push(rowArray)
        }
        const tableHeaders = [];
        //const tableHeaders = columns.map((c) => c.header);
        for(let i in columns) {
            if(columns[i].header === 'Sitemap' || columns[i].header === 'Host' || columns[i].header === 'URL Discovered')
                tableHeaders.push(columns[i].header)
        }
        autoTable(doc, {
            head: [tableHeaders],
            body: tableData,
        });

        doc.save('sitemap-website.pdf');
    };

    if (!isLoggedIn) {
        return <Navigate replace to="/sign-in" />;
    }

    return (
        <div>
            <DashboardLayout>
                <Header />
                {showSitemapToUrls ?
                <div>
                    {showSitemapToApplicationExpl ?
                    <div id="showTable">
                        <ArgonBox mt={8} mb={10}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <DefaultCounterCard
                                        count={knownSitemap}
                                        title="Known Sitemap"
                                        color={"dark"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <DefaultCounterCard
                                        count={successSitemap}
                                        title="Sitemaps with success"
                                        color={"success"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <DefaultCounterCard
                                        count={warningSitemap}
                                        title="Sitemaps with warnings"
                                        color={"warning"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <DefaultCounterCard
                                        count={errorSitemap}
                                        title="Sitemaps with errors"
                                        color={"error"}
                                    />
                                </Grid>
                            </Grid>
                        </ArgonBox>
                        <ArgonBox mt={5} mb={3}>
                            <Card sx={{ minHeight: "calc(100vh - 30vh)", display: "flex" }}>
                                <Grid container p={5} direction="row" justifyContent="flex-end" alignItems="flex-start">
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <AddNewSitemap actionNewSitemap={openCreateConfirmModal} valueProgressBar={valueProgressBar}/>
                                        </Grid>
                                        <Grid item>
                                            <ArgonButton variant="gradient" color="info" disabled={buttonDisabled} onClick={actionShowUrls}>
                                                <Icon>language</Icon>&nbsp;
                                                Show URLs
                                            </ArgonButton>
                                        </Grid>
                                        <Grid item>
                                            <DeleteSitemap buttonDisabled={buttonDisabled} tableSitemap={table} actionDeleteSitemap={openDeleteConfirmModal}/>
                                        </Grid>
                                        <Grid item>
                                            <ArgonButton variant="gradient" color="info" disabled={buttonDisabledAppExplorer} onClick={actionShowApplicationExplorer}>
                                                <Icon>map</Icon>&nbsp;
                                                Application Explorer
                                            </ArgonButton>
                                        </Grid>
                                        <Grid item>
                                            <ArgonButton variant="gradient" color="info" onClick={() => refetch()}>
                                                <Icon>autorenew</Icon>&nbsp;
                                                Reload
                                            </ArgonButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12} xs={12} paddingTop={2}>
                                        <MaterialReactTable table={table} />
                                    </Grid>
                                </Grid>
                            </Card>
                        </ArgonBox>
                    </div>
                    :
                    <ApplicationExplorer showSitemp={setShowSitemapToApplicationExpl} hostSelected={hostInSitemap}/>
                    }
                </div> :
                    <UrlWebsite showSitemap={setShowSitemapToUrls} sitemapSelected={rowSelection} setSiteMapSelected={setRowSelection} refetchDataSitemap={refetch}/>
                }
                <Footer />
            </DashboardLayout>
        </div>
    );
}

const queryClient = new QueryClient();

const SitemapWebsite = () => (
    <QueryClientProvider client={queryClient}>
        <SitemapTable/>
    </QueryClientProvider>
);

export default SitemapWebsite;
