import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import Stack from "@mui/material/Stack";
import ArgonBox from "components/ArgonBox";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';

import { HUBAPI } from "api/BackendApi/HUB";
import ArgonTypography from 'components/ArgonTypography';
import { CircularProgress, IconButton } from '@mui/material';
import { Refresh } from "@mui/icons-material";


import colors from "assets/theme/base/colors";

const { primary } = colors;


const loading = () => {
  return <Stack direction='row' justifyContent='center'><CircularProgress /></Stack>;
};

function CapabilitySelectionStack({ title, outputValueUpdate, startingCapabilities }) {
  const [capabilities, setCapabilities] = useState(null);

  const [loadingCapabilitiesError, setLoadingCapabilitiesError] = useState(null);
  const [loadingCapabilities, setLoadingCapabilities] = useState(true);

  const [selectedCapabilities, setSelectedCapabilities] = useState(startingCapabilities ?? { index: [], cap: [] });

  useEffect(() => {
    loadCapabilities();
  }, []);

  const loadCapabilities = async () => {
    try {
      setLoadingCapabilities(true);
      var resp = await HUBAPI.getCapabilities()
      setCapabilities(resp['data']['capabilities']);
    } catch (e) {
      setLoadingCapabilitiesError(e);
    } finally {
      setLoadingCapabilities(false);
    };
  };

  const onCardClick = (index) => {
    var newValue = selectedCapabilities?.index ?? [];
    if (newValue.includes(index)) {
      newValue = newValue.filter(item => item !== index)
    } else {
      newValue.push(index)
    }

    var output = null;

    if (newValue.length > 0) {
      output = {
        index: newValue,
        cap: newValue.map(c => capabilities[c])
      }
    }

    setSelectedCapabilities(output)
    outputValueUpdate(title, output)
  };

  const capabilitiesToCards = () => {
    return capabilities.map((c, i) => {
      const isSelected = selectedCapabilities?.index.includes(i) ?? false;
      const selectedStyle = isSelected ? { outline: 1, outlineColor: "primary.main" } : {};
      const selectedStyleBg = isSelected ? {backgroundColor: primary.main + "09"} : {}
      return <Card key={i} sx={{ ...selectedStyle }} >
        <CardActionArea onClick={() => onCardClick(i)} >
          <CardContent sx={selectedStyleBg}>
            <ArgonBox >
              <Grid container alignItems="center" spacing={2} direction="column">
                <Grid item xs style={{ paddingTop: 8 }}>
                  <ArgonTypography>{c.name}</ArgonTypography>
                </Grid>
                <Divider mt={0} style={{ width: '100%', height: '2px', margin: '0.2rem' }} />
                <Grid item xs={12} style={{ paddingTop: 8 }} >
                  <ArgonBox display='flex' justifyContent='center'>
                    <ArgonBox component="img" src={c.image} alt={c.name} width="70%" sx={{ width: "7vw", height: "7vw", margin: "25px" }} />
                  </ArgonBox>
                </Grid>
              </Grid>
            </ArgonBox>
          </CardContent>
        </CardActionArea>
      </Card>
    }
    );
  };

  if (loadingCapabilities) {
    return loading();
  }

  if (loadingCapabilitiesError != null) {
    return <ArgonBox
      key={2}
      display="flex"
      justifyContent="center"
      alignItems="center">
      <ArgonTypography textAlign="center">{`Cannot load capabilities: ${loadingCapabilitiesError.message}`}</ArgonTypography>
      <IconButton onClick={loadCapabilities}><Refresh></Refresh></IconButton>
    </ArgonBox>;
  }

  return <Stack
    direction='row'
    spacing={2}
    useFlexGap
    flexWrap="wrap"
  >
    {capabilitiesToCards()}
  </Stack>;
}

CapabilitySelectionStack.propTypes = {
  title: PropTypes.string,
  outputValueUpdate: PropTypes.func,
  startingCapabilities: PropTypes.object
};



export default CapabilitySelectionStack;