import React, { useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import ArgonInput from "components/ArgonInput";

// Argon Dashboard 2 PRO MUI components
import { TestConfig } from "api/BackendApi/TestConfiguration";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress } from '@mui/material';
import {enqueueSnackbar} from "notistack";

function AddNewConfigurationView({ closeDialog, configType }) {

  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");

  const saveNewConfig = () => {
    setSaving(true);
    TestConfig.addConfig(name, notes, configType)
      .then((response) => enqueueSnackbar('New Configuration ' + name + ' added for ' + configType + '!',{variant:'success'}))
      .catch((error) => enqueueSnackbar('Error during the addition of Configuration',{variant:'error'}))
      .finally(() => {
        setSaving(false);
        closeDialog();
      });
  };

  return (
    <ArgonBox component="form" role="form">
      <ArgonTypography fontSize={14}>Name</ArgonTypography>
      <ArgonBox mb={2}>
        <ArgonInput
          placeholder="Name"
          value={name}
          size="medium"
          onChange={(e) => { setName(e.target.value) }}
        />
      </ArgonBox>
      <ArgonTypography fontSize={14}>Notes</ArgonTypography>
      <ArgonBox mb={2}>
        <ArgonInput
          placeholder="Notes"
          value={notes}
          size="medium"
          onChange={(e) => { setNotes(e.target.value) }}
        />
      </ArgonBox>
      <ArgonBox display="flex" justifyContent="center" mt={2}>
        <ArgonBox mr={1.5}>
            {name === "" ?
                <ArgonButton color="info" disabled>
                    <SaveIcon />
                    &nbsp; Save
                </ArgonButton>
                :
              <ArgonButton color="info" onClick={saveNewConfig}>
                {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                &nbsp; {saving ? "Saving..." : "Save"}
              </ArgonButton>
            }
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}


AddNewConfigurationView.propTypes = {
  closeDialog: PropTypes.func,
  configType: PropTypes.string
};

export default AddNewConfigurationView;
