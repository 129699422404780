import Grid from "@mui/material/Grid";
import {CardActions, CardHeader, Icon} from '@mui/material';
import Card from "@mui/material/Card";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ExpandMore} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import React, {useMemo, useState} from "react";
import {QueryClient, QueryClientProvider, useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import ArgonBadge from "../../../components/ArgonBadge";
import {styled} from "@mui/material/styles";
import {keyframes} from "@emotion/react";
import CardMedia from "@mui/material/CardMedia";
import ArgonButton from "../../../components/ArgonButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import {enqueueSnackbar} from "notistack";
import {Locator} from "../../../api/BackendApi/Locator";
import Backdrop from "@mui/material/Backdrop";
import ArgonBox from "../../../components/ArgonBox";
import Modal from "@mui/material/Modal";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonInput from "../../../components/ArgonInput";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

function PageLocatorsTable({page, isExpanded, loadPagesLocator}) {

    const [expanded, setExpanded] = useState(isExpanded);

    const [openCreateLocator, setOpenCreateLocator] = useState(false);
    const [openDeleteLocator, setOpenDeleteLocator] = useState(false);
    const [openDeletePage, setOpenDeletePage] = useState(false);

    const [nameLocator, setNameLocator] = useState("");
    const [xpathLocator, setXpathLocator] = useState("");

    const [deleteLocatorInfo, setDeleteLocatorInfo] = useState(null);

    const [namePageTitle, setNamePageTitle] = useState(page.page_title);
    const [newNamePageTitle, setNewNamePageTitle] = useState(null);

    const [namePage, setNamePage] = useState(page.page);
    const [newPage, setNewPage] = useState(null);

    const [isTypingPageTitle, setIsTypingPageTitle] = useState(false);
    const [isTypingPage, setIsTypingPage] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleOpenCreateLocatorModal = () => {
        setNameLocator("");
        setXpathLocator("");
        setOpenCreateLocator(true);
    }

    const handleOpenDeletePageModal = () => {
        setOpenDeletePage(true);
    }

    const handleCloseCreateLocatorModal = () => {
        setOpenCreateLocator(false);
    }

    const handleCloseDeleteLocatorModal = () => {
        setOpenDeleteLocator(false);
    }

    const handleCloseDeletePageModal = () => {
        setOpenDeletePage(false);
    }

    const handleDeletePage = async () => {
        const response = await Locator.deleteLocatorPageOnDB(namePageTitle,namePage, page.project);
        if(response.status === 200){
            if(response.data.delete_page > 0){
                enqueueSnackbar('Page deleted!',{variant:'success'});
                handleCloseDeletePageModal();
                await loadPagesLocator();
            }
        }else{
            enqueueSnackbar('Error!',{variant:'error'})
            handleCloseDeletePageModal();
        }
    };

    const updateLocatorPageTitle = async () => {
        if (newNamePageTitle === "" || newNamePageTitle === namePageTitle) return;

        let resp = await Locator.updatePageTitle(page._id, newNamePageTitle)
        if (resp.status === 200) {
            setNamePageTitle(newNamePageTitle);
            enqueueSnackbar("Page Title updated", { variant: "success" });
        }
    }

    const updateLocatorPage = async () => {
        if (newPage === "" || newPage === namePage) return;

        let resp = await Locator.updatePage(page._id, newPage)
        if (resp.status === 200) {
            setNamePage(newPage);
            enqueueSnackbar("Page updated", { variant: "success" });
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        bgcolor: 'background.paper',
        border: '2px',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
    };

    //call CREATE hook
    const { mutateAsync: createLocator, isPending: isCreatingLocator } =
        useCreateLocator();

    //call UPDATE hook
    const { mutateAsync: updateLocator, isPending: isUpdatingLocator } =
        useUpdateLocator();

    //call DELETE hook
    const { mutateAsync: deleteLocator, isPending: isDeletingLocator } =
        useDeleteLocator();

    //CREATE action
    const handleCreateLocator = async () => {
        await createLocator();
    };

    //UPDATE action
    const handleSaveLocator = async ({ values, table }) => {
        await updateLocator(values);
        table.setEditingRow(null);
    };

    //DELETE action
    const handleDeleteLocator = async () => {
        await deleteLocator(deleteLocatorInfo);
    };

    const openDeleteConfirmModal = async (value) => {
        setDeleteLocatorInfo(null)
        setOpenDeleteLocator(true);
        setDeleteLocatorInfo(value);
    };

    //READ function
    const {
        data: dataLocators = [],
        refetch,
    } = useGetLocatorsPage();

    //READ function
    function useGetLocatorsPage() {
        return useQuery({
            queryKey: ['page-' + page._id],
            queryFn: async () => {
                let locatorsObject = [];
                let response = await Locator.getLocators(page.page_title, page.page, page.project);

                let locatorsPage = response.data.selectors

                locatorsObject.push(
                    ...locatorsPage.map((loc, index) => {

                        const shapeStyles = { bgcolor: 'primary.main', width: 30, height: 30 };
                        const shapeCircleStyles = { borderRadius: '50%' };
                        const blink = keyframes` from { opacity: 0; } to { opacity: 1; }`;

                        let status = null;

                        switch (loc.status) {
                            case "OK":
                                status = <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#1aae6f' }} />
                                break;
                            case "KO":
                                status = <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#f80031', animation: `${blink} 1.5s linear infinite` }} />
                                break;
                            default:
                                status = <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#ffd0c4' }} />
                                break;
                        }

                        let visible = <ArgonBadge badgeContent="---" variant="contained" container color={"warning"}/>;
                        let enabled = <ArgonBadge badgeContent="---" variant="contained" container color={"warning"}/>;

                        if (loc.status === "OK" || loc.status === "KO") {
                            visible = loc.visible ?
                                <ArgonBadge badgeContent="True" variant="contained" container color={"success"}/> :
                                <ArgonBadge badgeContent="False" variant="contained" container color={"error"}/>;
                            enabled = loc.enabled ?
                                <ArgonBadge badgeContent="True" variant="contained" container color={"success"}/> :
                                <ArgonBadge badgeContent="False" variant="contained" container color={"error"}/>;
                        }

                        let img = null;
                        if (loc.image_id) {
                            const blob = new Blob([loc.image_id.data], {type: 'image/jpeg'});
                            img = window.URL.createObjectURL(blob);
                        }

                        return {
                            id: (index + 1).toString(),
                            name: loc.name,
                            xpath: loc.xpath,
                            status: status,
                            visible: visible,
                            enabled: enabled,
                            imageLocator: img,
                            xpath_secondary: loc.xpath_secondary,
                            attribute: loc.attribute,
                        };
                    })
                );
                return Promise.resolve(locatorsObject);
            },
            refetchOnWindowFocus: false,
            refetchInterval: 3000
        });
    }

    //CREATE hook
    function useCreateLocator() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async () => {

                const response = await Locator.createLocatorOnDB(page,nameLocator,xpathLocator);
                if(response.status === 200){
                    if(response.data.add_locator > 0){
                        enqueueSnackbar('Locator created!',{variant:'success'});
                        handleCloseCreateLocatorModal();
                        return Promise.resolve();
                    }else if (response.data.add_locator === -1){
                        enqueueSnackbar('Locator name already exists!',{variant:'warning'});
                        return Promise.resolve();
                    }
                }else{
                    enqueueSnackbar('Error!',{variant:'error'})
                    handleCloseCreateLocatorModal();
                    return Promise.reject();
                }
            },
            //client side optimistic update
            onMutate: () => {
                queryClient.setQueryData(['page-'+page._id], (prevLocators) => {

                    if(prevLocators?.find(locator => locator.name === nameLocator)){
                        return prevLocators;
                    } else {
                        return [
                            ...prevLocators,
                            {
                                id: (prevLocators.length + 1).toString(),
                                name: nameLocator,
                                xpath: xpathLocator,
                                status: "loading...",
                                visible: "loading...",
                                enabled: "loading...",
                                imageLocator: null,
                                xpath_secondary: null,
                                attribute: null,
                            },
                        ]
                    }
                });
            },
        });
    }


    //UPDATE hook
    function useUpdateLocator() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (locator) => {
                let dataToModify = {
                    id: locator.id,
                    name: locator.name,
                    xpath: locator.xpath
                }
                const response = await Locator.updateLocatorOnDB(page,dataToModify);
                if(response.status === 200){
                    if(response.data.change_locator > 0){
                        enqueueSnackbar('Locator updated!',{variant:'success'});
                        refetch()
                        return Promise.resolve();
                    }
                }else{
                    enqueueSnackbar('Error!',{variant:'error'})
                    return Promise.reject();
                }
            },
            //client side optimistic update
            onMutate: (newLocatorInfo) => {
                queryClient.setQueryData(['page-'+page._id], (prevLocators) =>{
                    prevLocators?.map((loc) => {
                        return loc ? newLocatorInfo : loc;
                    })
                });
            },
        });
    }

    //DELETE hook (delete user in api)
    function useDeleteLocator() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (locatorName) => {
                const response = await Locator.deleteLocatorOnDB(page,locatorName);
                if(response.status === 200){
                    if(response.data.delete_locator > 0){
                        enqueueSnackbar('Locator deleted!',{variant:'success'});
                        handleCloseDeleteLocatorModal();
                        return Promise.resolve();
                    }
                }else{
                    enqueueSnackbar('Error!',{variant:'error'})
                    handleCloseDeleteLocatorModal();
                    return Promise.reject();
                }
            },
            //client side optimistic update
            onMutate: (deleteLocator) => {
                queryClient.setQueryData(['page-'+page._id], (prevLocators) =>
                    prevLocators?.filter((loc) => loc.name !== deleteLocator).map((loc, i) => {
                        loc.id = (i+1).toString();
                        return loc;
                    })
                );
            },
        });
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableResizing: false,
                enableEditing: false,
            },
            {
                accessorKey: 'name',
                header: 'Name',
                size: 200,
            },
            {
                accessorKey: 'xpath',
                header: 'Locator',
                size: 450,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 150,
                enableResizing: false,
                enableEditing: false,
            },
            {
                accessorKey: 'visible',
                header: 'Visible',
                size: 150,
                enableResizing: false,
                enableEditing: false,
            },
            {
                accessorKey: 'enabled',
                header: 'Enabled',
                size: 170,
                enableResizing: false,
                enableEditing: false,
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: dataLocators,
        defaultColumn: {
            maxSize: 3000,
            minSize: 130,
            size: 100,
        },
        state: {
            isSaving: isUpdatingLocator || isDeletingLocator || isCreatingLocator,
        },
        enableColumnResizing: true,
        columnResizeMode: 'onEnd',
        initialState: { density:   'compact', pagination: { pageSize: 25 }},
        enableBottomToolbar: true,
        enablePagination: true,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        createDisplayMode: 'custom',
        editDisplayMode: 'row',
        enableEditing: window.user.canEditLocator(),
        onEditingRowSave: handleSaveLocator,
        getRowId: (row) => row.id,  //custom row id
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100],
        },
        muiTableHeadProps: {
            sx: {
                padding: '0 !important',
            },
        },
        muiEditTextFieldProps: {
            inputProps: {
                sx: {
                    width: '100% !important',
                    height: '100% !important',
                },
            },
        },
        //custom expand button rotation
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
            },
        }),
        renderDetailPanel: ({ row }) =>
            <Box
                sx={{
                    width: '1500px',
                }}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <ArgonTypography variant="h5" textAlign="center"> Select Principal xPath </ArgonTypography>
                        {row.original.xpath_secondary ?
                            (<FormControl sx={{ ml: 20, mt: 1 }}>
                                <RadioGroup
                                    aria-labelledby="demo-customized-radios"
                                    name="customized-radios"
                                    defaultValue={row.original.xpath}
                                >
                                    <FormControlLabel value={row.original.xpath} control={<Radio  />} label={row.original.xpath}/>
                                    {row.original.xpath_secondary.map((xpath) => {
                                        const elem = <FormControlLabel value={xpath} key={xpath} control={<Radio  />} label={xpath} />
                                        return elem
                                    })}
                                </RadioGroup>
                            </FormControl>)
                            :
                            <ArgonTypography variant="h5" mt={2} textAlign="center">N/A</ArgonTypography>}
                    </Grid>
                    <Grid item xs={6}>
                        <ArgonTypography variant="h5" textAlign="center"> Image </ArgonTypography>
                        {row.original.imageLocator ? (
                            <ArgonBox mt={2}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        alt="icon locator"
                                        height="140"
                                        image={row.original.imageLocator}
                                    />
                                    <CardActions>
                                        <ArgonButton variant="gradient" size="small">Crop new Icon</ArgonButton>
                                        <ArgonButton variant="gradient" size="small" disabled={!window.user.canDeleteLocator()}>Delete</ArgonButton>
                                    </CardActions>
                                </Card>
                            </ArgonBox>
                            ) :
                            (<ArgonBox mt={2}>
                                <Grid container spacing={2} sx={{justifyContent: 'center' }}>
                                    <Grid item >
                                        <ArgonButton variant="gradient" size="small">Crop Icon</ArgonButton>
                                    </Grid>
                                    <Grid item>
                                        <ArgonButton variant="gradient" size="small">Import Crop Image</ArgonButton>
                                    </Grid>
                                </Grid>
                            </ArgonBox>)}
                    </Grid>
                </Grid>
            </Box>,
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" disabled={!window.user.canDeleteLocator()} onClick={() => openDeleteConfirmModal(row.original.name)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        )
    });

    return (
        <Grid container justifyContent="center" alignItems="center" mt={5} id={page.url} >
            <Grid item xs={12} lg={12}>
                <Card>
                    <CardHeader title={isTypingPageTitle ?
                        <Grid container>
                            <Grid item xs={11}>
                                <ArgonInput
                                    autoFocus
                                    value={newNamePageTitle}
                                    onChange={({ currentTarget }) => setNewNamePageTitle(currentTarget.value)}
                                    onBlur={({ currentTarget }) => {
                                        setIsTypingPageTitle(false);
                                        updateLocatorPageTitle(currentTarget.value)
                                    }}
                                />
                            </Grid>
                        </Grid>
                        :
                        <ArgonTypography
                            variant="h5"
                            fontWeight="medium"
                            textTransform="capitalize"
                            onClick={() => {
                                setIsTypingPageTitle(true);
                                setNewNamePageTitle(namePageTitle)
                            }}>
                            {namePageTitle}
                        </ArgonTypography>
                    }
                                subheader={isTypingPage ?
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <ArgonInput
                                                sx={{ marginTop: '10px' }}
                                                size="small"
                                                autoFocus
                                                value={newPage}
                                                onChange={({ currentTarget }) => setNewPage(currentTarget.value)}
                                                onBlur={({ currentTarget }) => {
                                                    setIsTypingPage(false);
                                                    updateLocatorPage(currentTarget.value)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    :
                                    <ArgonTypography
                                        variant="overline"
                                        onClick={() => {
                                            setIsTypingPage(true);
                                            setNewPage(namePage)
                                        }}>
                                        {namePage}
                                    </ArgonTypography>
                                }
                                action={
                                    <ExpandMore expand={expanded.toString()} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                }
                    />
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Grid container>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ArgonButton variant="gradient" color="info" disabled={!window.user.canSelfHealing()}>
                                            <Icon>healing</Icon>&nbsp;
                                            Self Healing
                                        </ArgonButton>
                                    </Grid>
                                    <Grid item>
                                        <ArgonButton variant="gradient" color="info" disabled={!window.user.canAddLocator()} onClick={() => handleOpenCreateLocatorModal()}>
                                            <Icon>add</Icon>&nbsp;
                                            Add Locator
                                        </ArgonButton>
                                    </Grid>
                                    <Grid item>
                                        <ArgonButton variant="gradient" color="error" onClick={() => handleOpenDeletePageModal()}>
                                            <Icon>delete</Icon>&nbsp;
                                            Delete Page
                                        </ArgonButton>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12} paddingTop={2}>
                                    <MaterialReactTable table={table}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>
            <Modal open={openDeleteLocator}
                   onClose={(event, reason) => {
                       if (reason !== 'backdropClick') {
                           handleCloseDeleteLocatorModal(event, reason);
                       }
                   }}
                   closeAfterTransition
                   slots={{ backdrop: Backdrop }}
                   slotProps={{
                       backdrop: {
                           timeout: 500,
                       },
                   }}
                   aria-labelledby="delete-locator"
                   aria-describedby="delete-locator" >
                <ArgonBox sx={style}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} mb={2} textAlign="center">
                            <ArgonTypography variant="h3" fontWeight="medium">
                                Delete Locator
                            </ArgonTypography>
                            <ArgonTypography variant="h6" fontWeight="regular">
                                Are you sure you want to delete this locator?
                            </ArgonTypography>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="dark" variant="gradient" onClick={handleCloseDeleteLocatorModal}>Back</ArgonButton>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="error" variant="gradient" onClick={handleDeleteLocator}>Delete</ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Modal>
            <Modal open={openDeletePage}
                   onClose={(event, reason) => {
                       if (reason !== 'backdropClick') {
                           handleCloseDeleteLocatorModal(event, reason);
                       }
                   }}
                   closeAfterTransition
                   slots={{ backdrop: Backdrop }}
                   slotProps={{
                       backdrop: {
                           timeout: 500,
                       },
                   }}
                   aria-labelledby="delete-page"
                   aria-describedby="delete-page" >
                <ArgonBox sx={style}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} mb={2} textAlign="center">
                            <ArgonTypography variant="h3" fontWeight="medium">
                                Delete Page
                            </ArgonTypography>
                            <ArgonTypography variant="h6" fontWeight="regular">
                                Are you sure you want to delete this page?
                            </ArgonTypography>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="dark" variant="gradient" onClick={handleCloseDeletePageModal}>Back</ArgonButton>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="error" variant="gradient" onClick={handleDeletePage}>Delete</ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Modal>
            <Modal open={openCreateLocator}
                   onClose={(event, reason) => {
                       if (reason !== 'backdropClick') {
                           handleCloseCreateLocatorModal(event, reason);
                       }
                   }}
                   closeAfterTransition
                   slots={{ backdrop: Backdrop }}
                   slotProps={{
                       backdrop: {
                           timeout: 500,
                       },
                   }}
                   aria-labelledby="create-locator"
                   aria-describedby="create-locator" >
                <ArgonBox sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} mb={2} textAlign="center">
                            <ArgonTypography variant="h3" fontWeight="medium">
                                Create Locator
                            </ArgonTypography>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <ArgonTypography variant="h6" fontWeight="medium">
                                Name Locator
                            </ArgonTypography>
                            <ArgonInput
                                placeholder="Name"
                                startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                                onChange={(event) => setNameLocator(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ArgonTypography variant="h6" fontWeight="medium">
                                xPath Locator
                            </ArgonTypography>
                            <ArgonInput
                                placeholder="xPath"
                                startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                                onChange={(event) => setXpathLocator(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} mt={1} justifyContent="center">
                        <Grid item>
                            <ArgonButton color="dark" variant="gradient" onClick={handleCloseCreateLocatorModal}>Cancel</ArgonButton>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="info" variant="gradient" onClick={handleCreateLocator} disabled={nameLocator === "" || xpathLocator === ""}>Create</ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Modal>
        </Grid>
    );
}

const queryClient = new QueryClient();

const PageLocators = ({page, isExpanded, loadPagesLocator}) => (
    <QueryClientProvider client={queryClient}>
        <PageLocatorsTable page={page} isExpanded={isExpanded} loadPagesLocator={loadPagesLocator}/>
    </QueryClientProvider>
);


export default PageLocators;
