/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Icon from "@mui/material/Icon";
import Checkbox from '@mui/material/Checkbox';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import ArgonPagination from "components/ArgonPagination";

// Argon Dashboard 2 PRO MUI example components
import DataTableHeadCell from "components/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/DataTable/DataTableBodyCell";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  onSelectionChange,
  enableSelection,
  actions,
  selectedColumnWidth,
  selectableRowsHeader,
  searchLocation = "right",
  onTableUpdate = null
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];

  const [pageIndex, setPageIndex] = useState(0)
  const [internalSortBy, setInternalSortBy] = useState([]);

  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: pageIndex, sortBy: internalSortBy }, manualSortBy: false },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageSize, globalFilter, sortBy },
  } = tableInstance;

  useEffect(() => {
    setInternalSortBy(sortBy);
  }, sortBy);

  // Set the default value for the entries per page when components mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  useEffect(() => {
    if (selected.length != 0) {
      var toDeselect = [];
      var allRowIds = [];
      for (var row of page) {
        allRowIds.push(row.id);
      }

      for (var rowId of selected) {
        if (allRowIds.indexOf(rowId) === -1) {
          toDeselect.push(rowId);
        }
      }

      for (var rowId of toDeselect) {
        selected.splice(selected.indexOf(rowId), 1);
      }
    }

    setSelected(selected);
  }, [table]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <ArgonPagination
      item
      key={option}
      onClick={() => goToPageInternal(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </ArgonPagination>
  ));

  const goToPageInternal = (n) => {
    setPageIndex(n);
    gotoPage(n);
  };

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? goToPageInternal(0) : goToPageInternal(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => goToPageInternal(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);
  const [selected, setSelected] = useState([]);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const setSelection = (newSelection) => {
    setSelected(newSelection);
    if (onSelectionChange != null) {
      onSelectionChange(newSelection.map((rowId) => page.find(p => p.id == rowId)));
    }
  }

  const handleClick = (event, rowId) => {
    // console.log(event.target.localName); // "textarea"
    if (event.target.className?.indexOf?.("MuiInputBase") != -1) return;
    if (event.target.className?.indexOf?.("MuiStack") != -1) return;

    const selectedIndex = selected.indexOf(rowId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelection(newSelected);
  };

  const isSelected = (rowId) => selected.indexOf(rowId) !== -1;

  const allRowsSelected = () => selected.length == rows.length;

  const selectAllRows = () => {
    if (allRowsSelected()) {
      setSelection([]);
    } else {
      setSelection(rows.map(r => r.id));
    }
  };

  return (
    <TableContainer sx={{ boxShadow: "none", overflowX: "unset" }}>
      {entriesPerPage || canSearch || actions ? (
        <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && entries.length > 0 && (
            <ArgonBox display="flex" alignItems="center">
              <ArgonBox width="25%">
                <ArgonSelect
                  defaultValue={{ value: defaultValue, label: defaultValue }}
                  options={entries.map((entry) => ({ value: entry, label: entry }))}
                  onChange={setEntriesPerPage}
                  size="small"
                />
              </ArgonBox>
              <ArgonTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </ArgonTypography>
            </ArgonBox>
          )}
          {(canSearch && searchLocation == "left") && (
            <ArgonBox width="12rem" mr="auto">
              <ArgonInput
                placeholder="Search..."
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </ArgonBox>
          )}
          {(canSearch && searchLocation == "right") && (
            <ArgonBox width="12rem" ml="auto">
              <ArgonInput
                placeholder="Search..."
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </ArgonBox>
          )}
          {actions && actions}
        </ArgonBox>
      ) : null}
      <Table {...getTableProps()} sx={{ tableLayout: "fixed" }} >
        <ArgonBox component="thead">
          {headerGroups.map((headerGroup, key) => {
            return (<TableRow
              key={key}
              sx={{ width: "100%" }}
            >
              {(enableSelection && page.length > 0) && <DataTableHeadCell key={0} width={"1vw"} padding="checkbox" align="center" hideIcon>
                <Checkbox
                  color="primary"
                  checked={allRowsSelected()}
                  onClick={selectAllRows}
                  inputProps={{
                    'aria-labelledby': "select-all",
                  }}
                />
              </DataTableHeadCell>}
              {headerGroup.headers.map((column, index) => (
                <DataTableHeadCell
                  key={index + 1}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  margin={index == 0 && !enableSelection ? 2 : 1}
                  align={column.align ? column.align : "left"}
                  hideIcon={column.hideIcon}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>)
          })}
        </ArgonBox>
        <TableBody {...getTableBodyProps()}>

          {page.map((row, key) => {
            prepareRow(row);
            const labelId = `enhanced-table-checkbox-${row.id}`;
            const isRowSelected = isSelected(row.id);
            return (
              <TableRow
                key={key}
                onClick={(event) => handleClick(event, row.id, row)}
                {...row.getRowProps()}
              >
                {enableSelection && <DataTableBodyCell key={0} width={"1vw"} padding="checkbox" align="center">
                  <Checkbox
                    color="primary"
                    checked={isRowSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </DataTableBodyCell>}
                {row.cells.map((cell, index) => {
                  return <DataTableBodyCell
                    key={index + 1}
                    width={cell.column.width ? cell.column.width : "auto"}
                    maxWidth={cell.column.maxWidth}
                    minWidth={cell.column.minWidth}
                    margin={index == 0 && !enableSelection ? 2 : 1}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <ArgonBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <ArgonBox mb={{ xs: 3, sm: 0 }}>
            <ArgonTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </ArgonTypography>
          </ArgonBox>
        )}
        {pageOptions.length > 1 && (
          <ArgonPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {renderPagination.length > 6 ? (
              <ArgonBox width="5rem" mx={1}>
                <ArgonInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </ArgonBox>
            ) : (
              renderPagination
            )}
          </ArgonPagination>
        )}
      </ArgonBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  enableSelection: true
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  enableSelection: PropTypes.bool,
  selectedColumnWidth: PropTypes.string,
  selectableRowsHeader: PropTypes.bool
};

export default DataTable;
