import React, {useState} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";


import "../../pages/chatbot/style/chatbot.css";

// @mui material components
import Link from "@mui/material/Link";
import avatarChatbot from "assets/images/chatbot/taffy.png";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import typography from "assets/theme/base/typography";
import ArgonAvatar from "../ArgonAvatar";
import {SnackbarProvider} from "notistack";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;
  const [showChatBot, setShowChatBot] = useState(false);


  
  const handleShowChatBot = () =>{
      if(showChatBot){
          setShowChatBot(false);
          let myElement = document.querySelector(".app-chatbot-container");
          myElement.style.display = "none";
      }
      else{
          setShowChatBot(true);
          let myElement = document.querySelector(".app-chatbot-container");
          myElement.style.display = "block";
      }
  }

  const renderLinks = () =>
    links.map((link) => (
      <ArgonBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <ArgonTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </ArgonTypography>
        </Link>
      </ArgonBox>
    ));

    const loadMessages = () => {
        return JSON.parse(localStorage.getItem("chat_messages"));
    }

  return (
      <div>
          <SnackbarProvider maxSnack={5}/>
          <ArgonBox
              width="100%"
              display="flex"
              flexDirection={{ xs: "column", lg: "row" }}
              justifyContent="space-between"
              alignItems="center"
              px={1.5}
          >
              <ArgonBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                  color="text"
                  fontSize={size.sm}
                  px={1.5}
              >
                  &copy; {new Date().getFullYear()}, made by
                  <Link href={href} target="_blank">
                      <ArgonTypography variant="button" fontWeight="medium">
                          &nbsp;Concept Quality&nbsp;
                      </ArgonTypography>
                  </Link>
              </ArgonBox>
              <ArgonBox
                  component="ul"
                  sx={({ breakpoints }) => ({
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "center",
                      listStyle: "none",
                      mt: 3,
                      mb: 0,
                      p: 0,

                      [breakpoints.up("lg")]: {
                          mt: 0,
                      },
                  })}
              ></ArgonBox>
              {window.taffy && <div>
                <div className="app-chatbot-container" style={{ border: 'none', borderRadius: '20px', display: 'none' }} >
                    <iframe
                        src={process.env.REACT_APP_CHATBOT}  /* URL of the chatbot interface */
                        width="100%"  /* The iframe takes the full width of the parent element */
                        height="550px"  /* Fixed height of the iframe */
                        title="Embedded Webpage"  /* Descriptive title for accessibility purposes */
                        style={{ border: 'none', borderRadius: '20px' }}  /* Additional styling options */
                    ></iframe>
                </div>
                <button onClick={handleShowChatBot} className="app-chatbot-button">
                    <ArgonAvatar
                        src={avatarChatbot}
                        alt="chatBot-image"
                        size="xl"
                        sx={{ transform: "scaleX(-1)"}}
                    />
                </button >
              </div>}
          </ArgonBox>
      </div>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://www.google.com/", name: "Concept Quality" },
  links: [
    { href: "https://www.google.com/", name: "Concept Quality" },
    { href: "https://www.google.com/presentation", name: "FolderTree Us" },
    { href: "https://www.google.com/blog", name: "Blog" },
    { href: "https://www.google.com/license", name: "License" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
