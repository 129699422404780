import React, { useState } from "react";

// @mui material components
import ArgonInput from "components/ArgonInput";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import SaveIcon from '@mui/icons-material/Save';
import {CircularProgress} from '@mui/material';
import {enqueueSnackbar} from "notistack";
import {Roles} from "../../../api/BackendApi/Roles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import EditIcon from "@mui/icons-material/Edit";

function EditRoleName({role, roleName, load}) {

    const [saving, setSaving] = useState(false);
    const [name, setName] = useState(roleName);
    const [roleNameDialogOpen, setRoleNameDialogOpen] = useState(false);

    const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
        width: pxToRem(34),
        minWidth: pxToRem(34),
        height: pxToRem(34),
        minHeight: pxToRem(34),
        mr: 1
    });

    const handleClose = () => {
        setRoleNameDialogOpen(false);
    };

    const handleOpen = () => {
        setRoleNameDialogOpen(true);
    };

    const editNameRole = () => {
        setSaving(true);
        Roles.editNameRole(name, role)
          .then(() => enqueueSnackbar('Role name modified!',{variant:'success'}))
          .catch(() => enqueueSnackbar('Error during the modification of Name',{variant:'error'}))
          .finally(() => {
            setSaving(false);
            handleClose();
            load();
          });
    };

  return (
      <div>
          {window.user.canEditRole() && <ArgonButton variant="contained" color="info" size="small" iconOnly sx={buttonStyleLeft} onClick={handleOpen}><EditIcon /></ArgonButton>}
          <Dialog
              open={roleNameDialogOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={"md"}
          >
              <DialogTitle textAlign="center">{"Edit Role Name"}</DialogTitle>
              <DialogContent>
                  <ArgonBox padding={2} paddingTop={0} width="100%" component="form" role="form">
                      <ArgonTypography fontSize={14}>Role Name</ArgonTypography>
                      <ArgonBox>
                          <ArgonInput
                              placeholder="Name"
                              value={name}
                              size="medium"
                              onChange={(e) => { setName(e.target.value) }}
                          />
                      </ArgonBox>
                      <ArgonBox display="flex" justifyContent="center" mt={3}>
                          <ArgonBox mr={1.5}>
                              {name === "" ?
                                  <ArgonButton color="info" disabled>
                                      <SaveIcon />
                                      &nbsp; Save
                                  </ArgonButton>
                                  :
                                  <ArgonButton color="info" onClick={editNameRole}>
                                      {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                                      &nbsp; {saving ? "Saving..." : "Save"}
                                  </ArgonButton>
                              }
                          </ArgonBox>
                      </ArgonBox>
                  </ArgonBox>
              </DialogContent>
          </Dialog>
      </div>
  );
}

export default EditRoleName;