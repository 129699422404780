import { useState } from "react";
import { UsersApi } from "api/BackendApi/Users";
import { useNavigate } from "react-router-dom";

export default () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const reset = () => {
    setUser(null);
    setError(null);
    setIsLoading(false);
  };

  const signIn = async (username, password, rememberMe, callback) => {
    setIsLoading(true);
    try {
      var response = await UsersApi.login(username, password);
      setUser(response.data);
      if (rememberMe) {
        localStorage.setItem("jwt-token", response.data.access_token);
      } else {
        sessionStorage.setItem("jwt-token", response.data.access_token);
      }
      console.log("Navigate to home page");
      navigate("/dashboards/");
    } catch (e) {
      setError([e]);
    } finally {
      setIsLoading(false);
      callback?.("SignIn");
    }
  };

  const ensureLoggedIn = () => {
    var sessionToken = sessionStorage.getItem("jwt-token");
    var localToken = localStorage.getItem("jwt-token");
    if (sessionToken == null && localToken == null) {
      console.log("Not logged in");
      return false;
    }
    return true;
  };

  return {
    user,
    error,
    isLoading,
    reset,
    signIn,
    ensureLoggedIn,
  };
};
