import {useEffect} from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../utils/useAuth";
import ArgonBox from "../../components/ArgonBox";
import Header from "../../components/Header";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";

function ChatbotPage() {

  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  useEffect(() => {

  }, []);

  return (
    <DashboardLayout>
      <Header />
      <ArgonBox mt={5} mb={3}>
        <Card sx={{ minHeight: "calc(100vh - 20vh)"}}>
          <iframe
              src={process.env.REACT_APP_CHATBOT}  /* URL of the chatbot interface */
              width="100%"  /* The iframe takes the full width of the parent element */
              height="750px"  /* Fixed height of the iframe */
              title="Embedded Webpage"  /* Descriptive title for accessibility purposes */
              style={{ border: 'none' }}  /* Additional styling options */
              onLoad={(event) => {
                console.log("Chatbot loaded")
              }}
          ></iframe>
        </Card>
      </ArgonBox>
    </DashboardLayout>
  );
}


export default ChatbotPage;
