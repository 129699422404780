/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ArgonDropzone from "components/ArgonDropzone";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";

function UploadBinaryDialog({ open, onClose }) {

  const dropzoneOptions = {
    addRemoveLinks: true,
    url: "/be/hub/binary/upload",
    maxFiles: 1,
    multiple: false,
    autoProcessQueue: true,
    method: "POST",
    withCredentials: true,
    headers: {
      "Authorization": "Bearer " + (localStorage.getItem("jwt-token") || sessionStorage.getItem("jwt-token"))
    },

    init: function () {
      this.on("complete", (file) => {
        onClose();
        if (file.status === 'error') {
          enqueueSnackbar('There was an error uploading the artifact.', { variant: 'error' });
          console.log(file);
        } else if (file.status === 'canceled') {
          enqueueSnackbar('Artifact upload canceled', { variant: 'warning' });
          console.log(file);
        } else {
          enqueueSnackbar('Artifact uploaded', { variant: 'success' });
        }
      });

      this.on("maxfilesexceeded", function (file) {
        this.removeAllFiles();
        this.addFile(file);
      });
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ "& .MuiDialog-paper": { width: "100%" } }
      }
      fullWidth={"md"}
      maxWidth={"md"}
    >
      <DialogTitle textAlign="center">{"Upload Binary"}</DialogTitle>
      <DialogContent>
        <div className="dropmehere" id="dropmehere">
          <ArgonDropzone options={dropzoneOptions} />
        </div>
      </DialogContent>
    </Dialog >
  );
}

function UploadArtifact({ user }) {

  const [uploaBinaryOpen, setUploadBinaryOpen] = useState(false);

  const onCloseBinUpload = (reason) => {
    setUploadBinaryOpen(false);
  };


  const handleUpload = () => {
    //TODO
  };

  return (
    <Card id="upload-artifact">
      <UploadBinaryDialog open={uploaBinaryOpen} onClose={onCloseBinUpload} />
      <ArgonBox p={3} lineHeight={1}>
        <ArgonBox mb={1}>
          <ArgonTypography variant="h5">Upload Artifact</ArgonTypography>
        </ArgonBox>
        <ArgonTypography variant="button" color="text" fontWeight="regular">
          Upload your artifact in the orchestrator
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox
        pb={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <ArgonBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>

        </ArgonBox>
        <ArgonBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <ArgonButton variant="contained" color="primary" onClick={() => setUploadBinaryOpen(true)}>
            Upload
          </ArgonButton>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default UploadArtifact;
