import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const excelExport = (rows) => {

  const mappedRows = [];
  rows.forEach((rowWrapper) => {
    const row = rowWrapper.original
    const testDeclarationRow = {
      'id': row.id,
      'test_name': row.test_name,
      'area': row.area,
      'step_num': row.steps[0] ? row.steps[0].step : "none",
      'action': row.steps[0] ? row.steps[0].action : "none",
      'expected_result': row.steps[0] ? row.steps[0].expected_result : "none",
    }
    mappedRows.push(testDeclarationRow);
    row.steps.slice(1).forEach((step) => mappedRows.push({
          'id': "",
          'test_name': "",
          'area': "",
          'step_num': step.step,
          'action': step.action,
          'expected_result': step.expected_result,
        })
    );
  });

  const worksheet = XLSX.utils.json_to_sheet(mappedRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
  saveAs(blob, `TestBook.xlsx`);
};

export {excelExport}