import React, { useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import ArgonInput from "components/ArgonInput";

// Argon Dashboard 2 PRO MUI components
import { TestConfig } from "api/BackendApi/TestConfiguration";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import SaveIcon from '@mui/icons-material/Save';
import {CircularProgress, FormGroup, FormHelperText, FormLabel} from '@mui/material';
import {enqueueSnackbar} from "notistack";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function AddNewConfigurationTypeView({ closeDialog, modulesActive }) {

    const [saving, setSaving] = useState(false);
    const [name, setName] = useState("");
    const [moduleSelected, setModuleSelected] = React.useState(["Dashboard", "Test Execution"]);

    const handleChange = (event) => {
        if(moduleSelected.includes(event.target.name)){
            const index = moduleSelected.indexOf(event.target.name);
            if (index > -1) {
                moduleSelected.splice(index, 1);
            }
        }else{
            moduleSelected.push(event.target.name)
        }
    };

    const saveNewType = () => {
        setSaving(true);
        TestConfig.addType(name,moduleSelected)
          .then((response) => enqueueSnackbar('New Type ' + name + ' created!',{variant:'success'}))
          .catch((error) => enqueueSnackbar('Error during the creation of Type',{variant:'error'}))
          .finally(() => {
            setSaving(false);
            closeDialog();
          });
    };

    function checkModule() {
        const moduleList = [];
        modulesActive.forEach(function(mod) {
            let checkbox = <Checkbox onChange={handleChange}/>;
            if(mod.name === "Dashboard" || mod.name === "Test Execution"){
                checkbox = <Checkbox defaultChecked onChange={handleChange}/>;
            }
            moduleList.push(
                <Grid item xs={6} key={mod._id}>
                    <FormControlLabel
                        control={checkbox}
                        label={mod.name}
                        name={mod.name}
                    />
                </Grid>
            )
        })
        return moduleList;
    }

    let body = (
        <FormGroup>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} pt={"1em !important"} pl={"2em !important"}>
                {checkModule()}
            </Grid>
        </FormGroup>
    );

  return (
    <ArgonBox component="form" role="form">
        <ArgonTypography fontSize={14}>Name</ArgonTypography>
        <ArgonBox mb={2}>
            <ArgonInput
              placeholder="Name"
              value={name}
              size="medium"
              onChange={(e) => { setName(e.target.value) }}
            />
        </ArgonBox>
        <ArgonBox mt={1} sx={{ maxHeight: '30em', overflow: "auto"}}>
            <ArgonTypography fontSize={14}>Modules:</ArgonTypography>
                {body}
        </ArgonBox>
        <ArgonBox display="flex" justifyContent="center" mt={2}>
            <ArgonBox mr={1.5}>
                {name === "" || moduleSelected.length === 0 ?
                    <ArgonButton color="info" disabled>
                        <SaveIcon />
                        &nbsp; Save
                    </ArgonButton>
                    :
                    <ArgonButton color="info" onClick={saveNewType}>
                        {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                        &nbsp; {saving ? "Saving..." : "Save"}
                    </ArgonButton>
                }
            </ArgonBox>
        </ArgonBox>
    </ArgonBox>
  );
}


AddNewConfigurationTypeView.propTypes = {
  closeDialog: PropTypes.func
};

export default AddNewConfigurationTypeView;
