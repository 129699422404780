import React, {useState} from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import ArgonTypography from "../../../components/ArgonTypography";
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonButton from "../../../components/ArgonButton";
import ArgonBox from "../../../components/ArgonBox";
import Table from "../../../components/Table";
import {LoadingButton} from "@mui/lab";
import ArgonProgress from "../../../components/ArgonProgress";

function AddNewUrls({sitemapSelected, actionAddNewUrl, valueProgressBarNewUrl}) {

    const [openModalNewUrl, setOpenModalNewUrl] = useState(false);
    const [buttonSaveNewUrl, setButtonSaveNewUrl] = useState(true);

    const handleCloseModal = () => setOpenModalNewUrl(false);
    const handleOpenModal = () => {
        setOpenModalNewUrl(true);

    }

    const loadingTable = () => {
        let dataTable = [];
        for (const sitemap of Object.keys(sitemapSelected)) {
            dataTable.push({
                sitemap: sitemap,
            });
        }

        return (<Table
            columns={[
                { name: "sitemap", align: "center" },
            ]}
            rows={dataTable}
        />)
    };

    const handleActionAddUrl = async () => {
        setButtonSaveNewUrl(false);
        await actionAddNewUrl();
        setOpenModalNewUrl(false);
        setButtonSaveNewUrl(true);
    };

    let body = loadingTable();

  return (
      <div>
          <ArgonButton variant="gradient" color="info" onClick={handleOpenModal}>
              <Icon>download</Icon>&nbsp;
              Download URLs
          </ArgonButton>
          <Modal open={openModalNewUrl}
                 onClose={(event, reason) => {
                     if (reason !== 'backdropClick') {
                         handleCloseModal(event, reason);
                     }
                 }}
                 closeAfterTransition
                 slots={{ backdrop: Backdrop }}
                 slotProps={{
                     backdrop: {
                         timeout: 500,
                     },
                 }}
                 aria-labelledby="delete-urls"
                 aria-describedby="delete-urls" >
              <ArgonBox mt={20} mb={20} p={1} xs={3} ml={"auto"} mr={"auto"}>
                  <Grid container justifyContent="center">
                      <Grid item xs={10} sm={10} lg={5} borderRadius={4}>
                          <Card>
                              <ArgonBox p={5}>
                                  <ArgonTypography variant="h3" textAlign={"center"}>
                                      Download new URLs
                                  </ArgonTypography>
                                  <ArgonTypography textAlign={"center"} sx={{ pt: 1 }}>
                                      Are you sure to check new urls for the following sitemap?
                                  </ArgonTypography>
                                  <ArgonBox mt={1}>
                                      <Grid container justifyContent="center">
                                          <Grid item sx={{ maxHeight: '20em', overflow: "auto"}}>
                                              {body}
                                          </Grid>
                                      </Grid>
                                  </ArgonBox>
                                  <ArgonBox mt={4}>
                                      {!buttonSaveNewUrl &&
                                          <div>
                                              <Grid container justifyContent="center">
                                                  <ArgonBox mb={5} ml={5} mr={5} sx={{ flexGrow: 1 }}>
                                                      <ArgonProgress value={valueProgressBarNewUrl} variant="gradient" label={true} sx={{height:'1em'}}/>
                                                  </ArgonBox>
                                              </Grid>
                                          </div>
                                      }
                                      <Grid container spacing={1} justifyContent="center">
                                          <Grid item >
                                              {buttonSaveNewUrl ? (<ArgonButton color="error" variant="gradient" onClick={handleCloseModal}>Cancel</ArgonButton>)
                                                  : (<ArgonButton color="error" variant="gradient" disabled>Cancel</ArgonButton>)}
                                          </Grid>
                                          <Grid item >
                                              {buttonSaveNewUrl ? (<ArgonButton color="success" variant="contained" onClick={handleActionAddUrl}>Yes, Download!</ArgonButton>)
                                                  : (<LoadingButton color="secondary" loading variant="contained">Yes, Download!</LoadingButton>)}
                                          </Grid>
                                      </Grid>
                                  </ArgonBox>
                              </ArgonBox>
                          </Card>
                      </Grid>
                  </Grid>
              </ArgonBox>
          </Modal>
      </div>
  );
}

export default AddNewUrls;
