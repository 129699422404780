import React, {useEffect, useState} from "react";

import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";

import {ExpandMore, Info, Visibility, VisibilityOff} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Grid, Card, CardHeader, InputAdornment, tooltipClasses} from "@mui/material";

// Images
import logoJira from "assets/images/small-logos/logo-jira.svg";
import ArgonInput from "../../../components/ArgonInput";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import ArgonButton from "../../../components/ArgonButton";
import {LoadingButton} from "@mui/lab";
import {connectToJira} from "../../../api/BackendApi/Account";

export function JiraTool({userIntegrations, setManagementSend, jira}) {

	const [check, setCheck] = useState(false);
	const [checkError, setCheckError] = useState(false);
	const [checkSuccess, setCheckSuccess] = useState(false);

	const [expanded, setExpanded] = useState(false);
	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {event.preventDefault();};

	const [url, setUrl] = useState("");
	const [email, setEmail] = useState("");
	const [token, setToken] = useState("");

	useEffect(() => {
		for (let integration of userIntegrations) {
			if (integration.integration === "Jira"){
				setUrl(integration.url);
				setEmail(integration.email);
				setToken(integration.token);
			}
		}
	}, []);


	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleChangeValue = (val,name) => {
		switch (name) {
			case "url":
				setUrl(val);
				break;
			case "email":
				setEmail(val);
				break;
			case "token":
				setToken(val);
				break;
			default:
				break;
		}
	}

	const handleConnectIntegration = async () => {
		try{
			setCheck(true)
			setCheckError(false)
			const response = await connectToJira(url,email,token);
			setCheck(false)
			if (response.status === 200) {
				setCheckSuccess(true)
				jira.setConnectedJira(true)
			}else{
				setCheckError(true)
				setCheckSuccess(false)
			}
		}catch (err) {
			setCheck(false)
			setCheckError(true)
			setCheckSuccess(false)

			console.log(err)
		}
	}

	const NoMaxWidthTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: 'none',
		},
	});

	return (
		<Grid item xs={6}>
			<ArgonBox mb={5} ml={5} mr={5} textAlign="center">
				<Card>
					<CardHeader
						action={
							<ExpandMore
								expand={expanded}
								onClick={handleExpandClick}
								aria-expanded={expanded}
								aria-label="show more"
							>
								<ExpandMoreIcon />
							</ExpandMore>
						}
						title={
							<Grid container justifyContent="center" alignItems="center">
								<Grid item >
									<ArgonBox>
										<img src={logoJira} style={{width: "3vw", height: "3vw"}} alt="logoJira"/>
									</ArgonBox>
								</Grid>
								<Grid item >
									<ArgonBox>
										<ArgonTypography component="label" variant="h5" fontWeight="bold">
											Jira
										</ArgonTypography>
									</ArgonBox>
								</Grid>
							</Grid>
						}
					/>
					<Collapse in={expanded} timeout="auto" unmountOnExit>
							{
								jira.connectedJira ?
									<CardContent>
										<ArgonTypography >To be Released</ArgonTypography>
									</CardContent>
									:
									<CardContent>
										<ArgonBox
											bgColor={"grey-100"}
											borderRadius="lg"
											display="flex"
											justifyContent="space-between"
											alignItems={{ xs: "flex-start", sm: "center" }}
											flexDirection={{ xs: "column", sm: "row" }}
											my={3}
											py={1}
											pl={{ xs: 1, sm: 2 }}
											pr={1}
											mt={0}
										>
											<ArgonTypography variant="button" fontWeight="medium" color="text">
												Url
											</ArgonTypography>
											<ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
												<ArgonInput
													type="url"
													size="medium"
													placeholder="https://<YOUR_HOST>/"
													value={url}
													onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : "","url")}}/>
											</ArgonBox>
										</ArgonBox>
										<ArgonBox
											bgColor={"grey-100"}
											borderRadius="lg"
											display="flex"
											justifyContent="space-between"
											alignItems={{ xs: "flex-start", sm: "center" }}
											flexDirection={{ xs: "column", sm: "row" }}
											my={3}
											py={1}
											pl={{ xs: 1, sm: 2 }}
											pr={1}
											mt={0}
										>
											<ArgonTypography variant="button" fontWeight="medium" color="text">
												email
											</ArgonTypography>
											<ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
												<ArgonInput
													type="email"
													size="medium"
													placeholder="aLhXH@example.com"
													value={email}
													onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : "","email")}}/>
											</ArgonBox>
										</ArgonBox>
										<ArgonBox
											bgColor={"grey-100"}
											borderRadius="lg"
											display="flex"
											justifyContent="space-between"
											alignItems={{ xs: "flex-start", sm: "center" }}
											flexDirection={{ xs: "column", sm: "row" }}
											my={3}
											py={1}
											pl={{ xs: 1, sm: 2 }}
											pr={1}
										>
											<ArgonTypography variant="button" fontWeight="medium" color="text">
												Token
												<NoMaxWidthTooltip title="https://confluence.atlassian.com/enterprise/using-personal-access-tokens-1026032365.html" placement="top">
													<Icon>
														<Info></Info>
													</Icon>
												</NoMaxWidthTooltip >
											</ArgonTypography>
											<ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
												<ArgonInput
													size="medium"
													type={showPassword ? 'text' : 'password'}
													placeholder="Add your Token"
													autoComplete="off"
													value={token}
													endAdornment={
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showPassword ? <VisibilityOff /> : <Visibility />}
															</IconButton>
														</InputAdornment>
													}
													onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : "","token")}}/>
											</ArgonBox>
										</ArgonBox>
										{!check ?
											<ArgonButton fullWidth variant="gradient" color="primary" sx={{ mr: 2 }} size="medium"
														 onClick={handleConnectIntegration} disabled={url==="" || email==="" ||token===""}>
												Connect
											</ArgonButton>
											:
											<LoadingButton fullWidth color="primary" loading variant="contained"  sx={{ mr: 2 }}  size="medium">Connect</LoadingButton>
										}
										{checkError &&
											<>
												{checkSuccess ?

													<></>
													:
													<ArgonTypography variant="button" fontWeight="light"  sx={{ mr: 2, color: 'red' }} color="text">
														<ErrorIcon fontSize="inherit"/> Connection Refused!
													</ArgonTypography>
												}
											</>
										}
									</CardContent>
							}
					</Collapse>
				</Card>
			</ArgonBox>
		</Grid>
	);
} 


