import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";
import ArgonBox from "components/ArgonBox";
import Header from "components/Header";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import AddIcon from '@mui/icons-material/Add';
import Card from "@mui/material/Card";
import ArgonButton from "components/ArgonButton";
import { CircularProgress } from "@mui/material";
import DataTable from "components/DataTable";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Roles } from "../../api/BackendApi/Roles";
import PermissionRole from "./components/PermissionRole";
import EditRoleName from "./components/EditRoleName";
import DeleteRole from "./components/DeleteRole";
import CreateRole from "./components/CreateRole";

function RoleManagement({ navbarRefresher }) {
  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const [roles, setRoles] = useState(null);
  const [selectedRole, setSelectedRole] = useState([]);
  const [createRoleOpen, setCreateRoleOpen] = useState(false);

  const loadRoles = async () => {
    let response = await Roles.getRoles();
    setRoles(response.data.roles);
  }

  useEffect(() => {
    loadRoles().then(r => console.log("Roles loaded"));
  }, []);

  let body = <CircularProgress></CircularProgress>

  if (roles != null) {
    body = (
      <DataTable
        entriesPerPage={false}
        enableSelection={false}
        showTotalEntries={true}
        table={{
          columns: [
            { Header: "Role", accessor: "role" },
            { Header: "Description", accessor: "description" },
            { Header: "Creation Date", accessor: "creation_date" },
            { Header: "Action", accessor: "action" },
          ],
          rows: roles.map((role) => {
            console.log(role);

            let moduleWithPermissions = {};

            for (let i = 0; i < role.permissions.length; i++) {

              let module = role.permissions[i].module;
              let module_enabled = role.permissions[i].module_enabled;
              let permission = role.permissions[i].permission;
              let description = role.permissions[i].description;
              let enabled = role.permissions[i].enabled;
              let id = role.permissions[i].permission_id;

              if (module_enabled) {
                if (!moduleWithPermissions[module]) {
                  moduleWithPermissions[module] = { module: module, permissions: [] };
                }
                moduleWithPermissions[module].permissions.push({ id: id, permission: permission, description: description, enabled: enabled });
              }
            }

            moduleWithPermissions = Object.values(moduleWithPermissions);
            console.log(moduleWithPermissions);

            var isUserAdmin = window.user.role == "SUPER ADMIN" || window.user.role === "ADMIN";
            var isUserSuperAdmin = window.user.role == "SUPER ADMIN";

            var isRoleAdmin = role.role == "SUPER ADMIN" || role.role === "ADMIN";
            var isRoleSuperAdmin = role.role == "SUPER ADMIN";

            let action = <Grid container>
              {isUserSuperAdmin && <PermissionRole navbarRefresher={navbarRefresher} permissions={moduleWithPermissions} role={role._id} load={loadRoles} />}
              {(!isRoleAdmin && isUserAdmin) && <EditRoleName role={role._id} roleName={role.role} load={loadRoles} />}
              {(!isRoleAdmin && isUserAdmin) && <DeleteRole role={role._id} roleName={role.role} load={loadRoles} />}
            </Grid>

            return {
              role: role.role,
              description: role.description,
              creation_date: role.creation_date,
              action: action
            }
          })
        }}
      />
    )
  }

  return (
    <DashboardLayout>
      <Header />
      <Dialog
        open={createRoleOpen}
        onSelectionChange={setSelectedRole}
        onClose={() => { setCreateRoleOpen(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Create Role</DialogTitle>
        <DialogContent>
          <p>TODO</p>
        </DialogContent>
      </Dialog>
      <ArgonBox mt={5} mb={3}>
        <Card sx={{ minHeight: "calc(100vh - 30vh)", display: "flex" }}>
          <Grid container p={2} direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Grid item>
              <CreateRole load={loadRoles} />
            </Grid>
            <Grid item md={12} xs={12}>
              {body}
            </Grid>
          </Grid>
        </Card>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RoleManagement;