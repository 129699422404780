import { useState } from "react";

import PropTypes from "prop-types";

import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress } from '@mui/material';
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import { Module } from "api/BackendApi/Module";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import {enqueueSnackbar} from "notistack";

function AddNewDashboard({ closeDialog }) {

  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");

  const saveNewDashboard = () => {
    setSaving(true);
    Module.addNewDashboard("Dashboard " +name)
      .then((response) => {
          enqueueSnackbar("New dashboard created!", { variant: "success" })
      })
      .catch((error) => {
          console.log(error)
          enqueueSnackbar("Error while creating new dashboard!", { variant: "error" });
      })
      .finally(() => {
        setSaving(false);
        closeDialog();
      });
  };

  return (
    <ArgonBox padding={2} paddingTop={0} width="100%" component="form" role="form">
      <ArgonTypography fontSize={14}>Dashboard Name</ArgonTypography>
      <ArgonBox>
        <ArgonInput
          placeholder="Name"
          value={name}
          size="medium"
          onChange={(e) => { setName(e.target.value) }}
        />
      </ArgonBox>
      <ArgonBox display="flex" justifyContent="center" mt={3}>
        <ArgonBox mr={1.5}>
            {name === "" ?
                <ArgonButton color="info" disabled>
                   <SaveIcon />
                    &nbsp; Save
                </ArgonButton>
                :
                <ArgonButton color="info" onClick={saveNewDashboard}>
                    {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                    &nbsp; {saving ? "Saving..." : "Save"}
                </ArgonButton>
            }
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}


AddNewDashboard.propTypes = {
  closeDialog: PropTypes.func
};

export default AddNewDashboard;
