import { useState } from "react";
import { Navigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

import useAuth from "utils/useAuth";

// @mui icons

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Footer from "components/Footer";

// Overview page components
import Header from "components/Header";

import TestTree from "pages/test-repository/components/TestTree/TestTree";
import TestDetails from "pages/test-repository/components/TestDetails";
import EmptyDetails from "pages/test-repository/components/EmptyDetails";
import FolderDetails from "pages/test-repository/components/FolderDetails";


function TestRepository() {

  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [selectedNodeName, setSelectedNodeName] = useState(null);
  const [refreshTreeHandle, setRefreshTreeHandle] = useState(1);

  var detailsContent = <EmptyDetails />;

  if (selectedNodeId != null) {
    var id = selectedNodeId.split('-')[1];
    if (selectedNodeId.includes('folder-')) {
      detailsContent = <FolderDetails folderId={id} folderName={selectedNodeName} treeRefresher={() => {
        setRefreshTreeHandle(refreshTreeHandle + 1);
      }} />;
    }
    if (selectedNodeId.includes('test-')) {
      detailsContent = <TestDetails testId={id} treeRefresher={() => {
        setRefreshTreeHandle(refreshTreeHandle + 1);
      }} />;
    }
  }

  return (
    <DashboardLayout>
      <Header />

      <ArgonBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={0}>
            <TestTree key={refreshTreeHandle} onSelect={setSelectedNodeId} onSelectName={setSelectedNodeName}></TestTree>
          </Grid>
          <Grid item xs={12} md={6} xl={0} xh={5}>
            {detailsContent}
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TestRepository;
