// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "../../../components/ArgonInput";
import {useEffect} from "react";
import {TestConfig} from "../../../api/BackendApi/TestConfiguration";


function TestArguments({
    module,
    setFeatureName,
    binaryTest,
    commandTest,
    typeTest,
    onSelect,
}) {

    useEffect(() => {

        async function loadData() {
            onSelect.setCommandTest(null);
            onSelect.setBinaryTest(null);

            const response = await TestConfig.getConfigsByName("Project");
            if (response.status === 200) {
                if(response.data.Project !== undefined) {
                    if(response.data.Project.length > 0){
                        if(typeTest === "functional" || typeTest === "ui"){
                            onSelect.setBinaryTest(response.data.Project[0].name + ".zip");
                            onSelect.setCommandTest("java -jar " + response.data.Project[0].name+ ".jar");
                        }
                    }
                }
            }

            onSelect.setDriverTest("");
            onSelect.setFeatureTest("");
            onSelect.setOtherArgsTest("");
        }
        loadData().then(r => console.log("Information loaded"));
    }, []);

    return (
        <div>
            {(typeTest === "functional" || typeTest === "ui") &&
                (<ArgonBox>
                    <ArgonBox width="80%" textAlign="center" mx="auto" mb={2}>
                        <ArgonBox mb={1}>
                            <ArgonTypography variant="h5" fontWeight="regular">
                                Arguments
                            </ArgonTypography>
                        </ArgonBox>
                        <ArgonTypography variant="body2" fontWeight="regular" color="text">
                            Set the arguments for the new tests
                        </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox component="form" role="form">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <ArgonTypography fontSize={14}>Binaries*</ArgonTypography>
                                <ArgonBox mb={2}>
                                    <ArgonInput
                                        placeholder="Eg. Project.zip"
                                        size="medium"
                                        value={binaryTest==null ? "" : binaryTest}
                                        onChange={(e) => onSelect.setBinaryTest(e.target.value!=="" ? e.target.value : null)}
                                    />
                                </ArgonBox>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <ArgonTypography fontSize={14}>Command*</ArgonTypography>
                                <ArgonBox mb={2}>
                                    <ArgonInput
                                        placeholder="Eg. java -jar Project.jar"
                                        size="medium"
                                        value={commandTest==null ? "" : commandTest}
                                        onChange={(e) => onSelect.setCommandTest(e.target.value!=="" ? e.target.value : null)}
                                    />
                                </ArgonBox>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <ArgonTypography fontSize={14}>Driver</ArgonTypography>
                                <ArgonBox mb={2}>
                                    <ArgonInput
                                        placeholder="Eg. Chrome"
                                        size="medium"
                                        onChange={(e) => onSelect.setDriverTest(e.target.value!=="" ? e.target.value : null)}
                                    />
                                </ArgonBox>
                            </Grid>
                            {setFeatureName ?
                                <Grid item xs={12} md={8}>
                                    <ArgonTypography fontSize={14}>Feature Test</ArgonTypography>
                                    <ArgonBox mb={2}>
                                        <ArgonInput
                                            placeholder="Eg. Compare"
                                            size="medium"
                                            onChange={(e) => onSelect.setFeatureTest(e.target.value!=="" ? e.target.value : null)}
                                        />
                                    </ArgonBox>
                                </Grid>
                                : <></>
                            }
                        </Grid>
                        <ArgonTypography fontSize={14}>Arguments</ArgonTypography>
                        <ArgonBox mb={2}>
                            <ArgonInput
                                placeholder="Arguments"
                                size="medium"
                                onChange={(e) => onSelect.setOtherArgsTest(e.target.value!=="" ? e.target.value : null)}
                            />
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>)
            }
            {typeTest === "accessibility" &&
                (<ArgonBox>
                    <ArgonBox width="80%" textAlign="center" mx="auto" mb={2}>
                        <ArgonBox mb={1}>
                            <ArgonTypography variant="h5" fontWeight="regular">
                                Arguments
                            </ArgonTypography>
                        </ArgonBox>
                        <ArgonTypography variant="body2" fontWeight="regular" color="text">
                            Set the arguments for the new tests
                        </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox component="form" role="form">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <ArgonTypography fontSize={14}>Binaries*</ArgonTypography>
                                <ArgonBox mb={2}>
                                    <ArgonInput
                                        placeholder="Eg. Project.zip"
                                        size="medium"
                                        value={binaryTest==null ? "" : binaryTest}
                                        onChange={(e) => onSelect.setBinaryTest(e.target.value!=="" ? e.target.value : null)}
                                    />
                                </ArgonBox>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <ArgonTypography fontSize={14}>Command*</ArgonTypography>
                                <ArgonBox mb={2}>
                                    <ArgonInput
                                        placeholder="Eg. pytest .\test.py"
                                        size="medium"
                                        value={commandTest==null ? "" : commandTest}
                                        onChange={(e) => onSelect.setCommandTest(e.target.value!=="" ? e.target.value : null)}
                                    />
                                </ArgonBox>
                            </Grid>
                        </Grid>
                    </ArgonBox>
                </ArgonBox>)
            }
        </div>
    );
}





export default TestArguments;
