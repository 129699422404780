import CheckIcon from '@mui/icons-material/Check';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import PropTypes from 'prop-types';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import ArgonTypography from 'components/ArgonTypography';
import { Box, CircularProgress, StepContent, Typography } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';


function CircularProgressWithContent(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress color='white' width={10} height={10}/>
    </Box>
  );
}


function HorizontalLinearStepper({ onFinish, onBackExit, loading, children }) {

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    if (activeStep == children.length - 1) {
      onFinish();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <ArgonBox>

      <Grid
        container

        height="0.5vh"
        sx={{ paddingTop: 0 }}
      >
        <Grid item xs align="start">
          <ArgonButton disabled={loading} variant="contained" size="medium" color="primary" iconOnly visible={activeStep < children.length} onClick={activeStep == 0 ? onBackExit : handleBack} >
            {activeStep == 0 ? <CloseIcon/> : <NavigateBeforeIcon />}
          </ArgonButton>
        </Grid>
        <Grid item xs align="center">
          <ArgonBox>
            <ArgonTypography>
              {children[activeStep]?.props.title ?? ''}
            </ArgonTypography>
          </ArgonBox>
        </Grid>
        <Grid item xs align="end">
          <ArgonButton disabled={loading} variant="contained" size="medium" color="primary" iconOnly visible={(children[activeStep]?.props.nextEnabled ?? false) && activeStep < children.length} onClick={handleNext} >
            {(activeStep < children.length - 1) ? <NavigateNextIcon /> : (loading ? < CircularProgress color={"white"} size="1rem" /> : < CheckIcon />)}
          </ArgonButton>
        </Grid>
      </Grid>

      <Stepper activeStep={activeStep}>
        {children.map((child, index) => {
          return (
            <Step key={index} active={activeStep == index}>
              <StepLabel StepIconProps={{ sx: { width: "0.8125rem !important", height: "0.8125rem !important" } }} />
            </Step>
          );
        })}
      </Stepper>
      <ArgonBox pl={3} pr={3}>{children[activeStep]}</ArgonBox>
    </ArgonBox>
  );
}

HorizontalLinearStepper.propTypes = {
  onFinish: PropTypes.func,
  children: PropTypes.array
};

export default HorizontalLinearStepper;
