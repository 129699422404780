import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsIcon from "@mui/icons-material/Settings";
import { CircularProgress } from '@mui/material';
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { TestCycles } from "api/BackendApi/TestCycles";
import { getTestInstancesInCycle, importTestsIntoCycle, deleteTestInstances, updateInstanceDefect } from "api/BackendApi/TestInstances";
import DataTable from "components/DataTable";
import moment from 'moment';

// Argon Dashboard 2 PRO MUI components
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Box from "@mui/material/Box";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArgonBadge from "components/ArgonBadge";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import { TestConfig } from "api/BackendApi/TestConfiguration";
import KeyboardArrowDownIcon from
  "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from
  "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import TestTree from 'pages/test-repository/components/TestTree/TestTree';
import { enqueueSnackbar } from "notistack";
import AvatarWithName from "components/AvatarWithName";
import ArgonInput from "components/ArgonInput";
import ElegantInput from "components/ElegantInput";


function CycleDetails({ cycleId, setShowParams, setSelectedCycle, setSelectedCycleTestInstances }) {

  const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
    width: pxToRem(34),
    minWidth: pxToRem(34),
    height: pxToRem(34),
    minHeight: pxToRem(34),
    mr: 1
  });

  const navigate = useNavigate();

  const [testSelectionTreeOpen, setTestSelectionTreeOpen] = useState(false);
  const [selectedTests, setSelectedTests] = useState(null);

  const [selectedTestInstances, setSelectedTestInstances] = useState([]);
  const [isDeletingTestInstances, setIsDeletingTestInstances] = useState(false);
  const [selectedCycleName, setSelectedCycleName] = useState(null);
  const [cycleInfo, setCycleInfo] = useState(null);

  const [ready, setReady] = useState(false);
  const [testInstances, setTestInstances] = useState([]);

  const [configOpen, setConfigOpen] = useState(false);

  const [configOptions, setConfigOptions] = useState(null);

  const handleClose = () => {
    setTestSelectionTreeOpen(false);
    setSelectedTests(null);
  };

  const handleShowParameters = () => {
    setShowParams(true);
  };

  const handleImport = async () => {
    setTestSelectionTreeOpen(false);
    // Show a loading
    try {
      await importTestsIntoCycle(cycleId, selectedTests);
      refreshCycleInfo();
      enqueueSnackbar("Tests imported successfully", { variant: "success" })
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to import tests", { variant: "error" });
    } finally {
      setSelectedTests(null);
    }
  };

  const deleteSelectedTestInstances = async () => {
    let ids = selectedTestInstances.map(i => i.original.instance._id);

    setIsDeletingTestInstances(true)
    try {
      await deleteTestInstances(ids)
      enqueueSnackbar("Test instances deleted successfully", { variant: "success" });
    } finally {
      await refreshCycleInfo();
      setSelectedTestInstances([]);
      setIsDeletingTestInstances(false);
    }
  }

  const updateDefect = async (instance, defect) => {
    try {
      if (instance.defect == defect) return;
      if (instance.defect == null && defect == "") return;
      await updateInstanceDefect(instance._id, defect);
      enqueueSnackbar("Defect updated", { variant: "success" });
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to update defect", { variant: "error" });
    }
  }

  const goToInstanceResults = () => {
    console.log(selectedTestInstances.map(r => r.original.instance))
    navigate('/test-results', { state: { "instances": selectedTestInstances.map(r => r.original.instance) } });
  }

  const runSelectedTests = () => {
    navigate('/test-schedule', { state: { "tests": selectedTestInstances.map(r => r.original.instance) } });
  };

  const onTestsSelected = (selectedTests) => {
    setSelectedTests(selectedTests);
  };

  const handleConfigurationOptionChange = async (type, event) => {
    if (!("configuration" in cycleInfo)) {
      cycleInfo["configuration"] = {};
    }

    let newInfo = JSON.parse(JSON.stringify(cycleInfo))
    if (event == null) {
      if (type in newInfo["configuration"]) {
        delete newInfo["configuration"][type];
      } else {
        // Nothing to do
        return;
      }
    } else {
      newInfo["configuration"][type] = event.value;
    }

    setCycleInfo(newInfo);
    try {
      await TestCycles.updateCycleInfo(cycleId, { "configuration": newInfo["configuration"] });
      enqueueSnackbar("Configuration updated", { variant: "success" });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Configuration update failed", { variant: "error" });
    }
  };

  const refreshCycleInfo = async () => {
    setReady(false);
    let f1 = async () => {
      try {
        let res = await TestCycles.getCycleInfo(cycleId);
        setCycleInfo(res.data.cycle);
        setSelectedCycle(res.data.cycle);
        setSelectedCycleName(res.data.cycle.name);
      } catch (err) {
        console.log(err);
        enqueueSnackbar("Failed to get cycle info", { variant: "error" });
      }
    };

    let f2 = async () => {
      try {
        let res2 = await TestConfig.getConfigs();
        setConfigOptions(res2.data.configurations);
      } catch (err) {
        console.log(err);
        enqueueSnackbar("Failed to refresh cycle info", { variant: "error" });
      };
    };

    let f3 = async () => {
      try {
        let res3 = await getTestInstancesInCycle(cycleId);
        setTestInstances(res3.data.test_instances);
        setSelectedCycleTestInstances(res3.data.test_instances);
      } catch (err) {
        console.log(err);
        enqueueSnackbar("Failed to get Test Instances info", { variant: "error" });
      }
    };

    Promise.all([f1(), f2(), f3()]).then((v) => setReady(true));
  };

  const getSelectedOptionForCycleConfiguration = (type) => {
    if (!("configuration" in cycleInfo)) {
      console.log("This cycle has no configuration option");
      return null;
    }

    if (!(type in cycleInfo["configuration"])) {
      console.log("This cycle has no " + type + " in configuration");
      return null;
    }
    var selected = cycleInfo["configuration"][type];
    return { value: selected, label: selected };
  }

  const configurationsToSelects = () => {
    if (configOptions == null) {
      console.log("No configuration options present");
      return [];
    }

    let configTypeMap = {};

    for (let option of configOptions) {
      if (!(option.type in configTypeMap)) {
        configTypeMap[option.type] = [];
      }
      configTypeMap[option.type].push(option.name);
    }

    let selects = [];

    for (const [type, names] of Object.entries(configTypeMap)) {
      selects.push(
        <Grid key={type} item xs={4}>
          <ArgonTypography fontSize={14} fontWeight={"bold"}>{type}:</ArgonTypography>
          {window.user.canConfigureTestCycle() && <ArgonSelect
            isClearable
            defaultValue={getSelectedOptionForCycleConfiguration(type)}
            placeholder={"Select " + type}
            onChange={(event) => handleConfigurationOptionChange(type, event)}
            options={names.map(n => {
              return { value: n, label: n }
            })}
          />}
        </Grid>
      );
    }
    return selects;
  };

  useEffect(() => {
    if (cycleId != null) {
      refreshCycleInfo();
    }
  }, [cycleId]);


  // Loading indicator
  let content = <Stack direction='row' justifyContent='center'>
    <CircularProgress />
  </Stack>;


  let selects = <></>;

  let statusColorMap = {
    "No Run": "info",
    "Passed": "success",
    "Failed": "error",
    "Not Completed": "attention",
    "N/A": "error"
  };

  if (ready && !isDeletingTestInstances && cycleInfo != null) {
    content = (
      <DataTable
        entriesPerPage={{ defaultValue: 25, entries: [10, 25, 50, 100] }}
        showTotalEntries={true}
        onSelectionChange={setSelectedTestInstances}
        table={{
          columns: [
            { Header: "Name", accessor: "name", maxWidth: "30vw" },
            { Header: "Status", accessor: "status", width: "3vw", align: "center" },
            { Header: "Execution Date", accessor: "execution_date", width: "4vw", align: "center" },
            { Header: "Tester", accessor: "tester", width: "3vw", align: "center" },
            { Header: "Defect", accessor: "defect", width: "5vw", },
          ],
          rows: testInstances.map((testInstance) => {
            return {
              name: testInstance.test.name,
              status: <ArgonBadge container color={statusColorMap[testInstance.status]} variant="contained" size="md" badgeContent={testInstance.status} />,
              execution_date: testInstance.execution_date ? moment(testInstance.execution_date).utcOffset(0).format("YYYY-MM-DD HH:mm:ss") : '',
              tester: <Grid container direction="row"
                justifyContent="center"
                alignItems="center"><Grid item><AvatarWithName user={testInstance.tester} /></Grid></Grid>,
              instance: testInstance,
              defect: <ElegantInput initialValue={testInstance.defect ?? ""} onBlur={(e) => { updateDefect(testInstance, e.target.value) }} />
            }
          })
        }}
      />
    )

    selects = configurationsToSelects();
  }

  return (
    <Box>
      <Card sx={{ marginBottom: "10px", overflow: "visible" }}>
        <ArgonButton onClick={() => setConfigOpen(!configOpen)}>
          <CardHeader
            title="Cycle Configuration"
            sx={{ height: "1rem", padding: "0" }}
            action={
              <IconButton
                onClick={() => setConfigOpen(!configOpen)}
                aria-label="expand"
                size="small"
              >
                {configOpen ? <KeyboardArrowUpIcon />
                  : <KeyboardArrowDownIcon />}
              </IconButton>
            }
          ></CardHeader>
        </ArgonButton>
        <div style={{
          backgroundColor: "rgba(211,211,211,0.4)", borderEndEndRadius: "15px", borderBottomLeftRadius: "15px"
        }}>
          <Collapse in={configOpen} timeout="auto" unmountOnExit>
            <Grid container p={2} spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
              {selects}
            </Grid>
          </Collapse>
        </div>
      </Card>
      <Card sx={{ height: "100%", display: "flex" }}>
        <ArgonBox p={2}>
          <Box display="flex" flexDirection="column" alignItems="start">
            <ArgonBox>
              <ArgonButton variant="contained" color="primary" size="large" iconOnly sx={buttonStyleLeft} onClick={setTestSelectionTreeOpen}>
                <AddIcon />
              </ArgonButton>
              <ArgonButton disabled={selectedTestInstances.length === 0} variant="contained" color="error" size="large" iconOnly sx={buttonStyleLeft}>
                <DeleteIcon onClick={deleteSelectedTestInstances} />
              </ArgonButton>
              <ArgonButton disabled={selectedTestInstances.length === 0} variant="contained" color="primary" size="large" iconOnly sx={buttonStyleLeft}>
                <AssignmentTurnedInIcon onClick={goToInstanceResults} />
              </ArgonButton>
              {window.user.canExecuteTestCase() && <ArgonButton disabled={selectedTestInstances.length === 0} variant="contained" color="primary" size="large" iconOnly sx={buttonStyleLeft}>
                <PlayArrowIcon onClick={runSelectedTests} />
              </ArgonButton>}
              <ArgonButton variant="contained" color="primary" size="large" iconOnly sx={buttonStyleLeft}>
                <SettingsIcon onClick={handleShowParameters} />
              </ArgonButton>
            </ArgonBox>
          </Box>
        </ArgonBox>
        <ArgonBox pl={2} pr={2}>
          {content}
        </ArgonBox>
        <Dialog
          open={testSelectionTreeOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "40vw",  // Set your width here
              },
            },
          }}
        >
          <DialogTitle>{"Import Tests"}</DialogTitle>
          <DialogContent>
            <TestTree
              onMultiSelect={onTestsSelected}
              showHeaderButtons={false}
              checkboxes={true}
              height="calc(100vh - 40vh)"
            />
          </DialogContent>
          <DialogActions>
            <ArgonButton variant="contained" color="error" size="medium" onClick={handleClose}>Cancel</ArgonButton>
            <ArgonButton variant="contained" color="primary" size="medium" onClick={handleImport} disabled={selectedTests === null || selectedTests.length == 0 || selectedTests[0] === "root"}>Import!</ArgonButton>
          </DialogActions>
        </Dialog>
      </Card>
    </Box>
  );
}

CycleDetails.propTypes = {
  cycleId: PropTypes.string,
  refreshCycleTree: PropTypes.func
};

export default CycleDetails;
