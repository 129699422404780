import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import { ChartsAPI } from "api/BackendApi/ChartsAPI";
import ArgonTypography from "components/ArgonTypography";
import ReactEcharts from "echarts-for-react";
import { useState } from "react";
import { useEffect } from "react";

function ThemeRiver({ filters, onReady }) {

  const [data, setData] = useState({ points: null });

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: 'rgba(0,0,0,0.2)',
          width: 1,
          type: 'solid'
        }
      }
    },
    color: [
      '#a1e8c7',
      '#e18383',
      '#f8e8a1'
    ],
    legend: {
      data: ['Passed', 'Failed', 'Not Completed']
    },
    singleAxis: {
      top: 50,
      bottom: 50,
      axisTick: {},
      axisLabel: {},
      type: 'time',
      axisPointer: {
        animation: true,
        label: {
          show: true
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          opacity: 0.2
        }
      }
    },
    series: [
      {
        type: 'themeRiver',
        emphasis: {
          itemStyle: {
            shadowBlur: 20,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        data: data.points
      }
    ]
  };



  useEffect(() => {
    const worker = async () => {
      const result = await ChartsAPI.getChartsRiverStatus(filters);
      setData(result.data);
      onReady();
    };

    worker();
  }, [filters]);

  if (data.points == null) {
    return <div/>;
  }

  if (data.points.length == 0) {
    return <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: '400px' }}
      >
        <ArgonTypography sx={{ textAlign: "center", alignItems: "center", flexDirection: "column" }}>No Data</ArgonTypography>
      </Stack>
    </Card>;
  }

  return <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
    <ReactEcharts option={option} style={{ height: '400px' }} />
  </Card>;
}

export default ThemeRiver;
