import React, {useEffect, useState} from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import ArgonTypography from "../../../components/ArgonTypography";
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonButton from "../../../components/ArgonButton";
import ArgonBox from "../../../components/ArgonBox";
import Table from "../../../components/Table";
import ArgonSelect from "../../../components/ArgonSelect";
import {TestConfig} from "../../../api/BackendApi/TestConfiguration";


//import custom components


function AddDashboardMonitoring({buttonDisabled, tableUrls, actionUpdateUrls, setRowUpdateUrls}) {

    const [openModalUpdateUrls, setOpenModalUpdateUrls] = useState(false);
    const [marketOptions, setMarketOptions] = useState([]);
    const [tableView, setTableView] = useState(null);
    const handleCloseModal = () => setOpenModalUpdateUrls(false);
    const handleOpenModal = () => {
        setOpenModalUpdateUrls(true);
    }

    const addOptions = [
        { value: 'yes', label: 'Yes'},
        { value: 'no', label: 'No'},
    ];

    useEffect(() => {
        async function fetchMarketData() {
            const response = await TestConfig.getConfigsByName("Market");
            if(response.status === 200){
                const markets = [];
                response.data.Market.forEach(market => {
                    markets.push({value: market.name, label: market.name});
                });
                setMarketOptions(markets);
            }
        }
        fetchMarketData().then(r => console.log("Market loaded"));
    }, []);

    const loadingTable = () => {
        let dataTable = [];
        let columns = [];
        for (let i in tableUrls.getSelectedRowModel().rows) {
            let optionSelected = addOptions[1];

            if(tableUrls.getSelectedRowModel().rows[i].original.monitoring==='Yes')
                optionSelected = addOptions[0];

            let marketSelected = [];

            if(marketOptions.length !== 0) {
                for (let l in marketOptions) {
                    if(marketOptions[l].value === tableUrls.getSelectedRowModel().rows[i].original.market){
                        marketSelected = marketOptions[l].value
                        break;
                    }
                }
                columns = [
                    { name: "url", align: "center" },
                    { name: "monitoring", align: "center" },
                    { name: "market", align: "center" },
                ]
                dataTable.push({
                    url: tableUrls.getSelectedRowModel().rows[i].id,
                    monitoring: (
                        <ArgonSelect
                            placeholder="Select..."
                            defaultValue={optionSelected}
                            isSearchable
                            options={addOptions}
                        />
                    ),
                    market: (
                        <ArgonSelect
                            placeholder="Select market"
                            defaultValue={marketSelected}
                            isClearable
                            isSearchable
                            options={marketOptions}
                        />
                    ),
                });
            }else{
                columns = [
                    { name: "url", align: "center" },
                    { name: "monitoring", align: "center" },
                ]
                dataTable.push({
                    url: tableUrls.getSelectedRowModel().rows[i].id,
                    monitoring: (
                        <ArgonSelect
                            placeholder="Select..."
                            defaultValue={optionSelected}
                            isSearchable
                            options={addOptions}
                        />
                    ),
                });
            }
        }

        return (<Table
                columns={columns}
                rows={dataTable}
                />)
    };

    const handleActionUpdateUrls = async () => {

        setRowUpdateUrls();

        actionUpdateUrls();
        setOpenModalUpdateUrls(false);
    };

    let body = loadingTable();

  return (
      <div>
          <ArgonButton variant="gradient" color="info" disabled={buttonDisabled} onClick={handleOpenModal}>
              <Icon>dashboard</Icon>&nbsp;
              Dashboard Monitoring
          </ArgonButton>
          <Modal open={openModalUpdateUrls}
                 onClose={(event, reason) => {
                     if (reason !== 'backdropClick') {
                         handleCloseModal(event, reason);
                     }
                 }}
                 closeAfterTransition
                 slots={{ backdrop: Backdrop }}
                 slotProps={{
                     backdrop: {
                         timeout: 500,
                     },
                 }}
                 aria-labelledby="update-urls"
                 aria-describedby="update-urls" >
              <ArgonBox mt={20} p={1} xs={3}>
                  <Grid container justifyContent="center">
                      <Grid item xs={10} sm={10} lg={8} borderRadius={4}>
                          <Card>
                              <ArgonBox p={5}>
                                  <ArgonTypography variant="h3" textAlign={"center"}>
                                      Add to Dashboard Monitoring (WIP)
                                  </ArgonTypography>
                                  <ArgonBox mt={1}>
                                      <Grid container justifyContent="center">
                                          <Grid item sx={{ maxHeight: '20em', overflow: "auto"}} id={"GridTableDashboardMonitoring"}>
                                              {body}
                                          </Grid>
                                      </Grid>
                                  </ArgonBox>
                                  <ArgonBox mt={5}>
                                      <Grid container spacing={1} justifyContent="center">
                                          <Grid item >
                                              <ArgonButton color="error" variant="gradient" onClick={handleCloseModal}>Cancel</ArgonButton>
                                          </Grid>
                                          <Grid item >
                                              <ArgonButton color="success" disabled variant="contained" onClick={handleActionUpdateUrls}>TODO</ArgonButton>
                                          </Grid>
                                      </Grid>
                                  </ArgonBox>
                              </ArgonBox>
                          </Card>
                      </Grid>
                  </Grid>
              </ArgonBox>
          </Modal>
      </div>
  );
}

export default AddDashboardMonitoring;
