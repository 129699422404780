import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "utils/useAuth";
import { CardHeader, CircularProgress } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import Card from "@mui/material/Card";
import Footer from "components/Footer";
import Header from "components/Header";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import { ChartsAPI } from "api/BackendApi/ChartsAPI";
import ArgonButton from "../../components/ArgonButton";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import RowDashboard from "./components/RowDashboard";
import { Module } from "../../api/BackendApi/Module";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import AddNewDashboard from "./components/AddNewDashboard";

const loading = () => {
  return (
    <ArgonBox mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress size={64} />
    </ArgonBox>
  );
};


function DashboardConfiguration({ navbarRefresher }) {

  const isLoggedIn = useAuth().ensureLoggedIn();
  const [graphTypes, setGraphTypes] = useState(null);
  const [getDashboard, setDashboard] = useState(null);
  const [addDashboardDialogOpen, setAddDashboardDialogOpen] = useState(false);

  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const updateGraphTypes = async () => {
    let configsResponse = await ChartsAPI.getChartsConfigsAllDashboards();
    let mappedConfigs = {};
    for (let config of configsResponse.data.configs) {
      let present = config.dashboard.id in mappedConfigs;
      if (!present) {
        mappedConfigs[config.dashboard.id] = [];
      }
      mappedConfigs[config.dashboard.id].push(config)
    }
    setGraphTypes(mappedConfigs);
  }


  useEffect(() => {
    updateGraphTypes();
    loadDashboard();
  }, []);

  const handleCloseAfterAction = () => {
    navbarRefresher?.("DashboardConfiguration");
    setAddDashboardDialogOpen(false);
    loadDashboard();
    updateGraphTypes();
  };

  const handleClose = () => {
    setAddDashboardDialogOpen(false);
  };

  const loadDashboard = async () => {
    let response = await Module.getModuleByName("DashboardContainer");
    setDashboard(response.data.modules.dashboard);
  };

  const getRowDashboard = () => {
    if (getDashboard == null || graphTypes == null) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            {loading()}
          </Grid>
        </Grid>
      );
    }

    const dashboards = [];
    console.log(getDashboard.length)
    let enableDelete = getDashboard.length > 1 && window.user.canDeleteDashboard();
    for (let i = 0; i < getDashboard.length; i++) {
      if (getDashboard[i][0].enabled) {
        dashboards.push(
          <RowDashboard
            graphTypes={graphTypes[getDashboard[i][0]._id]}
            key={getDashboard[i][0]._id}
            module={getDashboard[i][0]}
            enableDelete={enableDelete}
            handleCloseAfterAction={handleCloseAfterAction}
            onRename={navbarRefresher}
            onConfigChange={updateGraphTypes}
          />
        )
      }
    }
    return dashboards;
  }

  return (
    <DashboardLayout>
      <Header />
      <ArgonBox mt={5} mb={3}>
        <Card sx={{ marginBottom: "2em" }}>
          <CardHeader title="Charts Configuration"
            action={window.user.canCreateDashboard() &&
              <ArgonButton variant="gradient" color="info" sx={{ mr: 2 }} onClick={setAddDashboardDialogOpen}>
                <Icon>add</Icon>&nbsp;
                Add Dashboard
              </ArgonButton>
            }
          />
          <Dialog
            open={addDashboardDialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={"md"}
            maxWidth={"md"}
          >
            <DialogTitle textAlign="center">{"Add New Dashboard"}</DialogTitle>
            <DialogContent>
              <AddNewDashboard closeDialog={handleCloseAfterAction} />
            </DialogContent>
          </Dialog>
        </Card>
        {getRowDashboard()}
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}


export default DashboardConfiguration;
