import apiClient from "api/BackendApi";

const Roles = {
    createRole: (role, description) =>
        apiClient.post("/be/roles/addRole", {
          role: role,
          description: description
        }),

    getRole: (roleName) => apiClient.get("/be/roles/" + roleName),

    getRoles: () => apiClient.get("/be/roles"),

    editPermissionRole: (permission, role, enabled) =>
        apiClient.post("/be/roles/editPermissionRole", {
            permission: permission,
            role: role,
            enabled: enabled
        }),

    editNameRole: (name, role) =>
        apiClient.post("/be/roles/editNameRole", {
            name: name,
            role: role
        }),

    deleteRole: (role) =>
        apiClient.post("/be/roles/deleteRole", {
            role: role
        }),
}

export {
    Roles
};
