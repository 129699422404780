// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonInput from "components/ArgonInput";
import { useState } from "react";
import { Stack } from "@mui/material";

function ElegantInput({ initialValue, onBlur }) {

  const [selected, setSelected] = useState(false);
  const [value, setValue] = useState(initialValue);

  const onBlurInternal = (e) => {
    setSelected(false);
    onBlur(e);
  };

  return <>
    {!selected && <Stack
      direction="row"
      justifyContent="start"
      alignItems="center"
      onClick={() => setSelected(true)}
      sx={{ width: "100%", minWidth: "50px", minHeight: "40px", wordBreak: "break-all"}}
    >{value}</Stack>}
    {selected && <ArgonInput
      multiline
      size="small"
      autoFocus={selected}
      defaultValue={value}
      onBlur={onBlurInternal}
      onChange={(e) => setValue(e.target.value)}
    />}
  </>;
}

// Setting default values for the props of PieChart
ElegantInput.defaultProps = {
  value: ""
};

// Typechecking props for the PieChart
ElegantInput.propTypes = {
  value: PropTypes.string
};

export default ElegantInput;
