import apiClient from "api/BackendApi";

const urlsWebsiteBySitemap = (sitemap) => apiClient.post("/be/urls-website/allUrlBySitemaps", {"sitemap": sitemap});

const deleteUrlByNameUrl = (infoDeleteUrl) => apiClient.post("/be/urls-website/deleteUrl", {"url": infoDeleteUrl});

const addUrlsBySitemapName = (infoSitemap) => apiClient.post("/be/urls-website/addUrlsBySitemap", {"sitemap": infoSitemap});

export {
  urlsWebsiteBySitemap,
  deleteUrlByNameUrl,
  addUrlsBySitemapName
};
