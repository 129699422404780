import {useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";

// @mui material components
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {TreeItem, TreeView} from "@mui/x-tree-view";
import FolderIcon from "@mui/icons-material/Folder";
import {CircularProgress} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "../../../components/ArgonInput";
import ArgonButton from "../../../components/ArgonButton";

// backend api
import {addFolder, testRepository} from "../../../api/BackendApi/TestRepository";

function FolderTree({
   onSelect,
   showHeaderButtons = true,
}) {
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [selectedFolderId, setSelectedFolderId] = useState(null);
    const [repository, setRepository] = useState(null);
    const [treeTextFilter, setTreeTextFilter] = useState("");

    const [addFolderDialogOpen, setAddFolderDialogOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [name, setName] = useState("");

    const buttonStyleRight = ({ functions: { pxToRem } }) => ({
        width: pxToRem(34),
        minWidth: pxToRem(34),
        height: pxToRem(34),
        minHeight: pxToRem(34),
        ml: 1
    });

    useEffect(() => {
        refreshTree();
        onSelect(null)
    }, []);

    const refreshTree = () => {
        setRepository(null);
        return testRepository()
            .then((d) => setRepository(d.data.repository))
            .catch((e) => console.log(e));
    };

    const saveNewFolder = () => {
        setSaving(true);
        addFolder(selectedFolderId, name)
            .then((response) => enqueueSnackbar('New Folder created!',{variant:'success'}))
            .catch((error) => enqueueSnackbar('Error! Folder not created.',{variant:'error'}))
            .finally(() => {
                setSaving(false);
                refreshTree();
                handleBackToSelectFolder();
            });
    };

    const handleBackToSelectFolder = () => {
        setAddFolderDialogOpen(false);
        setName("");
    };

    const getTreeItemsFromData = (currentId, children = []) => {
        if (children === null) {
            return [];
        }

        let itemChildren = undefined;

        for (let child of children) {
            if(child.type!=="test"){
                if (child.parent === currentId) {
                    if (itemChildren === undefined) {
                        itemChildren = [];
                    }
                    itemChildren = itemChildren.concat(getTreeItemsFromData(child._id, children)).filter(e => e !== null);
                }
            }
        }

        if (currentId === "root") return itemChildren;

        //TODO: Bug ricerca folder figlie di folder
        let current = children.find(c => c._id === currentId);
        if (treeTextFilter.length > 0) {
            if (!current.name.toLowerCase().includes(treeTextFilter.toLowerCase())) {
                return null;
            }
        }

        return <TreeItem
            key={`${current.type}-${current._id}`}
            nodeId={`${current.type}-${current._id}`}
            label={
                <Grid container spacing="3" alignItems="center">

                    <Grid key={0} item>
                        <FolderIcon sx={() => ({ mt: 1 })} />
                    </Grid>
                    <Grid key={1} item>
                        <ArgonTypography fontSize={16}>
                            {current.name}
                        </ArgonTypography>
                    </Grid>
                </Grid>

            } >{itemChildren}</TreeItem>;
    };

    return (
        <ArgonBox>
        {!addFolderDialogOpen ?
            <div>
                <ArgonBox width="80%" textAlign="center" mx="auto" mb={2}>
                    <ArgonBox mb={1}>
                        <ArgonTypography variant="h5" fontWeight="regular">
                            Test Repository
                        </ArgonTypography>
                    </ArgonBox>
                    <ArgonTypography variant="body2" fontWeight="regular" color="text">
                        Select the destination folder for new tests in the test repository
                    </ArgonTypography>
                </ArgonBox>
                {showHeaderButtons && (
                <ArgonBox>
                    <Grid container width={"50vh"} margin={"0 auto"}>
                        <Grid item xs={12}>
                            <ArgonButton iconOnly disabled={selectedFolderId == null} variant="contained" color="primary" size="large" sx={buttonStyleRight} onClick={setAddFolderDialogOpen}>
                                <CreateNewFolderIcon />
                            </ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
                )}
                <ArgonBox p={1}>
                    <Grid container width={"50vh"} margin={"0 auto"}>
                        <Grid item xs={12}>
                            <ArgonInput
                                type="text"
                                placeholder="Search..."
                                size="medium"
                                onClick={() => { }}
                                onChange={(e) => { setTreeTextFilter(e.target.value) }}
                            />
                        </Grid>
                    </Grid>
                </ArgonBox>
                <ArgonBox mt={2} >
                    <Grid container width={"50vh"} margin={"0 auto"}>
                        <Grid item xs={12} sx={{ maxHeight: '15em', overflow: "auto"}}>
                            <TreeView
                                onNodeSelect={(e, id) => {
                                    setSelectedOptions(id);
                                    setSelectedFolderId(id.split("-")[1]);
                                    onSelect(id.split("-")[1]);
                                }}
                                selected={selectedOptions}
                                defaultExpanded={['folder-root']}
                                aria-label="file system navigator"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                sx={{margin: "0 auto", width: "40vh"}}
                            >
                                <TreeItem key={"root"} nodeId={`folder-root`} label={
                                    <Grid container spacing="3" alignItems="center">
                                        <Grid key={0} item>
                                            <FolderIcon sx={() => ({ mt: 1 })} />
                                        </Grid>
                                        <Grid key={1} item>
                                            <ArgonTypography fontSize={16} >
                                                Root
                                            </ArgonTypography>
                                        </Grid>
                                    </Grid>
                                }>
                                {getTreeItemsFromData("root", repository)}
                                </TreeItem>
                            </TreeView>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </div>
        :   (
            <ArgonBox  padding={2} component="form" role="form">
                <Grid container width={"50vh"} margin={"0 auto"}>
                    <Grid item xs={12}>
                        <ArgonTypography fontSize={14}>Folder Name</ArgonTypography>
                        <ArgonBox>
                            <ArgonInput
                                placeholder="Folder Name"
                                value={name}
                                size="medium"
                                onChange={(e) => { setName(e.target.value) }}
                            />
                        </ArgonBox>
                        <ArgonBox display="flex" justifyContent="center" mt={3}>
                            <ArgonBox mr={1.5}>
                                <ArgonButton variant="gradient" color="dark" onClick={handleBackToSelectFolder}>
                                    Back
                                </ArgonButton>
                            </ArgonBox>
                            <ArgonBox mr={1.5}>
                                <ArgonButton color="primary" onClick={saveNewFolder}>
                                    {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                                    &nbsp; {saving ? "Saving..." : "Save"}
                                </ArgonButton>
                            </ArgonBox>
                        </ArgonBox>
                    </Grid>
                </Grid>
            </ArgonBox>
            )
        }
        </ArgonBox>
    );
}

export default FolderTree;