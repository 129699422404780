import React, {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "../../../components/ArgonSelect";
import {TestConfig} from "../../../api/BackendApi/TestConfiguration";

function SelectTestType({module,onSelect}) {

    const [testTypeUrl, setTypeUrl] = useState([]);

    const [testTypeGenerative, setTypeGenerative] = useState([]);

    useEffect(() => {
        onSelect(null)
        loadTestsSelect();
    }, []);

    const loadTestsSelect = () => {
        return TestConfig.getConfigsByName("Test Type")
            .then((d) => {
                let arrURL = [];
                let arrGenerative = [];
                for(const data of d.data['Test Type']) {
                    for(const filter in data.filters){
                        if(data.filters[filter].module.name === "Sitemap Website" && data.filters[filter].enabled){
                            arrURL.push({ value: data.name.toString().toLowerCase(), label: data.name})
                        }
                        if(data.filters[filter].module.name === "Generative Test" && data.filters[filter].enabled){
                            arrGenerative.push({ value: data.name.toString().toLowerCase(), label: data.name})
                        }
                    }
                }
                setTypeUrl(arrURL)
                setTypeGenerative(arrGenerative)
            })
            .catch((e) => console.log(e));
    };

    const handleSelectTestType = (e) => {
        if(e!==null)
            onSelect(e.value)
        else
            onSelect(null)
    };

    return (
        <ArgonBox>
            <ArgonBox width="80%" textAlign="center" mx="auto" mb={2}>
                <ArgonBox mb={1}>
                    <ArgonTypography variant="h5" fontWeight="regular">
                        Test Type
                    </ArgonTypography>
                </ArgonBox>
                <ArgonTypography variant="body2" fontWeight="regular" color="text">
                    Select the type for new tests
                </ArgonTypography>
            </ArgonBox>
            <ArgonBox mt={2} >
                <Grid container width={"50vh"} margin={"0 auto"}>
                    {module === "generative" &&
                        <Grid item xs={12} mt={5} mb={15}>
                            <ArgonSelect
                                placeholder="Select test type"
                                isClearable
                                isSearchable
                                options={testTypeGenerative}
                                onChange={(e) => handleSelectTestType(e)}
                            />
                        </Grid>
                    }
                    {module === "url" &&
                        <Grid item xs={12} mt={5} mb={15}>
                            <ArgonSelect
                                placeholder="Select test type"
                                isClearable
                                isSearchable
                                options={testTypeUrl}
                                onChange={(e) => handleSelectTestType(e)}
                            />
                        </Grid>
                    }
                </Grid>
            </ArgonBox>
        </ArgonBox>
    );
}





export default SelectTestType;
