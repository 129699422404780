import React, { useState } from "react"

import ArgonButton from "components/ArgonButton";
import StarIcon from "@mui/icons-material/Star";
import Grid from "@mui/material/Grid";
import ArgonBox from "../../../components/ArgonBox";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import DataTable from "../../../components/DataTable";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import { enqueueSnackbar } from "notistack";
import { Roles } from "../../../api/BackendApi/Roles";

function PermissionRole({ navbarRefresher, permissions, role, load }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(permissions[0].module);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
    width: pxToRem(34),
    minWidth: pxToRem(34),
    height: pxToRem(34),
    minHeight: pxToRem(34),
    mr: 1
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 1500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  const changePermission = async (event, permission, permissionId, role) => {
    try {
      await Roles.editPermissionRole(permissionId, role, event.target.checked);
      await window.permissions.refreshPermissions();
      navbarRefresher();
      enqueueSnackbar("Permission '" + permission.permission + "' updated!", { variant: "success" })
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Error! Permission not updated.', { variant: 'error' })
    } finally {
      load();
    }
  };

  return (
    <div>
      {window.user.canAssignRightToRole() && <ArgonButton variant="contained" color="primary" size="small" iconOnly sx={buttonStyleLeft} onClick={handleOpen}>
        <StarIcon />
      </ArgonButton>}
      <Modal open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        aria-labelledby="edit-permission"
        aria-describedby="edit-permission" >
        <ArgonBox sx={style}>
          <Grid container justifyContent="center">
            <Grid item>
              <TabContext value={value}>
                <Box sx={{ maxWidth: { xs: 600, sm: 1500 }, border: 0, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="module-permission"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ justifyContent: "center", padding: 1 }}
                  >
                    {permissions.map((module, i) => {
                      return (<Tab key={"module-" + module.module} label={module.module} value={module.module} sx={{ justifyContent: "center", padding: 2 }} />)
                    })}
                  </TabList>
                </Box>
                {permissions.map((module, i) => {
                  return (
                    <TabPanel key={"permissions-" + module.module} value={module.module} sx={{ height: "500px", overflow: "auto" }}>
                      <DataTable
                        entriesPerPage={false}
                        enableSelection={false}
                        showTotalEntries={true}
                        table={{
                          columns: [
                            { Header: "Check", accessor: "check", width: "1vw" },
                            { Header: "Permission", accessor: "permission" },
                            { Header: "Description", accessor: "description" },
                          ],
                          rows: module.permissions.map((permission, i) => {
                            console.log(permission)
                            let switchPermission = <Switch key={permission.permission.toString().toLowerCase().replaceAll(" ", "_")} checked={permission.enabled}
                              onClick={event => changePermission(event, permission, permission.id, role)} />
                            return {
                              check: switchPermission,
                              permission: permission.permission,
                              description: permission.description,
                            }
                          })
                        }}
                      />
                    </TabPanel>)
                })}
              </TabContext>
            </Grid>
          </Grid>
          <Grid container spacing={1} mt={5} justifyContent="center">
            <ArgonButton color="dark" variant="gradient" onClick={handleClose}>Back</ArgonButton>
          </Grid>
        </ArgonBox>
      </Modal>
    </div>
  );
}

export default PermissionRole;
