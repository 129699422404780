import React, {useState} from "react";
import ReactECharts from 'echarts-for-react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import {TabContext, TabList, TabPanel} from "@mui/lab";

// Argon Dashboard 2 PRO MUI example components
import ArgonButton from "../../../components/ArgonButton";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonSelect from "../../../components/ArgonSelect";

// Overview page components
import {allHtmlbyHost} from "../../../api/BackendApi/SitemapWebsite";
import {generateGraph3,generateGraph4} from "../../../api/BackendApi/microservices/MapExplorer";
import {CircularProgress} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {enqueueSnackbar} from "notistack";

const UrlExplorer: React.FC = ({showUrl,urlsSelected,setUrlsSelected}) =>  {

    const [valueTab, setValueTab] = React.useState('1');
    const [eChart1, setEChart1] = useState();
    const [eChart2, setEChart2] = useState();
    const [dataGraph1, setDataGraph1] = useState([]);
    const [dataGraph2, setDataGraph2] = useState([]);
    const [showGraph1, setShowGraph1] = useState(true);
    const [showGraph2, setShowGraph2] = useState(true);
    const [urlGraph, setUrlGraph] = useState(urlsSelected[0].value);
    const [showLoading, setShowLoading] = React.useState(true);

    const returnToUrlWebsite = () => {
        setUrlsSelected([]);
        showUrl(true);
    };

    const handleChange = (event, newValue) => {
        handleOpen();
        setValueTab(newValue);
    };

    const handleClose = () => {
        setShowLoading(false);
    };

    const handleOpen  = () => {
        setShowLoading(true);
    };

    const handleSelectUrl = (e) => {
        console.log(e.value);
        handleOpen();
        setUrlGraph(e.value);

        switch (valueTab) {
            case '1':
                setShowGraph1(true);
                onChart1Ready(eChart1.getEchartsInstance(),e.value);
                break;
            case '2':
                setShowGraph2(true);
                onChart2Ready(eChart2.getEchartsInstance(),e.value);
                break;
            default:
                break;
        }
    };

    const optionsGrapth1 = {
        tooltip: {
            formatter: function (info) {
                return info.value
            }
        },
        legend: {
            data: dataGraph1.categories
        },
        toolbox: {
            show: true,
            feature: {
                //dataView: {readOnly: true},
                saveAsImage: {}
            }
        },
        series: [
            {
                name: 'xxx',
                layout: "force",
                type: 'graph',
                data: dataGraph1.nodes,
                links: dataGraph1.links,
                categories: dataGraph1.categories,
                roam: true,
                force: {
                    repulsion: 100, // Increase repulsion to push nodes further apart
                    gravity: 0.1, // Set gravity to 0 to minimize attraction to the center
                    friction: 0.5, // Increase friction to slow down movement
                    // Additional parameters can be adjusted as needed
                },
                label: {
                    position: 'right',
                    formatter: '{b}',
                    show: true
                },
                lineStyle: {
                    color: 'source',
                    curveness: 0.3,
                },
                emphasis: {
                    focus: 'adjacency',
                    lineStyle: {
                        width: 4
                    }
                },
                edgeSymbol: ['circle', 'arrow'],
                edgeSymbolSize: [4, 10],
            }
        ]
    }

    const optionsGrapth2 = {
        tooltip: {
            formatter: function (info) {
                return info.value
            }
        },
        legend: {
            data: dataGraph2.categories
        },
        toolbox: {
            show: true,
            feature: {
                //dataView: {readOnly: true},
                saveAsImage: {}
            }
        },
        series: [
            {
                name: 'xxx2',
                layout: "force",
                type: 'graph',
                data: dataGraph2.nodes,
                links: dataGraph2.links,
                categories: dataGraph2.categories,
                roam: true,
                force: {
                    repulsion: 100, // Increase repulsion to push nodes further apart
                    gravity: 0.1, // Set gravity to 0 to minimize attraction to the center
                    friction: 0.5, // Increase friction to slow down movement
                    // Additional parameters can be adjusted as needed
                },
                label: {
                    position: 'right',
                    formatter: '{b}',
                    show: true
                },
                lineStyle: {
                    color: 'source',
                    curveness: 0.3,
                },
                emphasis: {
                    focus: 'adjacency',
                    lineStyle: {
                        width: 4
                    }
                },
                edgeSymbol: ['circle', 'arrow'],
                edgeSymbolSize: [4, 10],
            }
        ]
    }

    function onChart1Ready(echarts,url) {
        async function fetchDataGraph1() {
            echarts.showLoading();

            const jsonGraph1Response = await generateGraph3(url);
            setDataGraph1(jsonGraph1Response.data);
            echarts.on('click', 'series', async function (params) {
                if(params.dataType !== 'node'){
                    await window.navigator.clipboard.writeText(params.value);
                }else {
                    await window.navigator.clipboard.writeText(params.name);
                }
                enqueueSnackbar(params.dataType + ' copied!', {variant: 'info'})
            });
            echarts.hideLoading();
        }
        fetchDataGraph1()
            .then(r => console.log("Graph 1 loaded"))
            .catch(
                e => {
                    console.log("Error loading graph 3")
                    echarts.hideLoading();
                    setShowGraph1(false);
                }
            ).finally(handleClose);
    }

    function onChart2Ready(echarts,url) {
        async function fetchDataGraph2() {
            echarts.showLoading();

            const jsonGraph2Response = await generateGraph4(url);
            setDataGraph2(jsonGraph2Response.data);
            echarts.on('click', 'series', async function (params) {
                if(params.dataType !== 'node'){
                    await window.navigator.clipboard.writeText(params.value);
                }else {
                    await window.navigator.clipboard.writeText(params.name);
                }
                enqueueSnackbar(params.dataType + ' copied!', {variant: 'info'})
            });
            echarts.hideLoading();
        }
        fetchDataGraph2()
            .then(r => console.log("Graph 2 loaded"))
            .catch(
                e => {
                    console.log("Error loading graph 4")
                    echarts.hideLoading();
                    setShowGraph2(false);
                }
            ).finally(handleClose);
    }

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose(event, reason);
                    }
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container mt={6}>
                <Grid item lg={12}>
                    <Grid container mb={5}>
                        <Grid item lg={3}>
                            <ArgonButton variant="gradient" color="info" onClick={returnToUrlWebsite}>
                                Back
                            </ArgonButton>
                        </Grid>
                        <Grid item lg={6}>
                            <ArgonSelect
                                options={urlsSelected}
                                isSearchable
                                defaultValue={urlsSelected[0]}
                                onChange={(e) => handleSelectUrl(e)}/>
                        </Grid>
                    </Grid>
                    <Box sx={{width: '100%', typography: 'body3' }}>
                        <TabContext value={valueTab}>
                            <Box>
                                <TabList sx={{ height: '2em' }} onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Technical Graph" value="1" />
                                    <Tab label="Details Graph" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" sx={{width: '100%', typography: 'body1' }}>
                                <Card>
                                    <CardContent sx={{height:'25em'}}>
                                        {showGraph1 ? <ReactECharts
                                                ref={(e) => { setEChart1(e); }}
                                                option={optionsGrapth1}
                                                notMerge={true}
                                                lazyUpdate={true}
                                                onChartReady={onChart1Ready}
                                                style={{ height: '30em'}}
                                                loadingOption={{
                                                    text: 'Loading...',
                                                    color: '#0581b0',
                                                    textColor: '#0581b0',
                                                    maskColor: 'rgb(255,255,255)'
                                                }}
                                            />:
                                            <ArgonTypography variant="h6" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No data available</ArgonTypography>
                                        }
                                    </CardContent>
                                </Card>
                            </TabPanel>
                            <TabPanel value="2" sx={{width: '100%', typography: 'body1' }}>
                                <Card>
                                    <CardContent sx={{height:'25em'}}>
                                        {showGraph2 ? <ReactECharts
                                                ref={(e) => { setEChart2(e); }}
                                                option={optionsGrapth2}
                                                notMerge={true}
                                                lazyUpdate={true}
                                                onChartReady={onChart2Ready}
                                                style={{ height: '30em'}}
                                                loadingOption={{
                                                    text: 'Loading...',
                                                    color: '#0581b0',
                                                    textColor: '#0581b0',
                                                    maskColor: 'rgb(255,255,255)'
                                                }}
                                            />:
                                            <ArgonTypography variant="h6" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No data available</ArgonTypography>
                                        }
                                    </CardContent>
                                </Card>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Grid>
            </Grid>
        </div>
  );
}


export default UrlExplorer;
