/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Settings page components
import FormField from "pages/user-profile/components/FormField";
import React, {useState} from "react";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";
import {UsersApi as Users} from "../../../api/BackendApi/Users";

function ChangePassword({user}) {
  const passwordRequirements = [
    "At least 8 - 16 characters",
    "Must contain at least 1 uppercase letter",
    "Must contain at least 1 lowercase letter",
    "1 number",
    "Must contain any special characters",
  ];
  const controlReq = new RegExp("^(?=.*)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-\#\$\.\!\?\@\%\&\*])(?=.*[a-zA-Z]).{8,16}$");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {event.preventDefault();};

  const handleChangePassword = () => {
    if (newPassword === confirmPassword && controlReq.test(newPassword)) {
      Users.changePassword(user._id, currentPassword, newPassword).then((response) => {
            if(response.status === 200) {
                if (response.data.change_password) {
                  enqueueSnackbar("Password changed", {variant: "success"});
                  setCurrentPassword("");
                  setNewPassword("");
                  setConfirmPassword("");
                  setShowPassword(false);
                } else {
                  enqueueSnackbar("Current password is Wrong!", {variant: "error"});
                }
            } else{
                enqueueSnackbar("Error", {variant: "error"});
            }
      }).catch((error) => {
        enqueueSnackbar(error, {variant: "error"});
      });
    } else {
      enqueueSnackbar("Passwords don't match", {variant: "error"});
    }
  };


  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <ArgonBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <ArgonTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </ArgonTypography>
      </ArgonBox>
    );
  });

  return (
    <Card id="change-password">
      <ArgonBox p={3}>
        <ArgonTypography variant="h5">Change Password</ArgonTypography>
      </ArgonBox>
      <ArgonBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              label="current password"
              placeholder="Current Password"
              value={currentPassword}
              type={showPassword ? 'text' : 'password'}
              autoComplete="off"
              required
              onChange={(e) => setCurrentPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="new password"
              placeholder="New Password"
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              autoComplete="off"
              required
              onChange={(e) => setNewPassword(e.target.value)}
              success={controlReq.test(newPassword)}
              error={!controlReq.test(newPassword) && newPassword !== ""}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="confirm new password"
              placeholder="Confirm Password"
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
              autoComplete="off"
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={newPassword !== confirmPassword}
              success={newPassword === confirmPassword && newPassword !== "" && confirmPassword !== ""}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <ArgonBox mt={6} mb={1}>
          <ArgonTypography variant="h5">Password requirements</ArgonTypography>
        </ArgonBox>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <ArgonBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </ArgonBox>
          <ArgonBox ml="auto">
            {currentPassword === "" || newPassword === "" || confirmPassword === "" || newPassword !== confirmPassword || !controlReq.test(newPassword) ?
              <ArgonButton variant="gradient" color="dark" size="small" disabled>
                Update Password
              </ArgonButton>
              :
              <ArgonButton variant="gradient" color="dark" size="small" onClick={handleChangePassword}>
                Update Password
              </ArgonButton>
            }
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default ChangePassword;
