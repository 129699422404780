import React, {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "../../../components/ArgonSelect";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {TestConfig} from "../../../api/BackendApi/TestConfiguration";

function GenerateSourceCodeFile({isTestBook,checkedGenerateSourceCode,onSelect}) {

    const [testType, setTestType] = useState([]);

    const [capabilityType, setCapabilityType] = useState([]);

    useEffect(() => {
        loadTestsSelect()
        loadCapabilitySelect()
        onSelect.setSelectedCapability(null)
        onSelect.setSelectedTypeTest(null)
        {isTestBook ? onSelect.setCheckedGenerateSourceCode(false) : onSelect.setCheckedGenerateSourceCode(true)}
        {isTestBook ? onSelect.setCheckedFeatureFile(true) : onSelect.setCheckedFeatureFile(false)}
    }, []);

    const loadTestsSelect = () => {
        return TestConfig.getConfigsByName("Test Type")
            .then((d) => {
                let arrGenerative = [];
                for(const data of d.data['Test Type']) {
                    for(const filter in data.filters){
                        if(data.filters[filter].module.name === "Generative Test" && data.filters[filter].enabled){
                            arrGenerative.push({ value: data.name.toString().toLowerCase(), label: data.name})
                        }
                    }
                }
                setTestType(arrGenerative)
            })
            .catch((e) => console.log(e));
    };

    const loadCapabilitySelect = () => {
        return TestConfig.getConfigsByName("Capability")
            .then((d) => {
                let arrGenerative = [];
                for(const data of d.data['Capability']) {
                    for(const filter in data.filters){
                        if(data.filters[filter].module.name === "Generative Test" && data.filters[filter].enabled){
                            arrGenerative.push({ value: data.name.toString().toLowerCase(), label: data.name})
                        }
                    }
                }
                setCapabilityType(arrGenerative)
            })
            .catch((e) => console.log(e));
    };

    const handleSelectTestType = (e) => {
        if(e!==null)
            onSelect.setSelectedTypeTest(e.value)
        else
            onSelect.setSelectedTypeTest(null)
    };

    const handleCapabilityType = (e) => {
        if(e!==null)
            onSelect.setSelectedCapability(e.value)
        else
            onSelect.setSelectedCapability(null)
    };

    const handleChange = (event) => {
        onSelect.setCheckedGenerateSourceCode(event.target.checked);
    };

    return (
        <ArgonBox>
            <ArgonBox width="80%" textAlign="center" mx="auto" mb={2}>
                <ArgonBox mb={1}>
                    <ArgonTypography variant="h5" fontWeight="regular">
                        Generate
                    </ArgonTypography>
                </ArgonBox>
                <ArgonTypography variant="body2" fontWeight="regular" color="text">
                    Select the mandatory information
                </ArgonTypography>
            </ArgonBox>
            <ArgonBox mt={2} height={"30vh"}>
                <Grid container width={"50vh"} margin={"0 auto"}>
                    <Grid item xs={12} mt={2} mb={2}>
                        <ArgonTypography fontSize={14}>Test Type</ArgonTypography>
                        <ArgonSelect
                            placeholder="Select test type"
                            isClearable
                            isSearchable
                            options={testType}
                            onChange={(e) => handleSelectTestType(e)}
                        />
                    </Grid>
                    <Grid item xs={12} mb={2}>
                        <ArgonTypography fontSize={14}>Capability</ArgonTypography>
                        <ArgonSelect
                            placeholder="Select test type"
                            isClearable
                            isSearchable
                            options={capabilityType}
                            onChange={(e) => handleCapabilityType(e)}
                        />
                    </Grid>
                    {isTestBook ?
                        <>
                            <Grid item xs={12} md={12}>
                                <FormControl component="fieldset" sx={{ ml: 2 }}>
                                    <FormControlLabel
                                        checked={true}
                                        control={<Checkbox />}
                                        label="Generete Feature File"
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl component="fieldset" sx={{ ml: 2 }}>
                                    <FormControlLabel
                                        checked={checkedGenerateSourceCode}
                                        control={<Checkbox />}
                                        label="Generete Source Code File (Java Class)"
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    :
                        <Grid item xs={12} md={12}>
                            <FormControl component="fieldset" sx={{ ml: 2 }}>
                                <FormControlLabel
                                    checked={checkedGenerateSourceCode}
                                    control={<Checkbox />}
                                    label="Generete Source Code File (Java Class)"
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                    }
                </Grid>
            </ArgonBox>
        </ArgonBox>
    );
}





export default GenerateSourceCodeFile;
