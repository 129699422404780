const cardActionArea = {
  styleOverrides: {
    focusHighlight: {
      opacity: 0,
      color: "blue"
    }
  },
};

export default cardActionArea;
