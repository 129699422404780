import {useState} from "react";

import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";

import {ExpandMore} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Grid, Card, CardHeader} from "@mui/material";

// Images
import logoADO from "assets/images/small-logos/logo-azure-devops.svg";

export function ADOTool() {

	const [expanded, setExpanded] = useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<Grid item xs={6}>
			<ArgonBox mb={5} ml={5} mr={5} textAlign="center">
				<Card>
					<CardHeader
						action={
							<ExpandMore
								expand={expanded}
								onClick={handleExpandClick}
								aria-expanded={expanded}
								aria-label="show more"
							>
								<ExpandMoreIcon />
							</ExpandMore>
						}
						title={
							<Grid container justifyContent="center" alignItems="center" mt={1} mb={1}>
								<Grid item >
									<ArgonBox mr={1}>
										<img src={logoADO} style={{width: "2vw", height: "2vw"}} alt="logoADO"/>
									</ArgonBox>
								</Grid>
								<Grid item >
									<ArgonBox>
										<ArgonTypography component="label" variant="h5" fontWeight="bold">
											Azure DevOps
										</ArgonTypography>
									</ArgonBox>
								</Grid>
							</Grid>
						}
					/>
					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<CardContent>
							<ArgonTypography paragraph>To be Released</ArgonTypography>
						</CardContent>
					</Collapse>
				</Card>
			</ArgonBox>
		</Grid>
	);
} 


