import React, { useEffect, useState } from "react"

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import ArgonAlert from "components/ArgonAlert";
import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress } from '@mui/material';
import { UsersApi } from "api/BackendApi/Users";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSnackbar from "components/ArgonSnackbar";
import {Roles} from "../../../api/BackendApi/Roles";
import ArgonSelect from "../../../components/ArgonSelect";
import {enqueueSnackbar} from "notistack";

function CreateUserView({ closeDialog, refreshTable }) {

  const [saving, setSaving] = useState(false);
  const [roles, setRoles] = useState({});

  const [username, setUseranme] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const saveNewUser = async () => {
    try {
      setSaving(true);
      await UsersApi.createUser(username, name, surname, email, role).then((res) => {
          if(res.status === 200) {
              if(res.data.create_user){
                  enqueueSnackbar('User created!',{variant:'success'})
              }else{
                  enqueueSnackbar('Username already in use',{variant:'error'})
              }
          }else{
              enqueueSnackbar('Error during the creation of User',{variant:'error'})
          }
      });
    } finally {
      setSaving(false);
      refreshTable();
      closeDialog();

    }
  };

  useEffect(() => {
      Roles.getRoles()
        .then((roles) => {
            let tmp = [];
            for(let i = 0; i < roles.data.roles.length; i++) {
                tmp.push({ value: roles.data.roles[i].role, label: roles.data.roles[i].role })
            }
            setRoles(tmp);
        })
  }, []);

  return (
    <ArgonBox component="form" role="form" p={4}>
      <ArgonTypography fontSize={14}>Username</ArgonTypography>
      <ArgonBox mb={2}>
        <ArgonInput
          placeholder="Username"
          value={username}
          size="medium"
          onChange={(e) => { setUseranme(e.target.value) }}
        />
      </ArgonBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ArgonTypography fontSize={14}>Name</ArgonTypography>
          <ArgonBox mb={2}>
            <ArgonInput
              placeholder="Name"
              value={name}
              size="medium"
              onChange={(e) => { setName(e.target.value) }}
            />
          </ArgonBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <ArgonTypography fontSize={14}>Surname</ArgonTypography>
          <ArgonBox mb={2}>
            <ArgonInput
              placeholder="Surname"
              value={surname}
              size="medium"
              onChange={(e) => { setSurname(e.target.value) }}
            />
          </ArgonBox>
        </Grid>
      </Grid>
      <ArgonTypography fontSize={14}>Email</ArgonTypography>
      <ArgonBox mb={2}>
        <ArgonInput
          placeholder="Email"
          value={email}
          size="medium"
          onChange={(e) => { setEmail(e.target.value) }}
        />
      </ArgonBox>
      <ArgonTypography fontSize={14}>Role</ArgonTypography>
      <ArgonBox mb={2}>
      <ArgonSelect
          placeholder="Role"
          options={roles}
          onChange={(e) => { setRole(e.value) }}
      />
      </ArgonBox>
      <ArgonBox display="flex" justifyContent="center" mt={4}>
        <ArgonBox mr={1.5}>
            {name === "" || surname === "" || email === "" || role === "" ?
                <ArgonButton color="primary" disabled>
                    <SaveIcon />&nbsp; Save
                </ArgonButton>
                :
              <ArgonButton color="primary" onClick={saveNewUser}>
                {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                &nbsp; {saving ? "Saving..." : "Save"}
              </ArgonButton>
            }
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}


CreateUserView.propTypes = {
  closeDialog: PropTypes.func,
};

export default CreateUserView;
