import React, {useState} from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Argon Dashboard 2 PRO MUI components
import ArgonButton from "../../../components/ArgonButton";
import ArgonBox from "../../../components/ArgonBox";

//import custom components
import FolderTree from "./FolderTree";
import SelectTestType from "./SelectTestType";
import TestArguments from "./TestArguments";
import {enqueueSnackbar} from "notistack";
import {addTestGenerative} from "../../../api/BackendApi/TestRepository";

function CreateNewTestCase({module, setFeatureName, buttonDisabled, table}) {

    const [openModalCreateNewTestCase, setOpenModalCreateNewTestCase] = useState(false);
    const handleCloseModal = () => setOpenModalCreateNewTestCase(false);
    const handleOpenModal = () => {
        setActiveStep(0)
        setOpenModalCreateNewTestCase(true);
    }
    const [activeStep, setActiveStep] = useState(0);
    const steps = ["Test Repository", "Type", "Arguments"];
    const isLastStep = activeStep === steps.length - 1;
    const handleNext = () => setActiveStep(activeStep + 1);
    const handleBack = () => setActiveStep(activeStep - 1);

    const [selectedFolderId, setSelectedFolderId] = useState(null);
    const [selectedTypeTest, setSelectedTypeTest] = useState(null);

    const [binaryTest, setBinaryTest] = useState(null);
    const [commandTest, setCommandTest] = useState(null);
    const [driverTest, setDriverTest] = useState(null);
    const [featureTest, setFeatureTest] = useState(null);
    const [otherArgsTest, setOtherArgsTest] = useState(null);

    const handleSendAddNewTests = () => {
        let check = true;

        for(let test of table.getSelectedRowModel().rows){

            let name = "";

            if(test.original.url)
                name = test.original.url;
            else if (test.original.name)
                name = test.original.name;
            else if (test.original.feature_name)
                name = test.original.feature_name;
            else if (test.original.test_name)
                name = test.original.test_name;

            let args = "";
            switch(selectedTypeTest){
                case "functional":
                    args = driverTest + " " + name + " " + otherArgsTest
                    break;
                case "ui":
                    args = driverTest + " " + featureTest + " -Dlink=" + name + " " + otherArgsTest
                    break;
                case "accessibility":
                    args = "--name " + name
                    break;
            }
            addTestGenerative(selectedFolderId, {
                "name": name,
                "notes": "",
                "description": "",
                "precondition": "",
                "binaries": binaryTest,
                "command": commandTest,
                "arguments": args
            }).catch((error)  => {
                check=false;
            })
        }
        if(check)
            enqueueSnackbar('New Test Created!',{variant:'success'});
        else
            enqueueSnackbar('Error!',{variant:'error'});
        handleCloseModal()
    }

    const getStepContent = (stepIndex) => {

        switch (stepIndex) {
            case 0:
                return <FolderTree onSelect={setSelectedFolderId}/>;
            case 1:
                return <SelectTestType module={module} onSelect={setSelectedTypeTest}/>;
            case 2:
                return <TestArguments module={module} setFeatureName={setFeatureName} binaryTest={binaryTest} commandTest={commandTest} typeTest={selectedTypeTest} onSelect={{setBinaryTest,setCommandTest,setDriverTest,setFeatureTest,setOtherArgsTest}}/>;
            default:
                return null;
        }
    }

    return (
      <div>
          <ArgonButton variant="gradient" color="info" disabled={buttonDisabled} onClick={handleOpenModal}>
              <Icon>add</Icon>&nbsp;
              Add to Test Repository
          </ArgonButton>
          <Modal open={openModalCreateNewTestCase}
                 onClose={handleCloseModal}
                 closeAfterTransition
                 slots={{ backdrop: Backdrop }}
                 slotProps={{
                     backdrop: {
                         timeout: 500,
                     },
                 }}
                 aria-labelledby="update-urls"
                 aria-describedby="update-urls" >
              <ArgonBox mt={20} mb={20} p={1} xs={3} ml={"auto"} mr={"auto"}>
                  <Grid container justifyContent="center">
                      <Grid item xs={10} sm={10} lg={8} borderRadius={4}>
                          <Card>
                              <ArgonBox p={2}>
                                  <Stepper activeStep={activeStep} alternativeLabel>
                                      {steps.map((label) => (
                                          <Step key={label}>
                                              <StepLabel>{label}</StepLabel>
                                          </Step>
                                      ))}
                                  </Stepper>
                                  <ArgonBox>
                                      {getStepContent(activeStep)}
                                      <ArgonBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                          {activeStep === 0 ? (
                                              <ArgonBox />
                                          ) : (
                                              <ArgonButton variant="gradient" color="light" onClick={handleBack}>
                                                  Back
                                              </ArgonButton>
                                          )}
                                          {activeStep === 0 && (
                                          <ArgonButton
                                              variant="gradient"
                                              color="dark"
                                              disabled={selectedFolderId === null}
                                              onClick={!isLastStep ? handleNext : undefined}
                                          >
                                              Next
                                          </ArgonButton>)}
                                          {activeStep === 1 && (
                                              <ArgonButton
                                                  variant="gradient"
                                                  color="dark"
                                                  disabled={selectedTypeTest === null}
                                                  onClick={!isLastStep ? handleNext : undefined}
                                              >
                                                  Next
                                              </ArgonButton>)}
                                          {activeStep === 2 && (
                                              <ArgonButton
                                                  variant="gradient"
                                                  color="success"
                                                  disabled={binaryTest === null || commandTest === null}
                                                  onClick={handleSendAddNewTests}
                                              >
                                                  Send
                                              </ArgonButton>)}
                                      </ArgonBox>
                                  </ArgonBox>
                              </ArgonBox>
                          </Card>
                      </Grid>
                  </Grid>
              </ArgonBox>
          </Modal>
      </div>
    );
}

export default CreateNewTestCase;
