import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";


import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";

import ArgonBox from "components/ArgonBox";
import Header from "components/Header";

import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import Sidenav from "pages/user-profile/components/SideNav";
import UserHeader from "pages/user-profile/components/UserHeader";
import BasicInfo from "pages/user-profile/components/BasicInfo";

import { UsersApi } from "api/BackendApi/Users";
import { CircularProgress, Stack } from "@mui/material";
import ChangePassword from "./components/ChangePassword";
import Accounts from "./components/Accounts";
import DeactivateAccount from "./components/DeactivateAccount";
import { getAllIntegration } from "../../api/BackendApi/Account";
import UploadArtifact from "./components/UploadArtifact";


function UserProfile() {
  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const [user, setUser] = useState(null);
  const [gitLab, setGitLab] = useState({ url: "", token: "", lastSaved: "Never" });
  const [gitHub, setGitHub] = useState({ url: "https://api.github.com/", token: "", org: "", project: "", lastSaved: "Never" });
  const [jira, setJira] = useState({ url: "", email: "", token: "", lastSaved: "Never" });

  useEffect(() => {
    async function loadData() {
      let response = await UsersApi.getMe();
      setUser(response.data.user);

      let getIntegration = await getAllIntegration(response.data.user);
      if (getIntegration.data.account.length > 0) {
        for (let integration of getIntegration.data.account) {
          if (integration.integration === "GitLab")
            setGitLab({ url: integration.url, token: integration.token, lastSaved: integration.saveTime });
          if (integration.integration === "GitHub")
            setGitHub({ url: integration.url, token: integration.token, org: integration.org, project: integration.project, lastSaved: integration.saveTime });
          if (integration.integration === "Jira")
            setJira({ url: integration.url, email: integration.email, token: integration.token, lastSaved: integration.saveTime });
        }
      }
    }
    loadData();
  }, []);

  const reloadUser = async () => {
    let response = await UsersApi.getMe();
    setUser(response.data.user);
  };

  let body = 
  <Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    sx={{ width: 1, height: '400px' }}
  >
    <CircularProgress size={64} />
  </Stack>;

  if (user != null) {
    body = (
      <ArgonBox mt={5} mb={3}>
        <Grid container>
          <Grid item md={3} xs={3}>
            <Card sx={{ minHeight: "calc(100vh - 80vh)", display: "flex" }}>
              <Sidenav />
            </Card>
          </Grid>
          <Grid item md={8} xs={8} ml={5} >
            <Grid container >
              <Grid item md={12} xs={12}>
                <UserHeader user={user} reloadUser={reloadUser}/>
              </Grid>
              <Grid item md={12} xs={12} mt={5}>
                <BasicInfo user={user} />
              </Grid>
              <Grid item md={12} xs={12} mt={5}>
                <ChangePassword user={user} />
              </Grid>
              <Grid item md={12} xs={12} mt={5}>
                <UploadArtifact user={user} />
              </Grid>
              <Grid item md={12} xs={12} mt={5}>
                <Accounts user={user} account={{ gitLab, gitHub, jira }} setAccount={{ setGitLab, setGitHub, setJira }} />
              </Grid>
              <Grid item md={12} xs={12} mt={5}>
                <DeactivateAccount user={user} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ArgonBox>
    );
  }

  return (
    <DashboardLayout>
      <Header />
      {body}
      <Footer />
    </DashboardLayout>
  );
}


export default UserProfile;
