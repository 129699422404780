import { useEffect, useState } from "react";
import { CircularProgress } from '@mui/material';
import PropTypes from "prop-types";
import Board, { addCard, addColumn } from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import Stack from "@mui/material/Stack";

import { ChartsAPI } from "api/BackendApi/ChartsAPI";
import ControllerCardCharts from "../../../components/Cards/ControllerCardCharts";

import CalendarChartImg from '../icons/calendar-chart-icon.png';
import CountryChartImg from '../icons/country-chart.png';
import GaugeGroupChart from '../icons/gauge-group-chart.png';
import RiverChartImg from '../icons/river-chart.png';
import SimplePieChartImg from '../icons/simple-pie-chart.png';
import { enqueueSnackbar } from "notistack";
import ArgonBox from "components/ArgonBox";

function ChartConfigsKanban({ title, configs, outputValueUpdate, onConfigChange }) {

  const iconMap = {
    "pie": SimplePieChartImg,
    "pie-group": SimplePieChartImg,
    "calendar": CalendarChartImg,
    "horizontal-bar": CountryChartImg,
    "river": RiverChartImg,
    "gauge-group": GaugeGroupChart
  };
;
  const [board, setBoard] = useState(null);

  const boardToTAFData = (boardData) => {
    // This should return a list of tests, if empty return null
    let configs = [];
    for (let column of boardData.columns) {
      configs.push(column.cards[0])
    }
    return configs;
  };

  const createKanban = async () => {

    let currentBoard = { columns: [] };
    let index = 0;
    for (let config of configs.sort((a, b) => a.order > b.order ? 1 : -1)) {
      currentBoard = addColumn(currentBoard, {
        id: index,
        title: config.name,
        cards: [config]
      });
      index++;
    }

    setBoard(currentBoard);
  }


  const onColumnDragEnd = (board, column, source, destination) => {
    setBoard(board);
    outputValueUpdate(boardToTAFData(board));
  }

  const renderColumnHeader = ({ title }, { removeColumn, renameColumn, addCard }) => {
    const card = configs.find(c => c.name == title);
    return (
      <ControllerCardCharts
          key={card._id}
          icon={<ArgonBox sx={{maxWidth: "100%", maxHeight:"100%", verticalAlign: "middle", borderRadius: 5,}} component="img" src={iconMap[card["type"]]} alt={iconMap[card["type"]]} />}
          title={card["name"]}
          description={card["description"]}
          color={"dark"}
          state={card["enabled"]}
          chartWidth={card["width"] ?? 1}
          onChange={async () => {
            await ChartsAPI.setChartConfigEnabled(card["_id"], !card["enabled"]);
            enqueueSnackbar(card["enabled"] ? "Chart disabled!" : "Chart enabled!", { variant: "success" })
            onConfigChange();
          }}
          onWidthChange={async (value) => {
            await ChartsAPI.setChartWidth(card["_id"], value["value"]);
            enqueueSnackbar("Width changed to " + value["value"], { variant: "success" })
            onConfigChange();
          }}
        />
    );
  }

  const renderCard = (card, { removeCard, dragging }) => {
    return <div/>;
  }

  useEffect(() => {
    createKanban();
  }, []);


  if (board == null || board?.columns?.length == 0) return <Stack direction='row' justifyContent='center'><CircularProgress /></Stack>;

  return (
    <Board
      initialBoard={board}
      onColumnDragEnd={onColumnDragEnd}
      renderColumnHeader={renderColumnHeader}
      renderCard={renderCard}
      disableCardDrag={true}
      disableColumnDrag={!window.user.canEditChart()}
    />
  );
}

ChartConfigsKanban.propTypes = {
  title: PropTypes.string,
  configs: PropTypes.array,
  outputValueUpdate: PropTypes.func
};

export default ChartConfigsKanban;