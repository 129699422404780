import { useEffect, useState } from "react";
import { CircularProgress } from '@mui/material';
import PropTypes from "prop-types";
import Board, { addCard, addColumn } from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from 'components/ArgonTypography';



function KanbanSummary({ title, testInstances, capabilityList, outputValueUpdate }) {

  const [updated, setUpdated] = useState(false);

  const createColTests = (maxCardId) => {
    let testsObjs = [];
    for (let testInstance of testInstances) {
      console.log(testInstance);
      testsObjs.push({
        id: maxCardId,
        testInstanceId: testInstance._id,
        title: testInstance.test.name,
      });
      maxCardId++;
    }
    return { newId: maxCardId, tests: testsObjs };
  };


  const [board, setBoard] = useState(null);


  const boardToTAFData = (boardData) => {
    // This should return a list of tests, if empty return null

    let tests = [];
    for (let column of boardData.columns) {
      if (column.capability == null) continue;
      let count = 0;
      for (let card of column.cards) {
        tests.push({
          "testName": card.title,
          "testinstanceID": card.testInstanceId,
          "capabilityId": column.capability.capabilityId,
          "capability": column.title,
          "priority": count
        });
        count++;
      }
    }

    if (tests.length == 0) {
      return null;
    }

    return tests;
  };

  const createKanban = () => {
    console.log("Creating kanban");

    let data = createColTests(0);
    let newBoard = addColumn({ columns: [], maxCardId: 0 }, {
      id: 1,
      title: "Test List",
      capability: null,
      backgroundColor: "#fff",
      cards: data.tests
    });

    for (const capability of capabilityList) {
      data = createColTests(data.newId);
      newBoard = addColumn(newBoard, {
        id: newBoard.columns.length + 1,
        title: capability.name,
        capability: capability,
        backgroundColor: "#fff",
        cards: data.tests
      });

      newBoard.maxCardId = data.newId;
    }

    setBoard(newBoard);

    if (!updated) {
      setUpdated(true);
      outputValueUpdate(title, boardToTAFData(newBoard));
    }
  }

  const isSameCard = (c1, c2) => c1.testInstanceId == c2.testInstanceId && c1.id == c2.id;

  const onCardDragEnd = (board, card, source, destination) => {
    let fromColId = source.fromColumnId;
    if (fromColId == 1) {
      // When removing a card from "Test List" add it again
      board.columns.find(c => c.id == fromColId).cards.push({
        id: board.maxCardId,
        testInstanceId: card.testInstanceId,
        title: card.title,
      });
      board.maxCardId += 1;
    } else if (destination.toColumnId == 1) {
      let column = board.columns.find(c => c.id == destination.toColumnId);
      column.cards = column.cards.filter(
        c => !isSameCard(c, card)
      );
    }

    setBoard(board);
    outputValueUpdate(title, boardToTAFData(board));
  }

  const renderColumnHeader = ({ title }, { removeColumn, renameColumn, addCard }) => {
    const c = capabilityList.find(c => c.name == title);
    return (
      <Card>
        <ArgonBox mb={0} lineHeight={1} style={{ textAlignVertical: "center", textAlign: "center", minWidth: "10vw", minHeight: "5vh" }}>

          {(c != null) ?
            <ArgonBox mt={3} mb={2} textAlign="center" lineHeight={1}>
              <ArgonBox component="img" src={c.image} alt={c.name} width="5vw" />
              <ArgonTypography>{title}</ArgonTypography>
            </ArgonBox> : <ArgonTypography mt={1}>{title}</ArgonTypography>}


        </ArgonBox>
      </Card>
    );
  }

  const renderCard = (card, { removeCard, dragging }) => {
    return (
      <Card key={card.id} sx={{ marginTop: 1, padding: 1, width: "15vw" }}>
        <ArgonBox
          display="flex"
          justifyContent="center"
          alignItems="center">
          <ArgonTypography textAlign="center" sx={{ textWrap: "pretty", wordBreak: "break-word" }}>{card.title}</ArgonTypography>
        </ArgonBox>
      </Card>
    );
  }

  useEffect(createKanban, [testInstances, capabilityList]);


  if (board == null || board?.columns?.length == 0) return <Stack direction='row' justifyContent='center'><CircularProgress /></Stack>;

  return (
    <Board
      initialBoard={board}
      onCardDragEnd={onCardDragEnd}
      renderColumnHeader={renderColumnHeader}
      renderCard={renderCard}

    />
  );
}

KanbanSummary.propTypes = {
  title: PropTypes.string,
  testInstances: PropTypes.array,
  capabilityList: PropTypes.array,
  outputValueUpdate: PropTypes.func
};

export default KanbanSummary;