/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import {useState} from "react";
import {enqueueSnackbar} from "notistack";
import {UsersApi as Users} from "../../../api/BackendApi/Users";
import {useNavigate} from "react-router-dom";

function DeactivateAccount({user}) {

  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const handleDisableAccount = () => {
    if (checked) {
      Users.deleteUser(user._id).then((response) => {
        if (response.status === 200) {
          localStorage.removeItem("jwt-token");
          sessionStorage.removeItem("jwt-token");
          window.user = null;
          window.user_permissions = null;
          window.permissions = null;
          location.reload();
        } else {
            enqueueSnackbar("Error during the disabling of Account", {variant: "error"});
        }
      })
    }
  };

  return (
    <Card id="deactivate-account">
      <ArgonBox p={3} lineHeight={1}>
        <ArgonBox mb={1}>
          <ArgonTypography variant="h5">Disable User Account</ArgonTypography>
        </ArgonBox>
        <ArgonTypography variant="button" color="text" fontWeight="regular">
          Once you disable your user account, there is no going back. Please be certain.
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox
        pb={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <ArgonBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
          <Switch  onChange={(e) => setChecked(e.target.checked)}/>
          <ArgonBox ml={2} lineHeight={0}>
            <ArgonTypography display="block" variant="button" fontWeight="medium">
              Confirm
            </ArgonTypography>
            <ArgonTypography variant="caption" color="text">
              I want to disable my user account.
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          {checked ? (
            <ArgonButton variant="contained" color="error" onClick={() => handleDisableAccount()}>
                Disable
            </ArgonButton>
          ) : (
            <ArgonButton variant="outlined" color="error" disabled>
              Disable
            </ArgonButton>
          )}
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default DeactivateAccount;
