import React, { useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import ArgonInput from "components/ArgonInput";

// Argon Dashboard 2 PRO MUI components
import { TestConfig } from "api/BackendApi/TestConfiguration";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import SaveIcon from '@mui/icons-material/Save';
import {CircularProgress, FormGroup, FormHelperText, FormLabel} from '@mui/material';
import {enqueueSnackbar} from "notistack";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function EditConfigurationTypeView({ closeDialog, modulesActive, types, selectedType }) {

    const [saving, setSaving] = useState(false);
    const [name, setName] = useState("");
    const [moduleSelected, setModuleSelected] = React.useState([]);

    const handleChange = (event) => {
        if(moduleSelected.includes(event.target.name)){
            const index = moduleSelected.indexOf(event.target.name);
            if (index > -1) {
                moduleSelected.splice(index, 1);
            }
        }else{
            moduleSelected.push(event.target.name)
        }
    };

    const editNewType = () => {
        setSaving(true);
        TestConfig.editType(selectedType,moduleSelected)
          .then((response) => enqueueSnackbar('Type ' + name + ' modified!',{variant:'success'}))
          .catch((error) => enqueueSnackbar('Error during the modification of Type',{variant:'error'}))
          .finally(() => {
            setSaving(false);
            closeDialog();
          });
    };

    function checkModule() {
        const moduleList = [];
        modulesActive.forEach(function(mod) {
            let checkbox = <Checkbox onChange={handleChange}/>;
            for(let i of types[selectedType].active_module){
                if(i[0].name === mod.name){
                    checkbox = <Checkbox disabled defaultChecked sx={{opacity: '0.5'}}/>;
                }
            }

            moduleList.push(
                <Grid item xs={6} key={mod._id}>
                    <FormControlLabel
                        control={checkbox}
                        label={mod.name}
                        name={mod.name}
                    />
                </Grid>
            )
        })
        return moduleList;
    }

    let body = (
        <FormGroup>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} pt={"1em !important"} pl={"2em !important"}>
                {checkModule()}
            </Grid>
        </FormGroup>
    );

  return (
    <ArgonBox component="form" role="form">
        <ArgonBox mt={1} sx={{ maxHeight: '30em', overflow: "auto"}}>
            <ArgonTypography fontSize={14}>Modules:</ArgonTypography>
                {body}
        </ArgonBox>
        <ArgonBox display="flex" justifyContent="center" mt={2}>
            <ArgonBox mr={1.5}>
                <ArgonButton color="info" onClick={editNewType}>
                    {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                    &nbsp; {saving ? "Saving..." : "Save"}
                </ArgonButton>
            </ArgonBox>
        </ArgonBox>
    </ArgonBox>
  );
}


EditConfigurationTypeView.propTypes = {
  closeDialog: PropTypes.func
};

export default EditConfigurationTypeView;
