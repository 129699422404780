import React, { useState } from "react";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import {CircularProgress} from '@mui/material';
import {enqueueSnackbar} from "notistack";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {UsersApi} from "../../../api/BackendApi/Users";
import DeleteIcon from "@mui/icons-material/Delete";

function DeleteUser({user, username, load}) {

    const [deleting, setDeleting] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
        width: pxToRem(34),
        minWidth: pxToRem(34),
        height: pxToRem(34),
        minHeight: pxToRem(34),
        mr: 1
    });

    const handleClose = () => {
        setDeleteDialogOpen(false);
    };

    const handleOpen = () => {
        setDeleteDialogOpen(true);
    };

    const deleteUser = () => {
        setDeleting(true);
        UsersApi.deleteUser(user)
          .then(() => enqueueSnackbar('User deleted!',{variant:'success'}))
          .catch(() => enqueueSnackbar('Error during the deletion of User',{variant:'error'}))
          .finally(() => {
              setDeleting(false);
              setDeleteDialogOpen(false);
              load();
          });
    };

  return (
      <div>
          {window.user.canToggleUser() && <ArgonButton variant="contained" color="primary" size="small" iconOnly sx={buttonStyleLeft} onClick={handleOpen}><PersonOffIcon /></ArgonButton>}
          <Dialog
              open={deleteDialogOpen}
              onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                      handleClose(event, reason);
                  }
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={"sm"}
          >
              <DialogTitle textAlign="center">Disable User {username}?</DialogTitle>
              <DialogContent>
                  <ArgonBox padding={2} paddingTop={0} width="100%" component="form" role="form">
                      <ArgonBox display="flex" justifyContent="center">
                          <ArgonBox>
                              <ArgonButton color="dark" onClick={handleClose}>
                                Back
                              </ArgonButton>
                              <ArgonButton color="error" sx={{ ml: 2 }} onClick={deleteUser}>
                                  {deleting ? <CircularProgress color="white" size={14} /> : <DeleteIcon />}
                                  &nbsp; {deleting ? "Disabling..." : "Yes, Disable"}
                              </ArgonButton>
                          </ArgonBox>
                      </ArgonBox>
                  </ArgonBox>
              </DialogContent>
          </Dialog>
      </div>
  );
}

export default DeleteUser;
