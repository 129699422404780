/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "../../../components/ArgonInput";
import Icon from "@mui/material/Icon";

function SubmitSitemapBySitemapAndHost({updateInputHostValue, updateInputSitemapValue}) {
  return (
      <ArgonBox mt={5}>
        <Grid container>
            <Grid item xs={6} sm={6}  textAlign={"center"} ml={"auto"} mr={"auto"}>
                <ArgonBox mb={1} ml={0.5} display="inline-block">
                    <ArgonTypography component="label" variant="caption" fontWeight="bold">
                        Sitemap&nbsp;&nbsp;
                    </ArgonTypography>
                </ArgonBox>
                <ArgonInput placeholder="ex. www.reply.com/de/pages/sitemap.xml" id="sitemapInput" onChange={evt => updateInputSitemapValue(evt)} startAdornment={<ArgonBox mt={0.5} mr={1}><Icon>web</Icon></ArgonBox>} />
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={6} sm={6} textAlign={"center"} ml={"auto"} mr={"auto"}>
                <ArgonBox mb={1} ml={0.5} display="inline-block">
                    <ArgonTypography component="label" variant="caption" fontWeight="bold">
                        Host&nbsp;&nbsp;
                    </ArgonTypography>
                </ArgonBox>
                <ArgonInput type="url" placeholder="ex. www.reply.com" id="hostInput" onChange={evt => updateInputHostValue(evt)} startAdornment={<ArgonBox mt={0.5} mr={1}><Icon>web</Icon></ArgonBox>} />
            </Grid>
        </Grid>
      </ArgonBox>
  );
}

export default SubmitSitemapBySitemapAndHost;
