import apiClient from "api/BackendApi";

const HUBAPI = {

  getCapabilities: () => apiClient.get("/be/hub/capabilities"),

  getExecutions: () => apiClient.get("/be/hub/executions"),

  launchTests: (tests, options) => apiClient.post("/be/hub/run_tests", {
    "tests": tests,
    "options": options
  }),

  getExecLogs: (execId) => apiClient.get("/be/hub/realtime_logs/" + execId),

  getRunLogs: (execId) => apiClient.get("/be/hub/logs/" + execId),

  stopExecution: (execId) => apiClient.get("/be/hub/executions/stop/" + execId),

  cleanQueue: (statuses) => apiClient.post("/be/hub/executions/clean_queue", {"statuses": statuses}),

  stopAll: () => apiClient.get("/be/hub/executions/stop_all")

};

export {
  HUBAPI
};
