import Card from "@mui/material/Card";
import ReactEcharts from "echarts-for-react";
import { useEffect } from "react";
import { useState } from "react";
import { ChartsAPI } from "api/BackendApi/ChartsAPI";
import ArgonTypography from "components/ArgonTypography";
import { Stack } from "@mui/material";

function DailyTestsCalendar({ filters, onReady }) {

  // { "all": { "max": 0, "points": [] }, "fail": { "max": 0, "points": [] } }
  const [data, setData] = useState(null);

  const topOption = {
    title: {
      top: 0,
      bottom: 0,
      left: 'center',
      text: 'Test Executions per Day'
    },
    legend: {
      // Try 'horizontal'
      orient: 'vertical',
      right: 10,
      top: 'center'
    },
    tooltip: {},
    visualMap: {
      min: 0,
      max: data?.all.max ?? 0,
      type: 'piecewise',
      orient: 'horizontal',
      left: 'center',
      top: 25,
      inRange: {
        color: ['#FFFFFF', '#a1e8c7'] //From smaller to bigger value ->
      }
    },
    calendar: {
      top: 80,
      left: 30,
      right: 30,
      cellSize: ['auto', 13],
      range: '2024',
      itemStyle: {
        borderWidth: 0.5
      },
      yearLabel: { show: false }
    },
    series: {
      type: 'heatmap',
      coordinateSystem: 'calendar',
      data: data?.all.points ?? []
    }
  };

  const botOption = {
    title: {
      top: 0,
      bottom: 0,
      left: 'center',
      text: 'Test Failures per Day'
    },
    legend: {
      // Try 'horizontal'
      orient: 'vertical',
      right: 10,
      top: 'center'
    },
    tooltip: {},
    visualMap: {
      min: 0,
      max: data?.fail.max ?? 0,
      type: 'piecewise',
      orient: 'horizontal',
      left: 'center',
      top: 25,
      inRange: {
        color: ['#FFFFFF', '#e18383'] //From smaller to bigger value ->
      }
    },
    calendar: {
      top: 80,
      left: 30,
      right: 30,
      cellSize: ['auto', 13],
      range: '2024',
      itemStyle: {
        borderWidth: 0.5
      },
      yearLabel: { show: false }
    },
    series: {
      type: 'heatmap',
      coordinateSystem: 'calendar',
      data: data?.fail.points ?? []
    }
  };

  useEffect(() => {
    const worker = async () => {
      let response = await ChartsAPI.getChartsHeatmapDailyExecFail(filters);
      setData(response.data);
      onReady();
    };

    worker();
  }, [filters]);

  if (data == null) {
    return <div/>;
  }
  if (data?.all?.points?.length == 0) {
    return <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: '400px' }}
      >
        <ArgonTypography sx={{ textAlign: "center", alignItems: "center", flexDirection: "column" }}>No Data</ArgonTypography>
      </Stack>
    </Card>;
  }


  return <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
    <ReactEcharts option={topOption} style={{ height: '200px' }} />
    <ReactEcharts option={botOption} style={{ height: '200px' }} />
  </Card>;
}

export default DailyTestsCalendar;
