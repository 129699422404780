import apiClient from "api/BackendApi";

const testRepository = () => apiClient.get("/be/test-repository");

const getTest = (testId) => apiClient.get("/be/test-repository/test/" + testId);

const updateTest = (testId, test) => apiClient.put("/be/test-repository/test/" + testId, test);

const addTest = (folderId, test) => apiClient.post("/be/test-repository/test/" + folderId, test);

const addTestGenerative = (folderId, test) => apiClient.post("/be/test-repository/test/" + folderId + "?update_by_name_and_folder=true", test);

const addFolder = (parentId, folderName) => apiClient.post("/be/test-repository/folder/" + parentId, {"name": folderName});

const renameFolder = (parentId, folderName) => apiClient.post("/be/test-repository/folder_rename/" + parentId, {"name": folderName})

const deleteEntity = (entityId) => apiClient.delete("/be/test-repository/entity/" + entityId)

const exportFolder = (folderId) => apiClient.get("/be/test-repository/export_csv/" + folderId)

export {
  testRepository,
  getTest,
  addTest,
  addFolder,
  renameFolder,
  updateTest,
  deleteEntity,
  exportFolder,
  addTestGenerative
};
