// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CardHeader, CircularProgress } from "@mui/material";
import CardContent from "@mui/material/CardContent";

// components
import ArgonSelect from "../../../components/ArgonSelect";
import ArgonDatePicker from "../../../components/ArgonDatePicker";

function SelectFilters({ dashboardId, fromDate, toDate, filters, onChange, onDateChange }) {

  const handleFilterChange = (name, event) => {
    onChange?.(name, event?.value);
  };

  const filterToCard = (filterName) => {
    return <Card sx={{ overflow: "visible" }}>
      <CardHeader title={filterName}>
      </CardHeader>
      <CardContent>
        <ArgonSelect
          multi
          size={"large"}
          sx={{ overflow: "hidden" }}
          placeholder={filterName}
          isClearable={true}
          onChange={(event) => handleFilterChange(filterName, event)}
          options={filters[filterName].filter(f => (f.enabled && f.filters.filter(f => f.enabled && f.module._id === dashboardId).length > 0) === true).map((f) => {
            return { value: f.name, label: f.name }
          })}
        />
      </CardContent>
    </Card>;
  };

  if (filters == null) {
    return <CircularProgress />
  }

  return (
    <Grid container direction="column">
      <Grid container spacing={3} mb={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardHeader title="Date">
            </CardHeader>
            <CardContent>
              <ArgonDatePicker
                onChange={onDateChange}
                options={{
                  mode: "range",
                  altInput: true,
                  dateFormat: 'Z',
                  altFormat: "Y-m-d H:i",
                  enableTime: true,
                  defaultDate: [fromDate, toDate], maxDate: new Date()
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {
          Object.entries(filters)
            .filter(([key, value]) => key !== "Capability")
            .filter(([key, value]) => value.find(v => (v.filters.filter(f => f.enabled && f.module._id === dashboardId).length > 0 && v.enabled) === true) != null)
            .map(([key, value]) => {
              return <Grid key={key} item xs={12} md={6} lg={3}>
                {filterToCard(key)}
              </Grid>
            })
        }
      </Grid>
    </Grid>

  );
}

export default SelectFilters;
