import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import { ChartsAPI } from "api/BackendApi/ChartsAPI";
import ArgonTypography from "components/ArgonTypography";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";

function StandardPieChart({ fromDate, toDate, filters, onReady }) {

  const [data, setData] = useState(null);

  const statusToColor = (status) => {
    return { "Failed": "#e18383", "Passed": "#a1e8c7", "Not Completed": "#f8e8a1" }[status];
  };

  const option = {
    title: {
      text: 'Test Status',
      left: 2,
      top: 2
    },
    legend: {
      orient: 'vertical',
      right: 10,
      top: 2,
      data: data?.map(group => group.name)
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    grid: {
      left: '100%',
      right: '100%',
      top: 0,
      bottom: 0
    },
    series: [
      {
        name: 'Test Status',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '30',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: data,
        animationType: 'scale',
        animationEasing: 'elasticOut',
        animationDelay: function (idx) {
          return Math.random() * 200;
        }
      }
    ]
  };

  useEffect(() => {
    const worker = async () => {
      var result = await ChartsAPI.getStandardStatusPie(fromDate, toDate, filters);

      let data = Object.entries(result.data.status_map).map(e => {
        return {
          "name": e[0],
          "value": e[1],
          "itemStyle": { color: statusToColor(e[0]) }
        };
      }).sort(function (a, b) { return a.value - b.value; });

      setData(data);
      onReady();
    };

    worker();
  }, [fromDate, toDate, filters]);

  if (data == null) {
    return <div/>;
  }

  if (data.length == 0) {
    return <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: '400px' }}
      >
        <ArgonTypography sx={{ textAlign: "center", alignItems: "center", flexDirection: "column" }}>No Data</ArgonTypography>
      </Stack>
    </Card>;
  }

  return <Card sx={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
    <ReactEcharts option={option} style={{ height: '400px' }} />
  </Card>;
}

export default StandardPieChart;