import { ContentPaste } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress, Dialog, DialogTitle, tooltipClasses } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import ArgonBox from "../../../components/ArgonBox";
import ArgonButton from "../../../components/ArgonButton";
import ArgonInput from "../../../components/ArgonInput";
import ArgonTypography from "../../../components/ArgonTypography";
import DataTable from "../../../components/DataTable";
import SaveIcon from '@mui/icons-material/Save';
import DialogContent from "@mui/material/DialogContent";
import { TestCycles } from "api/BackendApi/TestCycles";
import { enqueueSnackbar } from "notistack";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { updateInstanceParam } from "api/BackendApi/TestInstances";
import ElegantInput from "components/ElegantInput";

const buttonStyleRight = ({ functions: { pxToRem } }) => ({
  width: pxToRem(34),
  minWidth: pxToRem(34),
  height: pxToRem(34),
  minHeight: pxToRem(34),
  mr: 1
});

function EditableRow({ initialValue, onSave }) {

  const [savedValue, setSavedValue] = useState(initialValue);

  return <ElegantInput
    
    placeholder="Enter Parameter"
    initialValue={initialValue}
    onBlur={(e) => {
      var value = e.target.value;
      if (value != savedValue) {
        onSave(value);
        setSavedValue(value);
      }
    }}
  />;

}


function AddParameterDialog({ cycleId, existingParameters, closeDialog }) {

  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");

  const saveNewParameter = async () => {
    setSaving(true);
    var newNames = (existingParameters ?? []);
    newNames.push(name);
    try {
      await TestCycles.updateCycleInfo(cycleId, { params: newNames });
      enqueueSnackbar("Parameter " + name + " added!", { variant: "success" })
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to update cycle parameters!", { variant: "error" });
    } finally {
      setSaving(false);
      closeDialog();
    }
  };

  return (

    <ArgonBox padding={2} paddingTop={0} width="100%" component="form" role="form">
      <ArgonTypography fontSize={14}>Parameter Name</ArgonTypography>
      <ArgonBox>
        <ArgonInput
          placeholder="Name"
          value={name}
          size="medium"
          onChange={(e) => { setName(e.target.value) }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && name !== "") {
              saveNewParameter();
              e.preventDefault();
            } else if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      </ArgonBox>
      <ArgonBox display="flex" justifyContent="center" mt={3}>
        <ArgonBox mr={1.5}>
          {name === "" ?
            <ArgonButton color="primary" disabled>
              <SaveIcon />
              &nbsp; Save
            </ArgonButton>
            :
            <ArgonButton isabled={saving} color="primary" onClick={saveNewParameter}>
              {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
              &nbsp; {saving ? "Saving..." : "Save"}
            </ArgonButton>
          }
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}


function CopyParameterDialog({ cycleId, existingParameters, closeDialog }) {

  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");

  const saveNewParameter = async () => {
    setSaving(true);
    var newNames = (existingParameters ?? []);
    newNames.push(name);
    try {
      await TestCycles.updateCycleInfo(cycleId, { params: newNames });
      enqueueSnackbar("Parameter " + name + " added!", { variant: "success" })
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to update cycle parameters!", { variant: "error" });
    } finally {
      setSaving(false);
      closeDialog();
    }
  };

  return (
    <ArgonBox padding={2} paddingTop={0} width="100%" component="form" role="form">
      <ArgonTypography fontSize={14}>Parameter Name</ArgonTypography>
      <ArgonBox>
        <ArgonInput
          placeholder="Name"
          value={name}
          size="medium"
          onChange={(e) => { setName(e.target.value) }}
        />
      </ArgonBox>
      <ArgonBox display="flex" justifyContent="center" mt={3}>
        <ArgonBox mr={1.5}>
          {name === "" ?
            <ArgonButton color="info" disabled>
              <SaveIcon />
              &nbsp; Save
            </ArgonButton>
            :
            <ArgonButton color="info" onClick={saveNewParameter}>
              {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
              &nbsp; {saving ? "Saving..." : "Save"}
            </ArgonButton>
          }
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}


function ParametersDetails({ setShowParams, initSlectedCycle, selectedCycleTestInstances }) {

  const [selectedCycle, setSelectedCycle] = useState(initSlectedCycle);
  const [showAddParamDialog, setShowAddParamDialog] = useState(false);
  const [showCopyParamDialog, setShowCopyParamDialog] = useState(false);

  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [selectedColumnNames, setSelectedColumnNames] = useState([]);

  useEffect(() => {
  }, []);

  const deleteSelectedColumns = async () => {
    for (var param of selectedColumnNames) {
      const index = selectedCycle.params.indexOf(param.name);
      if (index > -1) { // only splice array when item is found
        selectedCycle.params.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    try {
      await TestCycles.updateCycleInfo(selectedCycle._id, { "params": selectedCycle.params });
      enqueueSnackbar("Columns deleted successfully", { variant: "success" });
    } catch (e) {
      console.log(e);
    } finally {
      refreshCycle();
    }
  };

  const updateInstanceParamValue = async (instanceId, column, value) => {
    try {
      await updateInstanceParam(instanceId, column, value);
      enqueueSnackbar("Updated parameter " + column + " to " + value, { variant: "success" })
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to update parameter", { variant: "error" });
    } finally {
      refreshCycle();
    }
  };

  const refreshCycle = async () => {
    var updatedCycle = await TestCycles.getCycleInfo(selectedCycle._id);
    setSelectedCycle(updatedCycle.data.cycle);
    console.log(updatedCycle.data.cycle)
  }

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  return (
    <Grid item xs={12}>
      <Dialog
        open={showAddParamDialog}
        onClose={() => setShowAddParamDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <DialogTitle textAlign="center">{"Add Parameter"}</DialogTitle>
        <DialogContent>
          <AddParameterDialog cycleId={selectedCycle._id} existingParameters={selectedCycle.params} closeDialog={() => {
            setShowAddParamDialog(false);
            refreshCycle();
          }} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={showCopyParamDialog}
        onClose={() => setShowCopyParamDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"md"}
        maxWidth={"md"}
      >
        <DialogTitle textAlign="center">{"Copy Parameter"}</DialogTitle>
        <DialogContent>
          <CopyParameterDialog cycleId={selectedCycle._id} existingParameters={selectedCycle.params} closeDialog={() => {
            setShowCopyParamDialog(false);
            refreshCycle();
          }} />
        </DialogContent>
      </Dialog>

      <ArgonBox>
        <Card m={0}>
          <Grid alignItems="center" container>
            <Grid item pl={1} textAlign={"center"}>
              <ArgonButton variant="gradient" color="info" onClick={() => {
                refreshCycle();
                setShowParams(false);
              }}>
                <Icon>arrow_back</Icon>&nbsp;
                Back
              </ArgonButton>
            </Grid>
            <Grid item xs={11} textAlign={"center"}>
              <ArgonBox p={2}>
                <ArgonTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                  Parameters
                </ArgonTypography>
              </ArgonBox>
            </Grid>
          </Grid>
        </Card>
      </ArgonBox>
      <ArgonBox p={2}>
        <Card>
          <Grid container>
            <DataTable
              canSearch={false}
              entriesPerPage={false}
              enableSelection={editModeEnabled}
              onSelectionChange={(rows) => {
                setSelectedColumnNames(rows.filter(v => v != undefined).map(r => r?.values));
              }}
              actions={<ArgonBox ml={"auto"}>
                {!editModeEnabled && <ArgonButton iconOnly variant="contained" color="info" size="large" sx={buttonStyleRight} onClick={() => setEditModeEnabled(true)} >
                  <NoMaxWidthTooltip title="Enable Action" placement="top">
                    <EditIcon />
                  </NoMaxWidthTooltip>
                </ArgonButton>}
                {editModeEnabled && <ArgonButton iconOnly variant="contained" color="info" size="large" sx={buttonStyleRight} onClick={() => setEditModeEnabled(false)} >
                  <NoMaxWidthTooltip title="Disable Action" placement="top">
                    <CloseIcon />
                  </NoMaxWidthTooltip>
                </ArgonButton>}
                <ArgonButton iconOnly variant="contained" color="info" size="large" sx={buttonStyleRight} onClick={setShowAddParamDialog} >
                  <NoMaxWidthTooltip title="Add Parameters" placement="top">
                    <AddIcon />
                  </NoMaxWidthTooltip>
                </ArgonButton>
                <ArgonButton iconOnly variant="contained" color="error" size="large" sx={buttonStyleRight} disabled={selectedColumnNames.length == 0} onClick={deleteSelectedColumns}>
                  <NoMaxWidthTooltip title="Delete Parameters" placement="top">
                    <DeleteIcon />
                  </NoMaxWidthTooltip>
                </ArgonButton>
                <ArgonButton iconOnly variant="contained" color="primary" size="large" sx={buttonStyleRight} disabled={true} onClick={() => setShowCopyParamDialog(true)}>
                  <NoMaxWidthTooltip title="TODO Copy Parameter in Multiple tests" placement="top">
                    <ContentPaste />
                  </NoMaxWidthTooltip>
                </ArgonButton>
              </ArgonBox>}
              table={{
                columns: [
                  { Header: "Name", accessor: "name", maxWidth: "20vw" }
                ],
                rows: selectedCycle.params?.map((param) => {
                  return {
                    "name": param,
                  }
                }) ?? []
              }}
            />
          </Grid>
        </Card>
      </ArgonBox>
      <ArgonBox p={2}>
        <Card>
          <Grid container>
            <DataTable
              canSearch={false}
              entriesPerPage={false}
              enableSelection={false}
              table={{
                columns: [
                  { Header: "Name", accessor: "name", maxWidth: "20vw" },
                  ...(selectedCycle.params?.map((p) => { return { Header: p, accessor: "name-" + p } }) ?? [])
                ],
                rows: selectedCycleTestInstances.map((testInstance) => {
                  var rowDetails = {
                    name: testInstance.test.name
                  }
                  for (var param of selectedCycle.params ?? []) {
                    let instanceId = testInstance._id;
                    let columnName = param;
                    rowDetails["name-" + param] = <EditableRow initialValue={testInstance.params?.[param] ?? ''} onSave={(val) => {
                      updateInstanceParamValue(instanceId, columnName, val);
                    }}
                    />
                  }
                  return rowDetails;
                })
              }}
            />
          </Grid>
        </Card>
      </ArgonBox>
    </Grid>
  );
}

export default ParametersDetails;