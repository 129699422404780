import React, {useEffect, useRef, useState} from "react";
import { Navigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";
import ArgonBox from "components/ArgonBox";
import Footer from "components/Footer";
import Header from "components/Header";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import {Locator} from "../../api/BackendApi/Locator";
import PageLocators from "./components/PageLocators";
import ArgonTypography from "../../components/ArgonTypography";
import Card from "@mui/material/Card";
import ArgonButton from "../../components/ArgonButton";
import {CircularProgress, Icon} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import ArgonInput from "../../components/ArgonInput";
import Modal from "@mui/material/Modal";
import {TestConfig} from "../../api/BackendApi/TestConfiguration";
import ArgonSelect from "../../components/ArgonSelect";
import {enqueueSnackbar} from "notistack";

function LocatorMonitoring() {
    const [pagesLocator, setPagesLocator] = useState(null);
    const [openCreatePage, setOpenCreatePage] = useState(false);
    const [pageTitle,setPageTitle] = useState("");
    const [page,setPage] = useState("");
    const [allProject, setAllProject] = useState([]);
    const [project,setProject] = useState("");

    const projectFiltered = useRef("");

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        bgcolor: 'background.paper',
        border: '2px',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
    };

    const isLoggedIn = useAuth().ensureLoggedIn();
    if (!isLoggedIn) {
      return <Navigate replace to="/sign-in" />;
    }

    useEffect(() => {
        async function fetchData() {
            const response = await TestConfig.getConfigsByName("Project");
            if (response.status === 200) {
                if (response.data.Project !== undefined) {
                    if (response.data.Project.length > 0) {
                        for (let i = 0; i < response.data.Project.length; i++) {
                            allProject.push({value: response.data.Project[i].name, label: response.data.Project[i].name})
                        }
                    }
                }
            }
            await loadPagesLocator();
        }
        fetchData().then(r  => console.log("Locator loaded"))
    }, []);

    const handleCreatePage  = async () => {
        const response = await Locator.createLocatorPageOnDB(pageTitle,page,project);
        if(response.status === 200){
            if(response.data.add_page !== -1){
                enqueueSnackbar('Locator Page created!',{variant:'success'});
                handleCloseCreatePageModal();
                await loadPagesLocator();
            }else {
                enqueueSnackbar('Locator Page already exists!',{variant:'warning'});
            }
        }else{
            enqueueSnackbar('Error!',{variant:'error'})
            handleCloseCreatePageModal();
        }
    };

    const handleOpenCreatePageModal = () => {
        setPageTitle("");
        setPage("");
        setProject("");
        setOpenCreatePage(true);
    }

    const handleCloseCreatePageModal = () => {
        setOpenCreatePage(false);
    }

    const handleFilterSelected = (event) => {
        if(event === null){
            projectFiltered.current = "";
        }else{
            projectFiltered.current = event.value;
        }
        loadPagesLocator();
    }

    const loadPagesLocator = async () => {

        let response = null;

        if(projectFiltered.current === ""){
            response = await Locator.getLocatorPages();
        }else{
            response = await Locator.getLocatorPagesByProject(projectFiltered.current);
        }
        if (response.status === 200) {
            if (response.data.pagesLocator !== undefined) {
                if (response.data.pagesLocator.length > 0) {
                    setPagesLocator(response.data.pagesLocator);
                }else {
                    setPagesLocator([]);
                }
            } else {
                setPagesLocator([]);
            }
        }
    };

    const getPagesLocator = () => {
        if (pagesLocator == null) {
            return (
                <ArgonBox
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "10em"}}>
                    <CircularProgress size={64} />
                </ArgonBox>
            );
        }else if(pagesLocator.length === 0){
            return (
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} lg={12} sx={{ textAlign: "center", paddingTop: "12em", paddingBottom: "15em" }}>
                  <ArgonTypography variant="h2">No Data</ArgonTypography>
                </Grid>
              </Grid>
            );
        }else {
            const pages = [];
            let isExpanded = true;
            for(let i = 0; i < pagesLocator.length; i++){
                if(i>0){
                    isExpanded = false
                }
                pages.push(<PageLocators page={pagesLocator[i]} isExpanded={isExpanded} key={pagesLocator[i]._id} loadPagesLocator={loadPagesLocator}/>)
            }
            return pages;
        }
    }

    return (
      <DashboardLayout>
        <Header />
        <Modal open={openCreatePage}
                 onClose={(event, reason) => {
                     if (reason !== 'backdropClick') {
                         handleCloseCreatePageModal(event, reason);
                     }
                 }}
                 closeAfterTransition
                 slots={{ backdrop: Backdrop }}
                 slotProps={{
                     backdrop: {
                         timeout: 500,
                     },
                 }}
                 aria-labelledby="create-locator"
                 aria-describedby="create-locator" >
          <ArgonBox sx={style}>
              <Grid container justifyContent="center">
                  <Grid item xs={12} mb={2} textAlign="center">
                      <ArgonTypography variant="h3" fontWeight="medium">
                          Create Page
                      </ArgonTypography>
                  </Grid>
                  <Grid item xs={12} mb={2}>
                      <ArgonTypography variant="h6" fontWeight="medium">
                          Page Title
                      </ArgonTypography>
                      <ArgonInput
                          placeholder="ex. Homepage"
                          startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                          onChange={(event) => setPageTitle(event.target.value)}
                      />
                  </Grid>
                  <Grid item xs={12} mb={2}>
                      <ArgonTypography variant="h6" fontWeight="medium">
                          Page
                      </ArgonTypography>
                      <ArgonInput
                          placeholder="ex. https://www.reply.com/"
                          startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                          onChange={(event) => setPage(event.target.value)}
                      />
                  </Grid>
                  <Grid item xs={12}>
                      <ArgonTypography variant="h6" fontWeight="medium">
                          Project
                      </ArgonTypography>
                      <ArgonSelect
                          placeholder="Select a Project"
                          options={allProject}
                          onChange={(event) => setProject(event.value)}
                          startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                          />
                  </Grid>
              </Grid>
              <Grid container spacing={3} mt={1} justifyContent="center">
                  <Grid item>
                      <ArgonButton color="dark" variant="gradient" onClick={handleCloseCreatePageModal}>Cancel</ArgonButton>
                  </Grid>
                  <Grid item>
                      <ArgonButton color="info" variant="gradient" onClick={handleCreatePage} disabled={pageTitle === "" || page === "" || project === ""}>Create</ArgonButton>
                  </Grid>
              </Grid>
          </ArgonBox>
        </Modal>
        <ArgonBox mt={10}>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <ArgonBox>
                        <Grid container p={1}>
                            <Grid item xs={3} >
                                {allProject.length > 1 &&
                                    <Grid container justifyContent={"start"}>
                                        <Grid item width="100%">
                                            <ArgonSelect
                                                placeholder="Filter by Project"
                                                options={allProject}
                                                isClearable
                                                onChange={(event) => handleFilterSelected(event)} />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container spacing={2} justifyContent={"end"}>
                                    <Grid item>
                                        <ArgonButton variant="gradient" color="info">
                                            <Icon>healing</Icon>&nbsp;
                                            Full Self Healing
                                        </ArgonButton>
                                    </Grid>
                                    <Grid item>
                                        <ArgonButton variant="gradient" color="info" onClick={() => handleOpenCreatePageModal()}>
                                            <Icon>add</Icon>&nbsp;
                                            Add New Page
                                        </ArgonButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ArgonBox>
                    <ArgonBox mt={3}>
                        <Card>
                            <Grid container p={1}>
                                <Grid item xs={12} textAlign="center">
                                    <ArgonTypography variant="h2">Locators</ArgonTypography>
                                </Grid>
                            </Grid>
                        </Card>
                    </ArgonBox>
                </Grid>
            </Grid>
        </ArgonBox>
        <ArgonBox mb={5}>
            <Grid container justifyContent="center">
                {getPagesLocator()}
            </Grid>
        </ArgonBox>
        <Footer />
      </DashboardLayout>
    );
}


export default LocatorMonitoring;
