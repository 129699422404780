import React, {useState} from "react";
import validator from 'validator'

// @mui material components
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import ArgonTypography from "../../../components/ArgonTypography";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

// Argon Dashboard 2 PRO MUI components
import ArgonButton from "../../../components/ArgonButton";
import ArgonBox from "../../../components/ArgonBox";


//import custom components
import SubmitSitemapByURL from "./SubmitSitemapByURL";
import SubmitSitemapBySitemapAndHost from "./SubmitSitemapBySitemapAndHost";
import {addSitemap} from "../../../api/BackendApi/SitemapWebsite";
import ArgonBadge from "../../../components/ArgonBadge";
import {LoadingButton} from "@mui/lab";
import {createRow} from "material-react-table";
import Badge from "@mui/material/Badge";
import {MRT_TableOptions} from "material-react-table";
import ArgonProgress from "../../../components/ArgonProgress";
import {enqueueSnackbar} from "notistack";

function AddNewSitemap({actionNewSitemap,valueProgressBar}) {
    const [open, setOpen] = useState(false);
    const [showByURL, setShowByURL] = useState(true)
    const [showBySitemapAndHost, setShowBySitemapAndHost] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [buttonSave, setButtonSave] = useState(true);
    const [typeInsert, setTypeInsert] = useState('url');
    const [brandURL, setBrandURL] = useState('');
    const [sitemapXML, setSitemapXML] = useState('');
    const [hostName, setHostName] = useState('');

    const handleOpen = () => {
        setOpen(true);
        setButtonDisabled(true)
        setSitemapXML("")
        setHostName("")
        setShowBySitemapAndHost(false);
        setShowByURL(true);
    }
    const handleClose = () => setOpen(false);
    const onOptionChange = e => {
        setTypeInsert(e.target.value)
        if(e.target.value === "tree" || e.target.value === "injection"){
            if(brandURL!==""){
                setBrandURL("")
                setButtonDisabled(true)
            }
            setShowBySitemapAndHost(true);
            setShowByURL(false);
        }else{
            setButtonDisabled(true)
            setSitemapXML("")
            setHostName("")
            setShowBySitemapAndHost(false);
            setShowByURL(true);
        }
    }

    const updateInputSitemapValue = (evt) => {
        setSitemapXML(evt.currentTarget.value)
        if(hostName!=="" && evt.currentTarget.value!== "")
            setButtonDisabled(false)
        else
            setButtonDisabled(true)
    }

    const updateInputHostValue = (evt) => {
        setHostName(evt.currentTarget.value)
        if(sitemapXML!=="" && evt.currentTarget.value!== "")
            setButtonDisabled(false)
        else
            setButtonDisabled(true)
    }

    const updateInputURLValue = (evt) => {
        setBrandURL(evt.currentTarget.value)
        if(evt.currentTarget.value === "")
            setButtonDisabled(true)
        else
            setButtonDisabled(false)
    }

    const saveNewSitemap = () => {
        setButtonSave(false);

        //TODO Controllo input https
        addSitemap({
            "typeSitemap": typeInsert,
            "brandURL": brandURL,
            "sitemapXML": sitemapXML,
            "hostName": hostName
        })
        .then(async (response) => {
            if (response.statusText === "OK") {
                enqueueSnackbar('New Sitemap added: ' + response.data.sitemap_added.length, {variant: 'success'})
                await new Promise((resolve) => setTimeout(resolve, 500));
                enqueueSnackbar('New Sitemap modified: ' + response.data.sitemap_modified.length, {variant: 'info'})
                await new Promise((resolve) => setTimeout(resolve, 500));
                enqueueSnackbar('New URLs added: ' + response.data.html_added.length, {variant: 'success'})
                await new Promise((resolve) => setTimeout(resolve, 500));
                enqueueSnackbar('New URLs modified: ' + response.data.html_modified.length, {variant: 'info'})
                actionNewSitemap();
            } else {
                enqueueSnackbar('Error!', {variant: 'error'})
            }
        })
        .catch((error) => {
            console.log(error)
            enqueueSnackbar('Error!',{variant:'error'})
        })
        .finally(() => {
            setButtonSave(true);
            handleClose();
        });
    };

  return (
      <div>
        <ArgonButton variant="gradient" color="info" onClick={handleOpen}>
          <Icon>add</Icon>&nbsp;
          Add
        </ArgonButton>
        <Modal open={open}
               onClose={(event, reason) => {
                   if (reason !== 'backdropClick') {
                       handleClose(event, reason);
                   }
               }}
               closeAfterTransition
               slots={{ backdrop: Backdrop }}
               slotProps={{
                 backdrop: {
                   timeout: 500,
                 },
               }}
               aria-labelledby="add-new-sitemap"
               aria-describedby="add-new-sitemap" >
          <ArgonBox mt={20} mb={20} p={1} xs={3}  sm={{width: 1200 }} ml={"auto"} mr={"auto"}>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={8} borderRadius={4}>
                <Card>
                  <ArgonBox p={5}>
                      <ArgonTypography variant="h3" textAlign={"center"}>Submit Sitemap</ArgonTypography>
                      {buttonSave &&
                      (<div>
                          <ArgonBox mt={3}>
                            <Grid container>
                              <Grid item xs={12} sm={12} textAlign={"center"} >
                                <FormControl>
                                  <ArgonTypography variant="h5" textAlign={"center"} mb={2}>Select how to insert the Sitemap</ArgonTypography >
                                    <RadioGroup row aria-labelledby="row-radio-buttons-sitemap" name="row-radio-buttons-sitemap" defaultValue="url" >
                                        <FormControlLabel value="url" control={<Radio />} onChange={onOptionChange} label="by Brand URL"  />
                                        <FormControlLabel value="tree" control={<Radio />} onChange={onOptionChange} label="by Sitemap" />
                                        <FormControlLabel value="injection" control={<Radio />} onChange={onOptionChange} label="by direct insertion" />
                                    </RadioGroup>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </ArgonBox>
                      </div>)
                      }
                    {showByURL && (<SubmitSitemapByURL updateInputURLValue={updateInputURLValue}/>)}
                    {showBySitemapAndHost && (<SubmitSitemapBySitemapAndHost updateInputHostValue={updateInputHostValue} updateInputSitemapValue={updateInputSitemapValue}/>)}
                    <ArgonBox mt={2}>
                        {!buttonSave &&
                            <div>
                                <Grid container justifyContent="center">
                                    <ArgonBox mb={5} ml={5} mr={5} sx={{ flexGrow: 1 }}>
                                        <ArgonProgress value={valueProgressBar} variant="gradient" label={true} sx={{height:'1em'}}/>
                                    </ArgonBox>
                                </Grid>
                            </div>
                        }
                      <Grid container spacing={1} justifyContent="center">
                        <Grid item >
                            {buttonSave ? (<ArgonButton color="success" variant="gradient" onClick={saveNewSitemap} disabled={buttonDisabled}>Submit</ArgonButton>)
                             : (<LoadingButton color="secondary" loading variant="contained">Submit</LoadingButton>)}
                        </Grid>
                        <Grid item >
                            {buttonSave ? (<ArgonButton color="error" variant="gradient" onClick={handleClose}>Cancel</ArgonButton>)
                                : (<ArgonButton color="error" variant="gradient" disabled>Cancel</ArgonButton>)}
                        </Grid>
                      </Grid>
                    </ArgonBox>
                  </ArgonBox>
                </Card>
              </Grid>
            </Grid>
          </ArgonBox>
        </Modal>
      </div>
  );
}

export default AddNewSitemap;
