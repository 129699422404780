import { useEffect, useState } from "react";
import CronJS from "react-js-cron-mui";
import cronstrue from 'cronstrue';

import ArgonTypography from "components/ArgonTypography";
import ArgonDatePicker from "../../../components/ArgonDatePicker";
import { Divider, Grid, Switch } from "@mui/material";
import ArgonSelect from "components/ArgonSelect";

function ScheduleStep({ title, initialValues, outputValueUpdate }) {

  const retryOptions = [1, 2, 3, 4, 5];
  const whenSendMailOptions = [
    { label: "Always", value: "ALWAYS_SEND" },
    { label: "On Failure", value: "SEND_ON_FAILURES" }
  ];
  const parallelizationOptions = [
    { label: "Parallelize All", value: "PARALLELIZE_ALL" },
    { label: "Serialize All", value: "SERIALIZE_ALL" },
    { label: "Parallelize on Capability", value: "SERIALIZE_CAPABILITIES" },
    { label: "Serialize on Capability", value: "SERIALIZE_CAPABILITY" }
  ];
  const failureBehaviourOptions = [
    { label: "Do Nothing", value: null },
    { label: "Cancel Capability", value: "STOP_CAPABILITY" },
    { label: "Cancel Session", value: "STOP_SESSION" }
  ];

  const [infiniteSchedule, setInfiniteSchedule] = useState(initialValues?.infiniteSchedule ?? true);
  const [startDate, setStartDate] = useState(initialValues?.startDate ?? new Date());
  const [endDate, setEndDate] = useState(initialValues?.endDate);
  const [enableRecurrence, setEnableRecurrence] = useState(initialValues?.enableRecurrence ?? false);
  const [retryCrashes, setRetryCrashes] = useState(initialValues?.retryCrashes ?? false);
  const [retryFailures, setRetryFailures] = useState(initialValues?.retryFailures ?? false);
  const [mailMe, setMailMe] = useState(initialValues?.mailMe ?? false);
  const [mailMailingList, setMailMailingList] = useState(initialValues?.mailMailingList ?? false);
  const [selectedRetryOption, setSelectedRetryOption] = useState(initialValues?.selectedRetryOption ?? { label: retryOptions[0] + " Retry", value: retryOptions[0] });
  const [selectedSendMailBehaviour, setSelectedSendMailBehaviour] = useState(initialValues?.selectedSendMailBehaviour ?? whenSendMailOptions[0]);
  const [selectedParallelizationOption, setSelectedParallelizationOption] = useState(initialValues?.selectedParallelizationOption ?? parallelizationOptions[0]);
  const [selectedFailureBehaviour, setSelectedFailureBehaviour] = useState(initialValues?.selectedFailureBehaviour ?? failureBehaviourOptions[0]);
  const [cron, setCron] = useState(initialValues?.cron ?? "0 9 * * *");

  const [error, onError] = useState(null);

  const lowerFirstLetter = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  const getRangeBlock = () => {
    return <Grid item xs>
      <Grid item>
        <ArgonTypography color="secondary">Range of recurrence</ArgonTypography>
      </Grid>
      <Grid container item direction={"row"} spacing={1}>
        <Grid item>
          <ArgonTypography>From</ArgonTypography>
        </Grid>
        <Grid item sx={{ width: "10vw" }}>
          <ArgonDatePicker
            options={{
              onChange: (dates) => { setStartDate(dates[0]) },
              mode: "single",
              altInput: true,
              dateFormat: 'Z',
              altFormat: "Y-m-d H:i",
              enableTime: true,
              defaultDate: [new Date()], maxDate: new Date()
            }}
          />
        </Grid>
        {!infiniteSchedule && <>
          <Grid item>
            <ArgonTypography>to</ArgonTypography>
          </Grid>
          <Grid item sx={{ width: "10vw" }}>
            <ArgonDatePicker
              options={{
                onChange: (dates) => { setEndDate(dates[0]) },
                mode: "single",
                altInput: true,
                dateFormat: 'Z',
                altFormat: "Y-m-d H:i",
                enableTime: true,
                defaultDate: [new Date()]
              }}
            />
          </Grid>
        </>}
      </Grid>
      <Grid container item direction={"row"} spacing={0}>
        <Grid item>
          <ArgonTypography sx={{ paddingTop: "0.4rem" }} variant={"body2"}>Never end schedule</ArgonTypography>
        </Grid>
        <Grid item ml={2}>
          <Switch key="never-end" checked={infiniteSchedule} onClick={(event) => { setInfiniteSchedule(!infiniteSchedule) }} />
        </Grid>
      </Grid>
    </Grid>;
  }

  const getRecurrenceBlock = () => {
    return <Grid item xs>
      <Grid item>
        <ArgonTypography color="secondary">Recurrence</ArgonTypography>
      </Grid>
      {enableRecurrence && <>
        <Grid item>
          <CronJS clearButton={false} value={cron} setValue={setCron} onError={onError} defaultPeriod={"day"} />
        </Grid>
        <Grid item>
          <ArgonTypography variant={"body2"}>Occours {lowerFirstLetter(cronstrue.toString(cron, { use24HourTimeFormat: true }))}</ArgonTypography>
        </Grid>
      </>}
      <Grid item>
        <Grid container item direction={"row"} spacing={0}>
          <Grid item>
            <ArgonTypography sx={{ paddingTop: "0.4rem" }} variant={"body2"}>Enable Recurrence</ArgonTypography>
          </Grid>
          <Grid item ml={2}>
            <Switch key="never-end" checked={enableRecurrence} onClick={(event) => { setEnableRecurrence(!enableRecurrence) }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }

  const getRetryBlock = () => {
    var options = retryOptions.map(c => { return { label: c + " Retry", value: c } });
    return <Grid item xs>
      <ArgonTypography color="secondary">Retry</ArgonTypography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid
          container
          item
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          xs
        >
          <Grid container item direction={"row"} justifyContent="space-between" spacing={0} columns={2} xs>
            <Grid item>
              <ArgonTypography sx={{ paddingTop: "0.4rem" }} variant={"body2"}>Retry crashes</ArgonTypography>
            </Grid>
            <Grid item ml={2}>
              <Switch key="never-end" checked={retryCrashes} onClick={(event) => { setRetryCrashes(!retryCrashes) }} />
            </Grid>
          </Grid>
          <Grid container item direction={"row"} justifyContent="space-between" spacing={0} columns={2} xs>
            <Grid item >
              <ArgonTypography sx={{ paddingTop: "0.4rem" }} variant={"body2"}>Retry failures</ArgonTypography>
            </Grid>
            <Grid item ml={2}>
              <Switch key="never-end" checked={retryFailures} onClick={(event) => { setRetryFailures(!retryFailures) }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <ArgonTypography variant={"subtitle2"} opacity={0.4}>Retry count</ArgonTypography>
          <ArgonSelect
            size={"small"}
            sx={{ overflow: "hidden" }}
            placeholder={"Retry Count"}
            defaultValue={selectedRetryOption}
            onChange={(event) => { setSelectedRetryOption({ label: event.label, value: event.value }) }}
            options={options}
          />
        </Grid>
      </Grid>
    </Grid>;
  }

  const getMailingBlock = () => {
    return <Grid item xs>
      <ArgonTypography color="secondary">Mailing</ArgonTypography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid
          container
          item
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          xs
        >
          <Grid container item direction={"row"} justifyContent="space-between" spacing={0} columns={2} xs>
            <Grid item>
              <ArgonTypography sx={{ paddingTop: "0.4rem" }} variant={"body2"}>Mail me</ArgonTypography>
            </Grid>
            <Grid item ml={2}>
              <Switch key="never-end" checked={mailMe} onClick={(event) => { setMailMe(!mailMe) }} />
            </Grid>
          </Grid>
          <Grid container item direction={"row"} justifyContent="space-between" spacing={0} columns={2} xs>
            <Grid item >
              <ArgonTypography sx={{ paddingTop: "0.4rem" }} variant={"body2"}>Mailing list</ArgonTypography>
            </Grid>
            <Grid item ml={2}>
              <Switch key="never-end" checked={mailMailingList} onClick={(event) => { setMailMailingList(!mailMailingList) }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <ArgonTypography variant={"subtitle2"} opacity={0.4}>When</ArgonTypography>
          <ArgonSelect
            size={"small"}
            sx={{ overflow: "hidden" }}
            placeholder={"When send mail"}
            defaultValue={selectedSendMailBehaviour}
            onChange={(event) => { setSelectedSendMailBehaviour({ label: event.label, value: event.value }) }}
            options={whenSendMailOptions}
          />
        </Grid>
      </Grid>
    </Grid>;
  }

  const getOtherSettingsBlock = () => {
    return <Grid item xs>
      <ArgonTypography color="secondary">More</ArgonTypography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs>
          <ArgonTypography variant={"subtitle2"} opacity={0.4}>Parallelization</ArgonTypography>
          <ArgonSelect
            size={"small"}
            sx={{ overflow: "hidden" }}
            placeholder={"Parallelization"}
            defaultValue={selectedParallelizationOption}
            onChange={(event) => { setSelectedParallelizationOption({ label: event.label, value: event.value }) }}
            options={parallelizationOptions}
          />
        </Grid>
        <Grid item xs>
          <ArgonTypography variant={"subtitle2"} opacity={0.4}>Failure Behaviour</ArgonTypography>
          <ArgonSelect
            size={"small"}
            sx={{ overflow: "hidden" }}
            placeholder={"Failure Behaviour"}
            defaultValue={selectedFailureBehaviour}
            onChange={(event) => { setSelectedFailureBehaviour({ label: event.label, value: event.value }) }}
            options={failureBehaviourOptions}
          />
        </Grid>
      </Grid>
    </Grid>;
  }

  const getFirstRow = () => {
    return <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      mb={4}
    >
      {getRangeBlock()}
      {getRecurrenceBlock()}
    </Grid>;
  }

  const getSecondRow = () => {
    return <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      mt={4}
    >
      {getRetryBlock()}
      <Grid item style={{ alignSelf: "stretch" }}>
        <Divider orientation="vertical" />
      </Grid>
      {getMailingBlock()}
      <Grid item style={{ alignSelf: "stretch" }}>
        <Divider orientation="vertical" />
      </Grid>
      {getOtherSettingsBlock()}
    </Grid>;
  }

  useEffect(() => {
    outputValueUpdate(title, {
      infiniteSchedule: infiniteSchedule,
      startDate: startDate,
      endDate: endDate,
      enableRecurrence: enableRecurrence,
      retryCrashes: retryCrashes,
      retryFailures: retryFailures,
      mailMe: mailMe,
      mailMailingList: mailMailingList,
      selectedRetryOption: selectedRetryOption,
      selectedSendMailBehaviour: selectedSendMailBehaviour,
      selectedParallelizationOption: selectedParallelizationOption,
      selectedFailureBehaviour: selectedFailureBehaviour,
      cron: cron,
    });
  }, [
    infiniteSchedule,
    startDate,
    endDate,
    enableRecurrence,
    retryCrashes,
    retryFailures,
    mailMe,
    mailMailingList,
    selectedRetryOption,
    selectedSendMailBehaviour,
    selectedParallelizationOption,
    selectedFailureBehaviour,
    cron
  ]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      p={2}>
      {getFirstRow()}
      <Divider flexItem />
      {getSecondRow()}
    </Grid>

  );
}

export default ScheduleStep;