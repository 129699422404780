import PropTypes from "prop-types";

function StepperStep({ nextEnabled, children }) {
  return children;
}

StepperStep.defaultProps = {
  nextEnabled: false,
  children: []
};

StepperStep.propTypes = {
  nextEnabled: PropTypes.bool,
  children: PropTypes.any
};

export default StepperStep;