import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

//BE components
import { GenerativeTestContent } from "./components/GenerativeTestContent";

export const queryClient = new QueryClient();

const GenerativeTest = () => (
		<QueryClientProvider client={queryClient}>
			<GenerativeTestContent />
		</QueryClientProvider>
	);

export default GenerativeTest;
