import React, { useEffect, useState } from "react"

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import ArgonAlert from "components/ArgonAlert";
import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress } from '@mui/material';
import { UsersApi } from "api/BackendApi/Users";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSnackbar from "components/ArgonSnackbar";
import {Roles} from "../../../api/BackendApi/Roles";
import ArgonSelect from "../../../components/ArgonSelect";
import {enqueueSnackbar} from "notistack";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CreateUserView from "./CreateUserView";
import Dialog from "@mui/material/Dialog";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import EditIcon from "@mui/icons-material/Edit";

function EditUser({ user, refreshTable }) {

  const [editing, setEditing] = useState(false);
  const [roles, setRoles] = useState({});

  const [idUser, setIdUser] = useState(user._id);
  const [username, setUseranme] = useState(user.username);
  const [name, setName] = useState(user.name);
  const [surname, setSurname] = useState(user.surname);
  const [email, setEmail] = useState(user.email);
  const [role, setRole] = useState([{value: user.role, label: user.role}]);

  const [editUserOpen, setEditUserOpen] = useState(false);

    const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
        width: pxToRem(34),
        minWidth: pxToRem(34),
        height: pxToRem(34),
        minHeight: pxToRem(34),
        mr: 1
    });

    const handleOpen = () => {
        setEditUserOpen(true);
    };

    const handleClose = () => {
        setEditUserOpen(false);
        setIdUser(user._id);
        setUseranme(user.username);
        setName(user.name);
        setSurname(user.surname);
        setEmail(user.email);
        // setRole([{value: user.role, label: user.role}]);
    };

  const editUser = async () => {
    try {
      setEditing(true);
      await UsersApi.editUser(idUser,username, name, surname, email, role[0].value);
      enqueueSnackbar('User Edited!',{variant:'success'});
    } finally {
      setEditing(false);
      refreshTable();
      handleClose();
    }
  };

  useEffect(() => {
      Roles.getRoles()
        .then((roles) => {
            let tmp = [];
            for(let i = 0; i < roles.data.roles.length; i++) {
                tmp.push({ value: roles.data.roles[i].role, label: roles.data.roles[i].role })
            }
            setRoles(tmp);
        })
  }, []);

  return (
      <div>
          {window.user.canEditUser() && <ArgonButton variant="contained" color="info" size="small" iconOnly sx={buttonStyleLeft} onClick={handleOpen}><EditIcon /></ArgonButton>}
          <Dialog
              open={editUserOpen}
              onClose={() => { handleClose() }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle>Edit User</DialogTitle>
              <DialogContent>
                  <ArgonBox component="form" role="form" p={4}>
                      <ArgonTypography fontSize={14}>Username</ArgonTypography>
                      <ArgonBox mb={2}>
                          <ArgonInput
                              placeholder="Username"
                              value={username}
                              size="medium"
                              onChange={(e) => { setUseranme(e.target.value) }}
                              disabled
                          />
                      </ArgonBox>
                      <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                              <ArgonTypography fontSize={14}>Name</ArgonTypography>
                              <ArgonBox mb={2}>
                                  <ArgonInput
                                      placeholder="Name"
                                      value={name}
                                      size="medium"
                                      onChange={(e) => { setName(e.target.value) }}
                                  />
                              </ArgonBox>
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <ArgonTypography fontSize={14}>Surname</ArgonTypography>
                              <ArgonBox mb={2}>
                                  <ArgonInput
                                      placeholder="Surname"
                                      value={surname}
                                      size="medium"
                                      onChange={(e) => { setSurname(e.target.value) }}
                                  />
                              </ArgonBox>
                          </Grid>
                      </Grid>
                      <ArgonTypography fontSize={14}>Email</ArgonTypography>
                      <ArgonBox mb={2}>
                          <ArgonInput
                              placeholder="Email"
                              value={email}
                              size="medium"
                              onChange={(e) => { setEmail(e.target.value) }}
                          />
                      </ArgonBox>
                      <ArgonTypography fontSize={14}>Role</ArgonTypography>
                      <ArgonBox mb={2}>
                          <ArgonSelect
                              placeholder="Role"
                              options={roles}
                              defaultValue={role}
                              onChange={(e) => { setRole([{ value: e.value, label: e.value }]) }}
                          />
                      </ArgonBox>
                      <ArgonBox display="flex" justifyContent="center" mt={4}>
                          <ArgonBox mr={1.5}>
                              {name === "" || surname === "" || email === "" || role === "" ?
                                  <ArgonButton color="primary" disabled>
                                      <SaveIcon />&nbsp; Edit
                                  </ArgonButton>
                                  :
                                  <ArgonButton color="primary" onClick={editUser}>
                                      {editing ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                                      &nbsp; {editing ? "Editing..." : "Edit"}
                                  </ArgonButton>
                              }
                          </ArgonBox>
                      </ArgonBox>
                  </ArgonBox>
              </DialogContent>
          </Dialog>
      </div>
  );
}

export default EditUser;
