import React, {useEffect, useRef, useState} from "react";
import { Navigate } from "react-router-dom";
import {createMRTColumnHelper, MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {
    QueryClient,
    QueryClientProvider,
    useMutation, useQuery,
    useQueryClient,
} from '@tanstack/react-query';

// @mui material components
import {Grid, Card, Icon} from "@mui/material";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "../../components/ArgonButton";

//import custom components
import useAuth from "utils/useAuth";
import DeleteUrl from "./components/DeleteUrl";

//import api backend
import {addUrlsBySitemapName, deleteUrlByNameUrl, urlsWebsiteBySitemap} from "../../api/BackendApi/UrlsWebsite";
import {enqueueSnackbar} from "notistack";
import AddDashboardMonitoring from "./components/AddDashboardMonitoring";
import CreateNewTestCase from "./components/CreateNewTestCase";
import AddNewUrls from "./components/AddNewUrls";
import {download, generateCsv, mkConfig} from "export-to-csv";
import type {MRT_Row} from "material-react-table";
import {jsPDF} from "jspdf";
import autoTable from "jspdf-autotable";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UrlExplorer from "./components/UrlExplorer";

const queryClient = new QueryClient();

const UrlTable = ({showSitemap,sitemapSelected,setSiteMapSelected,refetchDataSitemap}) =>  {

    const isLoggedIn = useAuth().ensureLoggedIn();
    const rowVirtualizerInstanceRef = useRef(null);
    const [sorting, setSorting] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    const [valueProgressBarNewUrl, setValueProgressBarNewUrl] = useState(0);
    const [rowUpdateUrls,setRowUpdateUrls] = useState({});
    const [showUrlToUrlExpl, setShowUrlToUrlExpl] = useState(true);
    const [urlsSelected, setUrlsSelected] = useState([]);

    const returnToSitemapWebsite = () => {
        if(isDeleted || isAdded)
            refetchDataSitemap();
        showSitemap(true);
        setSiteMapSelected({});
        setUrlsSelected([]);
    };

    const actionShowUrlExplorer = async () => {
        if(tableUrls.getSelectedRowModel().rows.length > 0) {
            tableUrls.getSelectedRowModel().rows.forEach(
                (item) => {
                    urlsSelected.push({value: item.id, label: item.id})
                }
            )
        }
        setShowUrlToUrlExpl(!showUrlToUrlExpl);
    };

    //READ
    const {
        data: dataUrls = [],
        isRefetching,
        refetch,
    } = useGetUrls(sitemapSelected);

    //READ function
    function useGetUrls(sitemapSelected) {
        return useQuery({
            queryKey: ['url'],
            queryFn: async () => {
                let urls = [];
                setIsLoading(true);
                for (const sitemap of Object.keys(sitemapSelected)) {

                    const response = await urlsWebsiteBySitemap(sitemap);

                    if (response.status === 200) {
                        urls.push(
                            ...response.data.response.map((row) => {
                                const last_mod = row.last_mod || "-";
                                const priority = row.priority || "-";
                                const change_freq = row.change_freq || "-";

                                return {
                                    url: row.html,
                                    sitemap: sitemap,
                                    last_modified: last_mod,
                                    priority: priority,
                                    change_frequency: change_freq,
                                    monitoring: "No",
                                    market: "-",
                                    creation_date: row.creation_date,
                                };
                            })
                        );
                    }
                }
                setIsLoading(false);
                return Promise.resolve(urls);
            },
            refetchOnWindowFocus: false,
        });
    }

    //call DELETE hook
    const { mutateAsync: deleteUrl,
            isPending: isDeletingUrl
    } = useDeleteUrl();

    //DELETE action
    const handleDeleteUrl = () => {
        deleteUrl(tableUrls.getSelectedRowModel().rows).then(r => {
            setIsDeleted(true);
            console.log("Url deleted");
        });
    };

    //DELETE hook
    function useDeleteUrl() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (urls) => {
                try{
                    let check = false;
                    for(const url of urls){
                        const response = await deleteUrlByNameUrl(url.id);
                        if(response.status === 200){
                            if(response.data.delete){
                                check = true;
                            }
                        }else{
                            check = false;
                            break;
                        }
                    }
                    if(check){
                        enqueueSnackbar('Url deleted!',{variant:'success'});
                        return Promise.resolve();
                    }
                    else{
                        enqueueSnackbar('Error!',{variant:'error'})
                        return Promise.reject();
                    }
                }catch (e) {
                    console.log(e);
                    enqueueSnackbar('Error!',{variant:'error'})
                    return Promise.reject();
                }
            },
            onMutate: (urls) => {
                for(const url of urls){
                    let urlName = url.id;
                    queryClient.setQueryData(['url'], (prevUrl) =>
                        prevUrl?.filter((url) => url.url !== urlName),
                    );
                }
            },
        });
    }

    //call UPDATE hook
    const { mutateAsync: updateUrls, isPending: isUpdatingUrls } =
        useUpdateUrls();

    //UPDATE action
    const handleSaveUrl = async () => {
        updateUrls(rowUpdateUrls).then(r => {
            console.log("Url updated");
        });
    };

    //UPDATE hook
    function useUpdateUrls() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (urlsToUpdate) => {
                try{
                    let check = false;
                    for(const url of urlsToUpdate){
                        const response = await updateUrlByNameUrl(url);
                        if(response.status === 200){
                            if(response.data.update){
                                check = true;
                            }
                        }else{
                            check = false;
                            break;
                        }
                    }
                    if(check){
                        enqueueSnackbar('Url updated!',{variant:'success'});
                        return Promise.resolve();
                    }
                    else{
                        enqueueSnackbar('Error!',{variant:'error'})
                        return Promise.reject();
                    }
                }catch (e) {
                    console.log(e);
                    enqueueSnackbar('Error!',{variant:'error'})
                    return Promise.reject();
                }
            },
            //client side optimistic update
            onMutate: (newUrls) => {
                queryClient.setQueryData(['url'], (prevUrls) =>
                    prevUrls?.map((url) => {
                        const newUrl = newUrls.find((u) => u.url === url.url);
                        return newUrl ? newUrl : url;
                    }),
                );
            },
        });
    }

    //call ADD hook
    const { mutateAsync: addNewUrl,
        isPending: isCreatingUrls
    } = useAddNewUrl();

    //ADD action
    const handleAddNewUrl = async () => {
        await addNewUrl(sitemapSelected).then(async r => {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            enqueueSnackbar('Reloaded data!', {variant: 'info'})
            setValueProgressBarNewUrl(0)
        });
    };

    //ADD hook
    function useAddNewUrl() {
        return useMutation({
            mutationFn: async (sitemapSelected) => {
                try{
                    let item = 1;
                    for (const sitemap of Object.keys(sitemapSelected)) {
                        const response = await addUrlsBySitemapName(sitemap);
                        if(response.status === 200){
                            if(response.data){
                                if(response.data.html_added.length > 0){
                                    enqueueSnackbar(response.data.html_added.length + ' New URLs added for sitemap ' + sitemap,{variant:'info'});
                                    setIsAdded(true);
                                }
                                await new Promise((resolve) => setTimeout(resolve, 500));
                                if(response.data.html_modified.length > 0){
                                    enqueueSnackbar(response.data.html_modified.length + ' URLs modified for sitemap ' + sitemap,{variant:'info'});
                                }
                                enqueueSnackbar(sitemap + ' checked!',{variant:'success'});
                            }
                        }else{
                            enqueueSnackbar('Error for sitemap ' + sitemap,{variant:'error'})
                            break;
                        }
                        setValueProgressBarNewUrl(Math.trunc((100/Object.keys(sitemapSelected).length)*item))
                        item++;
                    }
                    await refetch();
                    return Promise.resolve();
                }catch (e) {
                    console.log(e);
                    enqueueSnackbar('Error!',{variant:'error'})
                    return Promise.reject();
                }
            },
        });
    }

    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    const columnHelper = createMRTColumnHelper();
    const columns = [
        columnHelper.accessor('url', {
            header: 'URL',
            size: 350,
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        }),
        columnHelper.accessor('sitemap', {
            header: 'Sitemap',
            size: 250,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },

        }),
        columnHelper.accessor('last_modified', {
            header: 'Last Mod.',
            size: 110,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('priority', {
            header: 'Priority',
            size: 90,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('change_frequency', {
            header: 'Change Freq.',
            size: 130,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('monitoring', {
            header: 'Monitoring',
            size: 100,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('market', {
            header: 'Market',
            size: 100,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('creation_date', {
            header: 'Creation Date',
            size: 100,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
    ];

    const tableUrls = useMaterialReactTable({
        columns,
        data: dataUrls,
        createDisplayMode: 'custom',
        onCreatingRowSave: handleAddNewUrl,
        editDisplayMode: 'custom',
        enableRowSelection: true,
        selectAllMode: 'all',
        enableBottomToolbar: true,
        enablePagination: true,
        positionToolbarAlertBanner: 'bottom',
        enableRowVirtualization: true,
        rowVirtualizerInstanceRef,
        rowVirtualizerOptions: { overscan: 25 },
        muiTableContainerProps: { sx: { maxHeight: '600px' } },
        showProgressBars: isRefetching,
        onSortingChange: setSorting,
        state: {
            isSaving: isDeletingUrl,
            sorting,
            isLoading,
            isUpdatingUrls,
            isCreatingUrls,
        },
        initialState: {
            density:   'compact', pagination: { pageSize: 25 },
            columnVisibility: { creation_date: false, market: false, monitoring:false, sitemap:false }
        },
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100],
        },
        getRowId: (originalRow) => originalRow.url,
        muiTableHeadProps: {
            sx: {
                padding: '0 !important',
            },
        },
        muiFilterTextFieldProps: {
            sx: { m: '0.5rem 0', width: '100%' },
            variant: 'outlined',
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportCSVData}
                    startIcon={<FileDownloadIcon />}
                >
                    Export All Data by CSV
                </Button>
                <Button
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                        handleExportPDFRows(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                >
                    Export All Data by PDF
                </Button>
                <Button
                    disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    onClick={() => handleExportCSVRows(table.getSelectedRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                >
                    Export Selected Rows by CSV
                </Button>
                <Button
                    disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    onClick={() => handleExportPDFRows(table.getSelectedRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                >
                    Export Selected Rows by PDF
                </Button>
            </Box>
        ),
    });

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
    });

    const handleExportCSVRows = (rows) => {
        const rowData = [];
        //const rowData = rows.map((row) => row.original);
        for(let i in rows) {
            let rowArray = [];
            rowArray['url'] = (rows[i].original.html);
            rowArray['sitemap'] = (rows[i].original.sitemap)
            rowArray['last modified'] = (rows[i].original.last_modified);
            rowArray['priority'] = (rows[i].original.priority);
            rowArray['change frequency'] = (rows[i].original.change_frequency);

            rowData.push(rowArray)
        }
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };

    const handleExportCSVData = () => {
        const rowData = [];
        //const rowData = rows.map((row) => row.original);
        for(let i in dataUrls) {
            let rowArray = [];
            rowArray['url'] = (dataUrls[i].url);
            rowArray['sitemap'] = (dataUrls[i].sitemap)
            rowArray['last modified'] = (dataUrls[i].last_modified);
            rowArray['priority'] = (dataUrls[i].priority);
            rowArray['change frequency'] = (dataUrls[i].change_frequency);

            rowData.push(rowArray)
        }
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };

    const handleExportPDFRows = (rows: MRT_Row<>[]) => {
        const doc = new jsPDF();
        const tableData = [];
        //const tableData = rows.map((row) => Object.values(row.original));
        for(let i in rows) {
            let rowArray = [];
            rowArray.push(rows[i].original.url,rows[i].original.sitemap,rows[i].original.last_modified,rows[i].original.priority,rows[i].original.change_frequency)
            tableData.push(rowArray)
        }
        const tableHeaders = [];
        //const tableHeaders = columns.map((c) => c.header);
        for(let i in columns) {
            if(columns[i].header === 'URL' || columns[i].header === 'Sitemap' || columns[i].header === 'Last Mod.' || columns[i].header === 'Priority' || columns[i].header === 'Change Freq.')
                tableHeaders.push(columns[i].header)
        }

        autoTable(doc, {
            head: [tableHeaders],
            body: tableData,
        });

        doc.save('urls-website.pdf');
    };


    if (!isLoggedIn) {
        return <Navigate replace to="/sign-in" />;
    }

    return (
            <div>
                {showUrlToUrlExpl ?
                <div>
                    <ArgonBox mt={5}>
                        <ArgonButton variant="gradient" color="info" onClick={returnToSitemapWebsite}>
                            Back
                        </ArgonButton>
                    </ArgonBox>
                    <div id="showTableUrl">
                        <ArgonBox mt={5} mb={3}>
                            <Card sx={{ minHeight: "calc(100vh - 30vh)", display: "flex" }}>
                                <Grid container p={5} direction="row" justifyContent="flex-end" alignItems="flex-start">
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <AddNewUrls variant="gradient" color="info" sitemapSelected={sitemapSelected} actionAddNewUrl={handleAddNewUrl} valueProgressBarNewUrl={valueProgressBarNewUrl}/>
                                        </Grid>
                                        <Grid item>
                                            <ArgonButton variant="gradient" color="info" disabled={!tableUrls.getSelectedRowModel().rows.length > 0} onClick={actionShowUrlExplorer}>
                                                <Icon>map</Icon>&nbsp;
                                                URL Explorer
                                            </ArgonButton>
                                        </Grid>
                                        <Grid item>
                                            <CreateNewTestCase module={"url"} setFeatureName={true} buttonDisabled={!tableUrls.getSelectedRowModel().rows.length > 0} table={tableUrls}/>
                                        </Grid>
                                        <Grid item>
                                            <AddDashboardMonitoring buttonDisabled={!tableUrls.getSelectedRowModel().rows.length > 0} tableUrls={tableUrls} actionUpdateUrls={handleSaveUrl} setRowUpdateUrls={setRowUpdateUrls}/>
                                        </Grid>
                                        <Grid item>
                                            <DeleteUrl variant="gradient" color="info" tableUrls={tableUrls} actionDeleteUrl={handleDeleteUrl}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12} xs={12} paddingTop={2}>
                                        <MaterialReactTable table={tableUrls} />
                                    </Grid>
                                </Grid>
                            </Card>
                        </ArgonBox>
                    </div>
                </div>
                :
                <UrlExplorer variant="gradient" color="info" buttonDisabled={!tableUrls.getSelectedRowModel().rows.length > 0} showUrl={setShowUrlToUrlExpl} urlsSelected={urlsSelected} setUrlsSelected={setUrlsSelected}/>
                }
            </div>


    );
}

const UrlWebsite = ({showSitemap, sitemapSelected, setSiteMapSelected, refetchDataSitemap}) => (
    <QueryClientProvider client={queryClient}>
        <UrlTable showSitemap={showSitemap} sitemapSelected={sitemapSelected} setSiteMapSelected={setSiteMapSelected} refetchDataSitemap={refetchDataSitemap}/>
    </QueryClientProvider>
);

export default UrlWebsite;
