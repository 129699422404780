import { useCallback, useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import { TestCycles } from "api/BackendApi/TestCycles";
import DataTable from "components/DataTable";
import { CircularProgress, Grid } from "@mui/material";
import HorizontalBar from "./CycleTree/components/HorizontalBar";

const loading = () => {
  return (<ArgonBox style={{
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }}>
    <CircularProgress size={64} />
  </ArgonBox>);
};

function FolderDetails({ folderId }) {

  const [stats, setStats] = useState(null);
  const [totalStats, setTotalStats] = useState(null);

  const getRecap = async () => {
    var recap = await TestCycles.recap(folderId);
    console.log(recap.data.folders_stats);
    setStats(recap.data.folders_stats);
    setTotalStats(recap.data.total_stats);
  };

  useEffect(() => {
    getRecap();
  }, []);

  var table = loading();
  var recap = loading();

  if (stats != null) {
    recap = <Grid item xs ml={2} mt={5} mb={5}>
      <Grid container direction="row" columns={12}>
        <Grid item xs>
          <Grid
            container
            direction={"column"}
            justifyContent="center"
            alignItems="center">
            <Grid item >
              {totalStats.executed + totalStats.not_executed}
            </Grid>
            <Grid item sx={{ fontSize: "12px" }}>
              Total Tests
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction={"column"}
            justifyContent="center"
            alignItems="center">
            <Grid item >
            {totalStats.executed}
            </Grid>
            <Grid item sx={{ fontSize: "12px" }}>
              Executed
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction={"column"}
            justifyContent="center"
            alignItems="center">
            <Grid item >
            {totalStats.not_executed}
            </Grid>
            <Grid item sx={{ fontSize: "12px" }}>
              Unexecuted
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7} mr={2}>
          <HorizontalBar data={totalStats.statuses} height='50px'></HorizontalBar>
        </Grid>
      </Grid>
    </Grid>;
    table = <DataTable
      entriesPerPage={false}
      showTotalEntries={false}
      enableSelection={false}
      table={{
        columns: [
          { Header: "Name", accessor: "name", minWidth: "10vw", maxWidth: "15vw" },
          { Header: "Total Tests", accessor: "totalTests", width: "4vw", align: "center" },
          { Header: "Executed", accessor: "executed", width: "4vw", align: "center" },
          { Header: "Unexecuted", accessor: "unexecuted", width: "4vw", align: "center" },
          { Header: "Chart", accessor: "chart", width: "100%" }, // TODO hide title
        ],
        rows: stats.map((cycleStats) => {
          return {
            name: cycleStats.name,
            totalTests: cycleStats.executed + cycleStats.not_executed,
            executed: cycleStats.executed,
            unexecuted: cycleStats.not_executed,
            chart: <HorizontalBar
              data={cycleStats.statuses}
              height='50px'
              width='100%'>
            </HorizontalBar>,
          }
        })
      }}
    />
  }

  return (
    <Card sx={{ height: "100%", display: "flex" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        spacing={1}
      >
        {recap}
        <Grid item xs>{table}</Grid>
      </Grid>
    </Card>
  );
}

FolderDetails.propTypes = {
  folderId: PropTypes.string,
}

export default FolderDetails;
