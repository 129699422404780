import { Grid } from "@mui/material";
import ArgonAvatar from "./ArgonAvatar";
import ArgonTypography from "./ArgonTypography";

function AvatarWithName({ user }) {

  var userImage = user?.picture;
  let name = user?.name;
  let surname = user?.surname;
  let nameSurname = [];
  if (name) nameSurname.push(name);
  if (surname) nameSurname.push(surname);
  if (nameSurname.length == 0) nameSurname.push(user?.username ?? 'Unknown');
  nameSurname = nameSurname.join(" ")

  return <Grid
    container
    spacing={1}
    direction="row"
    justifyContent="left"
    alignItems="left">
    <Grid item>
      <ArgonAvatar
        src={userImage ? "data:image/png;base64," + userImage : null}
        alt="profile-image"
        variant="rounded"
        size="xs"
        shadow="sm"
      >
        <ArgonTypography fontSize={"small"}>{nameSurname[0].toUpperCase()}</ArgonTypography>
      </ArgonAvatar>
    </Grid>
    <Grid item>{nameSurname}</Grid>
  </Grid>;

}

export default AvatarWithName;
