import apiClient from "api/BackendApi";

const UsersApi = {
  login: (username, password) =>
    apiClient.post("/be/users/login", {
      username: username,
      password: password,
    }),
  createUser: (username, name, surname, email, role) =>
    apiClient.post("/be/users/create", {
      username: username,
      name: name,
      surname: surname,
      email: email,
      role: role
    }),
  getMe: () => apiClient.get("/be/users/me"),
  getUsers: () => apiClient.get("/be/users"),
  deleteUser: (user) => apiClient.post(`/be/users/delete`, {
    user: user
  }),
  editUser: (idUser, username, name, surname, email, role) => apiClient.post(`/be/users/edit`, {
    user_id: idUser,
    username: username,
    name: name,
    surname: surname,
    email: email,
    role: role
  }),
  changePicture: (pictureB64) => apiClient.post(`/be/users/change_picture`, pictureB64),
  enableUser: (user) => apiClient.post(`/be/users/enable`, {
    user: user
  }),
  changePassword: (user, oldPassword, newPassword) => apiClient.post(`/be/users/change_password`, {
    user_id: user,
    oldPassword: oldPassword,
    newPassword: newPassword
  }),
}

export {
  UsersApi
};
