import apiClient from "api/BackendApi";

const getTestInstancesInCycle = (cycleId) => apiClient.get("/be/test-instances/" + cycleId);

const importTestsIntoCycle = (cycleId, testIds) => apiClient.post("/be/test-instances/" + cycleId + "/import", testIds);

const deleteTestInstances = (instancesIds) => apiClient.post("/be/test-instances/delete", { ids: instancesIds });

const updateInstanceDefect = (instanceId, defect) => apiClient.patch("/be/test-instances/" + instanceId + "/defect", { defect: defect });

const updateInstanceParam = (instanceId, key, value) => apiClient.patch("/be/test-instances/" + instanceId + "/param", { "key": key, "value": value });

export {
  getTestInstancesInCycle,
  importTestsIntoCycle,
  deleteTestInstances,
  updateInstanceDefect,
  updateInstanceParam
};
