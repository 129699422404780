// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

import { Navigate, useNavigate, useRoutes } from "react-router-dom";

import { useEffect } from "react";

import useAuth from "utils/useAuth";

import { CircularProgress } from "@mui/material";


function SplashScreen({navbarRefresher}) {
  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => navbarRefresher("SplashScreen"), 100);
    console.log("SplashScreen created");
  }, []);

  return (
    <ArgonBox
      display={{ xs: "none", lg: "flex" }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
      position="relative"
      textAlign="center"
     
      px={13}
      sx={{ overflow: "hidden" }}
    >
      <ArgonBox
        variant="gradient"
        width="100%"
        height="100%"
        position="absolute"
        sx={{background: "white"}}
        top={0}
        bot={0}
        right={0}
        left={0}
      />
      <ArgonBox position="relative">
        <CircularProgress size={64} color={"error"} />
      </ArgonBox>
    </ArgonBox>
  );
}

export default SplashScreen;