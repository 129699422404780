import {
	Grid,
	Card
} from "@mui/material";
import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";
import logoDBTablePNG from "assets/images/icons/database-table.png";
import ArgonButton from "../../../components/ArgonButton";
import logoManagementToolPNG from "../../../assets/images/icons/management-consulting.png";
import {JiraTool} from "./JiraTool";
import {FigmaTool} from "./FigmaTool";
import {ADOTool} from "./ADOTool";

export function DataLakeInfo({setCardDataLakeSelected,setDatalakeSend}) {

	return (<ArgonBox mt={15} mb={10}>
				<Grid container justifyContent="center">
					<Grid item xs={12} md={8} mb={5}>
						<Card>
							<Grid container justifyContent="center" alignItems="center">
								<Grid item >
									<ArgonBox>
										<img src={logoDBTablePNG} style={{width: "2vw", height: "2vw"}} alt="logoDatalake"/>
									</ArgonBox>
								</Grid>
								<Grid item >
									<ArgonBox>
										<ArgonTypography component="label" variant="h5" fontWeight="bold">
											Data Lake
										</ArgonTypography>
									</ArgonBox>
								</Grid>
							</Grid>
						</Card>
					</Grid>
					<Grid container justifyContent="center">
					</Grid>
					<Grid container justifyContent="center">
						<Grid item xs={12} md={8} mb={5}>
							<ArgonBox mt={3} ml={5} mr={5}>
								<ArgonButton fullWidth variant="contained" color="dark" onClick={() => setCardDataLakeSelected(false)}> Back </ArgonButton>
							</ArgonBox>
						</Grid>
					</Grid>
				</Grid>
			</ArgonBox>);
} 


