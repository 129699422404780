import { useEffect, useState } from "react"

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import SaveIcon from '@mui/icons-material/Save';

import { CircularProgress } from '@mui/material';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { getTest, updateTest } from "api/BackendApi/TestRepository";
import ArgonSnackbar from "components/ArgonSnackbar";
import {enqueueSnackbar} from "notistack";

function TestDetails({ testId, treeRefresher }) {

  const [saving, setSaving] = useState(false);
  const [ready, setReady] = useState(false);

  const [test, setTest] = useState(null);

  const updateTestDetails = async () => {
    setSaving(true);
    try {
      await updateTest(testId, test);
      treeRefresher();
      enqueueSnackbar("Test updated!", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Error updating test", { variant: "error" });
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    async function loadTest() {
      setReady(false);
      let response = await getTest(testId);
      let test = response.data.test;
      setTest(test);
      setReady(true);
    }
    loadTest();
  }, [testId]);


  // Loading indicator
  let content = <Stack direction='row' justifyContent='center'>
    <CircularProgress />
  </Stack>;

  if (ready) {
    content = (
      <ArgonBox component="form" role="form">
        <ArgonTypography fontSize={14}>Test Name</ArgonTypography>
        <ArgonBox mb={2}>
          <ArgonInput
            placeholder="Test Name"
            value={test.name ?? ''}
            size="medium"
            onChange={(e) => setTest({ ...test, name: e.target.value })}
          />
        </ArgonBox>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ArgonTypography fontSize={14}>Test Description</ArgonTypography>
            <ArgonBox mb={2}>
              <ArgonInput
                placeholder="Test Description"
                value={test.description ?? ''}
                size="medium"
                onChange={(e) => setTest({ ...test, description: e.target.value })}
              />
            </ArgonBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <ArgonTypography fontSize={14}>Test Precondition</ArgonTypography>
            <ArgonBox mb={2}>
              <ArgonInput
                placeholder="Test Precondition"
                value={test.precondition ?? ''}
                size="medium"
                onChange={(e) => setTest({ ...test, precondition: e.target.value })}
              />
            </ArgonBox>
          </Grid>
        </Grid>
        <ArgonTypography fontSize={14}>Notes</ArgonTypography>
        <ArgonBox mb={2}>
          <ArgonInput
            placeholder="Notes"
            value={test.notes ?? ''}
            size="medium"
            onChange={(e) => setTest({ ...test, notes: e.target.value })}
          />
        </ArgonBox>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <ArgonTypography fontSize={14}>Binaries</ArgonTypography>
                <ArgonBox mb={2}>
                    <ArgonInput
                    placeholder="Binaries"
                    value={test.binaries ?? ''}
                    size="medium"
                    onChange={(e) => setTest({ ...test, binaries: e.target.value })}
                    />
                </ArgonBox>
            </Grid>
            <Grid item xs={12} md={6}>
                <ArgonTypography fontSize={14}>Command</ArgonTypography>
                <ArgonBox mb={2}>
                  <ArgonInput
                      placeholder="Command"
                      value={test.command ?? ''}
                      size="medium"
                      onChange={(e) => setTest({ ...test, command: e.target.value })}
                  />
                </ArgonBox>
            </Grid>
        </Grid>
        <ArgonTypography fontSize={14}>Arguments</ArgonTypography>
        <ArgonBox mb={2}>
          <ArgonInput
            placeholder="Arguments"
            value={test.arguments ?? ''}
            size="medium"
            onChange={(e) => setTest({ ...test, arguments: e.target.value })}
          />
        </ArgonBox>
        <ArgonBox display="flex" justifyContent="center" mt={2}>
          <ArgonBox mr={1.5}>
            <ArgonButton color="primary" onClick={updateTestDetails} disabled={test.name === ""}>
              {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
              &nbsp; {saving ? "Saving..." : "Save"}
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    )
  }

  return (

    <Card sx={{ height: "100%", display: "flex" }}>
      <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <ArgonTypography variant="h5" fontWeight="medium" textTransform="capitalize">
          Details
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox p={2}>
        {content}
      </ArgonBox>
    </Card>
  );
}

TestDetails.propTypes = {
  testId: PropTypes.string,
  treeRefresher: PropTypes.func
};

export default TestDetails;
