import apiClient  from "api/BackendApi";

const Module = {
  getModules:  () => apiClient.get("/be/module/enabled"),
  getModulesAll:  () => apiClient.get("/be/module/all"),
  getModuleByName:  (name) => apiClient.post("/be/module/"+name),
  getModuleById:  (id) => apiClient.post("/be/module/"+id),
  editModule:  (module_id, name, enabled) => apiClient.post("/be/module/update", {"module_id": module_id, "name": name, "enabled": enabled}),
  enableModule:  (module_id, name, enabled) => apiClient.post("/be/module/enable", {"module_id": module_id, "name": name, "enabled": enabled}),
  addNewDashboard:  (name) => apiClient.post("/be/module/add_dashboard", {"name": name}),
  deleteDashboard:  (module_id) => apiClient.post("/be/module/delete_dashboard", {"module_id": module_id}),
}

export {
  Module
};
