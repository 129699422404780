import { useState } from "react";

import PropTypes from "prop-types";

import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress } from '@mui/material';
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";

import { addFolder } from "api/BackendApi/TestRepository";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import {enqueueSnackbar} from "notistack";

function AddNewFolderView({ closeDialog, folderId }) {

  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");

  const saveNewFolder = () => {
    setSaving(true);
    addFolder(folderId, name)
        .then(() => {
            enqueueSnackbar("Folder added successfully", { variant: "success" });
        })
        .catch(() => {
            enqueueSnackbar("Error", { variant: "error" });
        })
      .finally(() => {
        setSaving(false);
        closeDialog();
      });
  };

  return (
    <ArgonBox  padding={2} component="form" role="form">
      <ArgonTypography fontSize={14}>Folder Name</ArgonTypography>
      <ArgonBox>
        <ArgonInput
          placeholder="Folder Name"
          value={name}
          size="medium"
          onChange={(e) => { setName(e.target.value) }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && name !== "") {
              saveNewFolder();
              e.preventDefault();
            } else if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      </ArgonBox>
      <ArgonBox display="flex" justifyContent="center" mt={3}>
        <ArgonBox mr={1.5}>
            {name === "" ? (
                <ArgonButton color="primary" disabled>
                    <SaveIcon />&nbsp; Save
                </ArgonButton>
            ) : (
              <ArgonButton color="primary" onClick={saveNewFolder}>
                {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                &nbsp; {saving ? "Saving..." : "Save"}
              </ArgonButton>
            )}
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}


AddNewFolderView.propTypes = {
  closeDialog: PropTypes.func,
  folderId: PropTypes.string
};

export default AddNewFolderView;
