import apiClient from "api/BackendApi";

const Locator = {
  getLocatorPages:  () => apiClient.get("/be/locator/pages"),
  getLocatorPagesByProject:  (project) => apiClient.post("/be/locator/pagesByProject",{"project": project}),
  getLocators:  (pageTitle, page, project) => apiClient.post("/be/locator", {"pageTitle": pageTitle, "page": page, "project": project}),
  getLocatorImage:  (imageId) => apiClient.post("/be/locator/getImage", {"imageId": imageId}, {responseType: 'blob'}),
  createLocatorPageOnDB:  (pageTitle, page, project) => apiClient.post("/be/locator/newPage", {"pageTitle": pageTitle, "page": page, "project": project}),
  createLocatorOnDB:  (page, name, xpath) => apiClient.post("/be/locator/new", {"page": page, "name": name, "xpath": xpath}),
  updateLocatorOnDB:  (page, dataToModify) => apiClient.post("/be/locator/update", {"page": page, "data": dataToModify}),
  deleteLocatorOnDB:  (page, nameLocator) => apiClient.post("/be/locator/delete", {"page": page, "nameLocator": nameLocator}),
  deleteLocatorPageOnDB:  (pageTitle, page, project) => apiClient.post("/be/locator/deletePage", {"pageTitle": pageTitle, "page": page, "project": project}),
  updatePageTitle:  (pageId, pageTitle) => apiClient.post("/be/locator/updatePageTitle", {"pageId": pageId, "pageTitle": pageTitle}),
  updatePage:  (pageId, page) => apiClient.post("/be/locator/updatePage", {"pageId": pageId, "page": page}),
}

export {
  Locator
};
