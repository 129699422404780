import apiClient from "api/BackendApi";


const sitemapWebsite = () => apiClient.get("/be/sitemap-website");

const addSitemap = (infoAddSitemap) => apiClient.post("/be/sitemap-website/addSitemap", infoAddSitemap);

const deleteSitemapBySitemapName = (infoDeleteSitemap) => apiClient.post("/be/sitemap-website/deleteSitemap", {"sitemap": infoDeleteSitemap});

const checkStatus = (infoCheckStatus) => apiClient.post("/be/sitemap-website/checkStatus", infoCheckStatus);

const allHtmlbyHost = (host) => apiClient.post("/be/urls-website/allUrlByHost", {"host": host});

export {
  addSitemap,
  deleteSitemapBySitemapName,
  sitemapWebsite,
  checkStatus,
  allHtmlbyHost,
};
