import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";


import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";

import ArgonBox from "components/ArgonBox";
import Header from "components/Header";

import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Card from "@mui/material/Card";
import Sidenav from "pages/user-profile/components/SideNav";
import UserHeader from "pages/user-profile/components/UserHeader";
import BasicInfo from "pages/user-profile/components/BasicInfo";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import { UsersApi } from "api/BackendApi/Users";
import { CircularProgress } from "@mui/material";
import DataTable from "components/DataTable";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CreateUserView from "./components/CreateUserView";
import PermissionRole from "../roles-management/components/PermissionRole";
import EditRoleName from "../roles-management/components/EditRoleName";
import DeleteRole from "../roles-management/components/DeleteRole";
import DeleteUser from "./components/DeleteUser";
import { Block, Check } from "@mui/icons-material";
import EnableUser from "./components/EnableUser";
import EditUser from "./components/EditUser";

const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
  width: pxToRem(34),
  minWidth: pxToRem(34),
  height: pxToRem(34),
  minHeight: pxToRem(34),
  mr: 1
});


function UserManagement() {
  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const [users, setUsers] = useState(null);
  const [createUserOpen, setCreateUserOpen] = useState(false);

  const loadUsers = async () => {
    let response = await UsersApi.getUsers();
    setUsers(response.data.users);
  }

  const refreshTable = () => {
    loadUsers();
  }

  useEffect(() => {
    loadUsers();
  }, []);

  let body = <CircularProgress></CircularProgress>

  if (users != null) {
    body = (
      <DataTable
        entriesPerPage={false}
        showTotalEntries={true}
        enableSelection={false}
        table={{
          columns: [
            { Header: "Username", accessor: "username" },
            { Header: "Name", accessor: "name" },
            { Header: "Surname", accessor: "surname" },
            { Header: "Email", accessor: "email" },
            { Header: "Role", accessor: "role" },
            { Header: "Enabled", accessor: "enabled" },
            { Header: "Action", accessor: "action" },
          ],
          rows: users.map((user) => {

            let action;
            let enabled;

            if (user.enabled) {
              enabled = <ArgonButton variant="contained" color="success" size="small" iconOnly disabled><Check /></ArgonButton>
              action = <Grid container>
                <EditUser key={user._id} user={user} refreshTable={refreshTable} />
                <DeleteUser user={user._id} username={user.username} load={refreshTable} />
              </Grid>
            } else {
              enabled = <ArgonButton variant="contained" color="error" size="small" iconOnly disabled><Block /></ArgonButton>
              action = <Grid container>
                <EditUser key={user._id} user={user} refreshTable={refreshTable} />
                <EnableUser user={user._id} username={user.username} load={refreshTable} />
              </Grid>
            }

            return {
              username: user.username,
              name: user.name,
              surname: user.surname,
              email: user.email,
              role: user.role,
              enabled: enabled,
              action: action
            }
          })
        }}
      />
    )
  }

  return (
    <DashboardLayout>
      <Header />
      <Dialog
        open={createUserOpen}
        onClose={() => { setCreateUserOpen(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Create User</DialogTitle>
        <DialogContent>
          <CreateUserView closeDialog={setCreateUserOpen} refreshTable={refreshTable} />
        </DialogContent>
      </Dialog>
      <ArgonBox mt={5} mb={3}>
        <Card sx={{ minHeight: "calc(100vh - 30vh)", display: "flex" }}>
          <Grid container p={2} direction="row" justifyContent="flex-end" alignItems="flex-start">
            {window.user.canCreateUser() && <Grid item>
              <ArgonButton variant="contained" color="primary" size="large" iconOnly sx={buttonStyleLeft}>
                <AddIcon onClick={() => setCreateUserOpen(true)} />
              </ArgonButton>
            </Grid>}
            <Grid item md={12} xs={12}>
              {body}
            </Grid>
          </Grid>
        </Card>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}


export default UserManagement;
