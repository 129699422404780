import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import apiClient from "api/BackendApi";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import ArgonDropzone from "components/ArgonDropzone";
import { enqueueSnackbar } from "notistack";



function UploadImageDialog({ open, onClose }) {

  const dropzoneOptions = {
    addRemoveLinks: true,
    url: "/be/users/change_picture",
    maxFiles: 1,
    multiple: false,
    autoProcessQueue: true,
    method: "POST",
    withCredentials: true,
    headers: {
      "Authorization": "Bearer " + (localStorage.getItem("jwt-token") || sessionStorage.getItem("jwt-token"))
    },

    init: function () {

      this.on("removedfile", (file) => {
        // setDropzoneFiles((previousFiles) => previousFiles.filter((f) => f.upload.filename !== file.upload.filename))
      });

      this.on("addedfile", (file) => {
        // setIsUploading(true);
      });

      this.on("complete", (file) => {
        console.log(file)
        onClose();
        if (file.status === 'error') {
          enqueueSnackbar('There was an error updating the profile image.', { variant: 'error' })
        } else if (file.status === 'canceled') {
          enqueueSnackbar('Image upload canceled', { variant: 'warning' })
        } else {
          enqueueSnackbar('Profile image changed.', { variant: 'success' })
        }

      });

      this.on("maxfilesexceeded", function (file) {
        this.removeAllFiles();
        this.addFile(file);
      });
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ "& .MuiDialog-paper": { width: "100%" } }
      }
      fullWidth={"md"}
      maxWidth={"md"}
    >
      <DialogTitle textAlign="center">{"Upload Image"}</DialogTitle>
      <DialogContent>
        <div className="dropmehere" id="dropmehere">
          <ArgonDropzone options={dropzoneOptions} />
        </div>
      </DialogContent>
    </Dialog >
  );
}

function UserHeader({ user, reloadUser }) {

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const openUploadImagePopup = () => {
    setUploadDialogOpen(true);
  };

  const onClose = (reason) => {
    setUploadDialogOpen(false);
    reloadUser();
  };

  return (
    <Card id="profile">
      <UploadImageDialog open={uploadDialogOpen} onClose={onClose} />
      
      <ArgonBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <div style={{ position: "relative", overflow: "hidden" }}>
              <ArgonAvatar
                src={"data:image/png;base64," + user.picture}
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"
              ><ArgonTypography>{user.name[0].toUpperCase()}</ArgonTypography></ArgonAvatar>
              <Box
                style={{ position: "absolute", bottom: 0, right: 0 }}
              >
                <IconButton color={"white"} size={"small"} onClick={openUploadImagePopup} sx={{"mix-blend-mode": "difference", "color": "white"}}>
                  <EditIcon />
                </IconButton>
              </Box>
            </div>
          </Grid>
          <Grid item>
            <ArgonBox height="100%" mt={0.5} lineHeight={1}>
              <ArgonTypography variant="h5" fontWeight="medium">
                {user.name + " " + user.surname}
              </ArgonTypography>
              <ArgonTypography variant="button" color="text" fontWeight="medium">
                {user.email + " (" + user.username + ")"}
              </ArgonTypography>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
    </Card>
  );
}

UserHeader.propTypes = {
  user: PropTypes.object
}

export default UserHeader;
