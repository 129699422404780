import apiClient from "../index";

const addRequirementFile = (uploadedFiles) =>
{
  return apiClient.post("/be/generative-test/requirement/files", uploadedFiles, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const progress = Math.round((loaded * 100) / total);
    },
  });
}

const downloadFeatureFile = (type, rows) => apiClient.post("/be/generative-test/downloadFeatureFile",
    {"type": type, "rows_id": rows},
    {responseType: 'blob'})

const generate = (rows, runId, runType) => apiClient.post("/be/generative-test/generate", {"features_id": rows, "run_id": runId, "step_name": runType})

const pushCloudRepository = (data) => apiClient.post("/be/generative-test/mgmt/cloudRepository/push", data)

export {
  addRequirementFile,
  downloadFeatureFile,
  generate,
  pushCloudRepository,
};
